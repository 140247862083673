import { Waveform } from "@uiball/loaders";
import { marked } from "marked";
import React, { useEffect, useRef, useState } from "react";
import {
  go_btn,
  studentDashboard_AI_chatbot_clodse_image,
  studentDashboard_Small_AI_chatbot_image
} from "../../assets/images/images";
import PreprocessAndRenderLatex from "../ProcessRenderLatex";

function CustomChatbot(props) {
  const {
    chatHistory,
    ai_welcome_msg,
    ai_avatar,
    ai_name,
    school_name,
    onClose,
    onMinimize,
    socketUrl,
    socketPayload,
    roomId
  } = props;
  const [getSocket, setSocket] = useState(null);
  const [chatHist, setChatHist] = useState(
    chatHistory !== null && chatHistory?.length > 0
      ? chatHistory
      : [
          {
            query: "",
            answer:
              ai_welcome_msg !== undefined
                ? ai_welcome_msg
                : "Hi, I am your Ai Assistant, How may I help you?",
          },
        ]
  );

  const messagesEndRef = useRef(null);
  const [aichatbotquery, setAichatbotquery] = useState("");
  const handleTextareaChange = (e) => {
    setAichatbotquery(e.target.value);
  };
  const renderer = new marked.Renderer();
  const originalLinkRenderer = renderer.link;
  renderer.link = (href, title, text) => {
    const html = originalLinkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a target="_blank" rel="noopener noreferrer" ');
  };
  const handlechatbotSubmit = async (e) => {
    const history = chatHist.map((chat) => {
      return {
        query: chat.query,
        answer: Array(chat.answer)
          ?.map((item) => item)
          .join(""),
      };
    });
    try {
      if (aichatbotquery !== "") {
        if (getSocket) {
          const newPayload = {
            ...socketPayload,
            query: aichatbotquery,
            messages: history ?? [],
            school_name: school_name ?? "SRDS",
          };
          const payloadJSON = JSON.stringify(newPayload);
          setChatHist((prevChatHist) => {
            if (Array.isArray(prevChatHist)) {
              return [...prevChatHist, { query: newPayload.query, answer: "" }];
            } else {
              return [{ query: newPayload.query, answer: "" }];
            }
          });

          getSocket.send(payloadJSON);
        }
      }
    } catch (error) {}
    setAichatbotquery("");
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  let combinedMessage = [];

  useEffect(() => {

    if (roomId) {
      const maxReconnectInterval = 30000; // Max reconnect interval
      const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
      const maxReconnectAttempts = 3; // Maximum number of reconnect attempts
      let reconnectInterval = 1000; // Initial reconnect interval in ms
      let reconnectAttempts = 0; // Number of reconnect attempts

      let socket = new WebSocket(socketUrl);

    setSocket(socket);

    socket.onopen = () => {
      // console.log("WebSocket connection opened");
      reconnectAttempts = 0; // Reset reconnect attempts on successful connection7
      reconnectInterval = 1000; // Reset reconnect interval
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data && data.message) {
          const message = data.message;

          combinedMessage.push(message);
          let status = false;
          let chatdatares = combinedMessage.join("");

          if (
            chatdatares.includes("<STARTOFTURN>") ||
            chatdatares.includes("<ENDOFTURN>")
          ) {
            chatdatares = chatdatares
              .replace(/<STARTOFTURN>/g, "")
              .replace(/<ENDOFTURN>/g, "");

            if (combinedMessage.join("").includes("<ENDOFTURN>")) {
              status = true;
              combinedMessage.length = 0;
              combinedMessage.push(chatdatares);
            }
          }
          setChatHist((prevChatHist) => {
            const updatedChatHist = Array.isArray(prevChatHist)
              ? [...prevChatHist]
              : [];
            if (updatedChatHist.length > 0) {
              const lastQuery = updatedChatHist[updatedChatHist.length - 1];
              if (lastQuery) {
                lastQuery.answer = chatdatares;
              }
            }
            if (status) {
              combinedMessage.length = 0;
            }
            return updatedChatHist;
          });
          scrollToBottom();
        } else {
        }
      } catch (error) {}
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = (event) => {
      // console.log("WebSocket connection closed:", event);
      if (reconnectAttempts < maxReconnectAttempts) {
        setTimeout(() => {
          reconnectAttempts++;
          socket.close(); // Make sure to close old sockets if not yet closed
          socket = new WebSocket(socketUrl); // Create a new socket and attempt to connect
          reconnectInterval = Math.min(reconnectInterval * reconnectDecay, maxReconnectInterval);
        }, reconnectInterval);
      } else {
        // console.log("Max reconnect attempts reached, will not attempt further reconnects.");
      }
    };


    messagesEndRef.current?.scrollIntoView({
      inline: "center",
      behavior: "smooth",
    });

    return () => {
      if (socket.readyState === 1) {
        // <-- This is important
        socket.close();
      }
    };
  }
  }, [roomId]);

  // useEffect(() => {
  //   const socket = new WebSocket(socketUrl);

  //   setSocket(socket);

  //   socket.onopen = () => {
  //     console.log("WebSocket connection opened");
  //   };

  //   socket.onmessage = (event) => {
  //     try {
  //       const data = JSON.parse(event.data);

  //       if (data && data.message) {
  //         const message = data.message;

  //         combinedMessage.push(message);
  //         let status = false;
  //         let chatdatares = combinedMessage.join("");

  //         if (
  //           chatdatares.includes("<STARTOFTURN>") ||
  //           chatdatares.includes("<ENDOFTURN>")
  //         ) {
  //           chatdatares = chatdatares
  //             .replace(/<STARTOFTURN>/g, "")
  //             .replace(/<ENDOFTURN>/g, "");

  //           if (combinedMessage.join("").includes("<ENDOFTURN>")) {
  //             status = true;
  //             combinedMessage.length = 0;
  //             combinedMessage.push(chatdatares);
  //           }
  //         }
  //         setChatHist((prevChatHist) => {
  //           const updatedChatHist = Array.isArray(prevChatHist)
  //             ? [...prevChatHist]
  //             : [];
  //           if (updatedChatHist.length > 0) {
  //             const lastQuery = updatedChatHist[updatedChatHist.length - 1];
  //             if (lastQuery) {
  //               lastQuery.answer = chatdatares;
  //             }
  //           }
  //           if (status) {
  //             combinedMessage.length = 0;
  //           }
  //           return updatedChatHist;
  //         });
  //         scrollToBottom();
  //       } else {
  //       }
  //     } catch (error) {}
  //   };

  //   socket.onerror = (error) => {
  //     console.error("WebSocket error:", error);
  //   };

  //   socket.onclose = (event) => {
  //     console.log("WebSocket connection closed:", event);
  //   };
  //   messagesEndRef.current?.scrollIntoView({
  //     inline: "center",
  //     behavior: "smooth",
  //   });

  //   return () => {
  //     if (socket.readyState === 1) {
  //       // <-- This is important
  //       socket.close();
  //     }
  //   };
  // }, []);
  // const renderPlainHtml = (htmlString) => {
  //   const doc = new DOMParser().parseFromString(htmlString, "text/html");
  //   return doc.body.textContent || "";
  // };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents the default action to stop from happening (e.g., navigation)
      handlechatbotSubmit();
    }
  };
  const renderMarkdown = (markdown) => {
    return { __html: marked(markdown, { renderer }) };
  };

  return (
    <div className="studentDashboard-chatbot">
      <div className="studentDashboard-chatbot-title-folder">
        <div className="studentDashboard-chatbot-Small-AI-image">
          <img
            src={ai_avatar ?? studentDashboard_Small_AI_chatbot_image}
            alt=""
          />
        </div>
        <h2>{ai_name}</h2>
      </div>
      <div className="studentDashboard-universal-chatbot-inner-chat">
        {chatHist?.map((chat, index) => (
          <div key={index}>
            {chat.query !== null && chat.query !== "" ? (
              <div className="studentDashboard-universal-chatbot-AI-des studentDashboard-universal-chatbot-send-massege">
                {chat.query}
              </div>
            ) : (
              <></>
            )}
            <div className="studentDashboard-universal-chatbot-AI-des">
              {chat.answer === "" && (
                <div className="loading-chat-answer waveform">
                  <Waveform size={25} />
                </div>
              )}
              {/* <div className="loading-chat-answer">
                <PreprocessAndRenderLatex
                  content={marked(chat.answer)}
                />
              </div> */}
              <div
                className="loading-chat-answer"
                dangerouslySetInnerHTML={renderMarkdown(chat.answer)}
              ></div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
      <div className="studentDashboard-universal-chatbot-send-text-folder">
        <textarea
          name="aichatbotquery"
          value={aichatbotquery}
          rows="30"
          color="8"
          onKeyPress={handleKeyPress}
          onChange={handleTextareaChange}
          placeholder="Type your query here"
        />
        <button
          type="button"
          onClick={handlechatbotSubmit}
          className="go_cls_btn"
          id="studentDashboard-universal-chatbot-send-btn"
        >
          <img src={go_btn} alt="" />
        </button>
      </div>
      {/* <div
        className="studentDashboard-chatbot-container-minimize-image"
        onClick={onMinimize}
      >
        <img src={studentDashboard_AI_chatbot_minimize_image} alt="" />
      </div> */}
      <div
        className="studentDashboard-chatbot-container-close-image"
        onClick={onClose}
      >
        <img src={studentDashboard_AI_chatbot_clodse_image} alt="" />
      </div>
    </div>
  );
}

export default CustomChatbot;
