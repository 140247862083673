const genders = [
  { name: "Choose Gender", value: 0 },
  { name: "Male", value: 1 },
  { name: "Female", value: 2 },
  { name: "Other", value: 3 },
];

const titles = [
  { name: "Title", value: 0 },
  { name: "Mr.", value: 1 },
  { name: "Ms.", value: 2 },
  { name: "Mrs.", value: 3 },
  { name: "Dr.", value: 4 },
];

const courseTabNames = {
  ENROLLED: "Enrolled",
  ACTIVE: "Active",
  COMPLETED: "Completed",
  REQUESTED: "Requested",
  FAVOURITE: "Favourite",
};
const AssignmentType = {
  MCQ: {
    name: "Multiple Choice Questions",
    value: 1,
  },
  TF: {
    name: "True/False",
    value: 2,
  },
  FITB: {
    name: "Fill-In-The-Blanks",
    value: 3,
  },
  SAQ: {
    name: "Short-Answer-Questions",
    value: 4,
  },
  EQ: {
    name: "Essay-Questions",
    value: 5,
  },
  // OEQ: {
  //   name: "Open-Ended-Questions",
  //   value: 6,
  // },
  MULTIFACETED: {
    name: "Mixed Questions",
    value: 7,
  },
};
const AssignmentDuration = {
  "10min": {
    name: "10 minute",
    value: "10",
  },
  "30min": {
    name: "30 minutes",
    value: "30",
  },
};
export { genders, courseTabNames, AssignmentType, AssignmentDuration, titles };
