import React from "react";
import "./PreviewPopup.css";
import FileUploadPreprocessAndRenderLatex from "../FileuploadProcessRenderLatex";

function PreviewPopup({value, onClose}) {

    return (
        <>  
            <div className="preview-popup-wrapper">
                <div class="preview-popup-container">
                    <div class="preview-popup-close-btn" onClick={onClose}>
                        <a><i class="fa-solid fa-xmark dismis-close"></i></a>
                    </div>
                    <div class="preview-popup-folder">
                        {/* <p>{value}</p> */}
                        {value ? (
                            <FileUploadPreprocessAndRenderLatex content={value} isBlockMath={true} /> 
                        ) : (
                            <p>No data found</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreviewPopup;