import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CourseCard from "../../../components/CourseCard/CourseCard";
import {
    course_bg_image,
    course_info_image,
    search,
} from "../../../assets/images/images";
import { ReactSVG } from "react-svg";
import { useAuth } from "../../../features/auth/AuthContext";
import {
    getCourseBySchoolId,
    resetCourseListBySchoolId,
} from "../../../state/actions/getCourseListBySchoolIDAction";
import PaginationView from "../../../components/Pagination/PaginationView";
import { CircularProgress, Grid } from "@mui/material";
import dayjs from "dayjs";

const CoursesList = ({ currentPage, setCurrentPage }) => {

    // const [selectedCourse, setSelectedCourse] = useState(null);
    // const [deletePopUp, setdeletePopUp] = useState(false);
    // const [loading, setLoading] = useState(false);
    // const [anchorEl, setAnchorEl] = useState(null);
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { role } = useSelector((state) => state.globalState);
    const { courses, count, next, previous, totalPages, error, isLoading } = useSelector(
        (state) => state.getCoursesbySchoolId
    );
    const globalState = useSelector((state) => state.globalState);

    // const handleClick = (event, courseId) => {
    //     setAnchorEl(event.currentTarget);
    //     setActiveMenuCourseId(courseId);
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    //     setActiveMenuCourseId(null);
    // };

    // const deleteCourse = async (course_id) => {
    //     setSelectedCourse(course_id);
    //     setdeletePopUp(!deletePopUp);
    //     handleClose();
    //     document.body.style.overflow = "hidden";
    // };

    // const handleDeleteCourse = () => {
    //     setdeletePopUp(!deletePopUp);
    //     dispatch()
    // }

    return (
        <>
            {totalPages > 1 && (
                <div className="pagination_top_wrapper">
                    <PaginationView
                        totalPages={totalPages}
                        next={next}
                        previous={previous}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            )}
            <div className="CoursesDashboard-section">
                <div className="CoursesDashboard-wrapper">
                    {/* <div className="School_Course_Card_container">
                        {courses?.map((data, index) => (
                            <CourseCard
                                key={index}
                                courseimage={data.image}
                                coursename={data.name}
                                totalstudents={data.students_enrolled}
                                totalteachers="1"
                                lesson_count={data.lessons_count}
                                start_date={data.start_date}
                                end_date={data.end_date}
                                description={data.description}
                                course_info_image={data.course_info_image}
                                Teacher_title="Teacher"
                                Teacher_name={data.teacher_name}
                                createdcourse="2024-01-20"
                                schooljoined="0"
                                course_details={data}
                            />
                        ))}
                        {!courses.length && (
                            <p style={{ textAlign: "center" }}>No Courses Found</p>
                        )}
                    </div> */}
                    <div className="School_Course_Card_container">
                        {courses?.map((data, index) => (
                            <CourseCard
                                key={index}
                                courseimage={data.image}
                                coursename={data.name}
                                totalstudents={data.students_enrolled}
                                totalteachers="1"
                                lesson_count={data.lessons_count}
                                start_date={data.start_date}
                                end_date={data.end_date}
                                description={data.description}
                                course_info_image={data.course_info_image}
                                Teacher_title="Teacher"
                                Teacher_name={data.teacher_name}
                                createdcourse={dayjs(data.created_at).format("MMM DD, YYYY")}
                                schooljoined="0"
                                course_details={data}
                            />
                        ))}
                        {isLoading && (
                            <Grid
                                height={"50vh"}
                                xs={12}
                                container
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <CircularProgress size={80} />
                            </Grid>
                        )}
                        {!isLoading && courses && !courses.length && (
                            <p style={{ textAlign: "center" }}>No Courses Found</p>
                        )}
                    </div>

                </div>
            </div>
            {totalPages > 1 && (
                <PaginationView
                    totalPages={totalPages}
                    next={next}
                    previous={previous}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            )}
        </>
    );
};

export default CoursesList;
