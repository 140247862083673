import { Dispatch } from "redux";
import api from "../../api/api";
import {
    updatefileurl,
    setloading,
    seterror,
    resetupdateFileUrl,
} from "../reducers/updateFileUrlReducer";

const updateFileUrl = (data: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.post(`/ai/api/file-url-update/`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });

        if (result) {
            dispatch(setloading(false));
            dispatch(updatefileurl(result.data));
        }
    } catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
};

export { updateFileUrl, resetupdateFileUrl };

