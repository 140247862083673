import React, { useEffect, useRef, useState } from "react";
import { Ai_Voice_animation_image_mute, Ai_Voice_animation_image_unmute } from "../../assets/images/images";
import "./AiVoiceanimation.css";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../LoaderComponent/LoaderComponent";

function AiVoiceanimation({ togglePlay }) {

    const texttospeech = useSelector((state) => state.texttospeech);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isStopped, setIsStopped] = useState(true);
    const audioRef = useRef(null);

    useEffect(() => {

        if (texttospeech.success) {
            const audio = audioRef.current;

            const setPlaying = () => setIsPlaying(true);
            const setPaused = () => setIsPlaying(false);
            const checkIfStopped = () => {
                if (audio.currentTime === 0 || audio.ended) {
                    setIsStopped(true);
                }
            };

            audio.addEventListener('play', setPlaying);
            audio.addEventListener('pause', setPaused);
            audio.addEventListener('ended', checkIfStopped);

            return () => {
                audio.removeEventListener('play', setPlaying);
                audio.removeEventListener('pause', setPaused);
                audio.removeEventListener('ended', checkIfStopped);
            };
        }

    }, [texttospeech]);

    return (
        <>
            <div className="Ai-Voice-animation-wrapper">
                <div className="Ai-Voice-animation-container">
                    {/* <div className="Ai-Voice-animation-image">
                        {isPlaying ? (
                            <img src={Ai_Voice_animation_image_unmute} alt="Unmute" />
                        ) : (
                            <img src={Ai_Voice_animation_image_mute} alt="Mute" />
                        )}
                    </div> */}
                    <div className="Ai-Voice-animation-play-progress-folder">
                        {/* <div className="play-pause-btn-Overview-lesson-generator" >
                            {!pauseButton ? (
                                <>
                                    <i class="fa-regular fa-circle-play" onClick={handlePlay}></i> */}
                        {/* <div className="animation-voice">
                                        <div class="boxContainer">
                                            <div class="box box1"></div>
                                            <div class="box box2"></div>
                                            <div class="box box3"></div>
                                        </div>
                                    </div> */}
                        {/* </>
                            ) : (
                                <i class="fa-regular fa-circle-pause" onClick={handlePause}></i>
                            )}

                            <i class="fa-regular fa-circle-stop" onClick={handleStop}></i>
                        </div> */}
                        {texttospeech.success ? <audio controls ref={audioRef} src={texttospeech.voice.audio_url} /> : <LoaderComponent />}
                    </div>
                    <i class="fa-solid fa-x Ai-Voice-animation-close-btn" onClick={togglePlay}></i>
                </div>
            </div>
        </>
    );
}

export default AiVoiceanimation;