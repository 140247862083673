import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import imgs from "../assets/images/imgs";
import AddAdmin from "../components/Add Profile/AddAdmin";
import AddProfilePopup from "../components/Add Profile/AddProfilePopup";
import AddSchool from "../components/AddSchool/AddSchool";
import Sidebar from "../components/Sidebar/Sidebar";
import Students from "../components/Students/Students";
import Teachers from "../components/Teachers/Teachers";
import ShowAdminDashboard from "../modules/admin/Dashboard/Dashboard";
import AllCourses from "../modules/admin/courses/AllCourses";
import AllCoursesDashboard from "../modules/superadmin/AllCoursesDashboard/AllCoursesDashboard";
import RatingCard from "../modules/superadmin/Rating/Rating";
import SchoolList from "../modules/superadmin/SchoolList/SchoolList";
import SuperAdminDashboard from "../modules/superadmin/dashboard/Dashboard";
import StudentDashboard from "../pages/home/components/dashboard/student/StudentDashboard";
import Message from "../pages/home/components/dashboard/teacher/Tab/Message";
import TeacherDashboard from "../pages/home/components/dashboard/teacher/TeacherDashboard";
import CreateCourse from "../pages/home/components/dashboard/teacher/course/CreateCourse";
import Settings from "../pages/home/components/settings/Settings";

const tabs = [
  {
    key: "Dashboard",
    value: imgs.svg.dashboard_sidebar,
    role: "Teacher",
    route: "home",
    Component: TeacherDashboard
  },
  { key: "Message", value: imgs.svg.sms_sidebar, role: "Teacher", route: "messages", Component: Message },
  { key: "Settings", value: imgs.svg.setting_sidebar, role: "Teacher", route: "settings", Component: Settings, props: { isDashboard: true } },
  {
    key: "Dashboard",
    value: imgs.svg.dashboard_sidebar,
    role: "SuperAdmin",
    route: "home",
    Component: SuperAdminDashboard
  },
  {
    key: "Teachers",
    value: imgs.svg.teachers_profile,
    role: "SuperAdmin",
    route: "teachers",
    Component: Teachers,
  },
  {
    key: "Students",
    value: imgs.svg.students_profile,
    role: "SuperAdmin",
    route: "students",
    Component: Students,
  },
  {
    key: "Schools",
    value: imgs.svg.schools,
    role: "SuperAdmin",
    route: "schools",
    Component: SchoolList,
  },
  {
    key: "Courses",
    value: imgs.svg.book,
    role: "SuperAdmin",
    route: "courses",
    Component: AllCoursesDashboard,
  },
  {
    key: "Ratings",
    value: imgs.svg.rating_star,
    role: "SuperAdmin",
    route: "ratings",
    Component: RatingCard
  },
  // {
  //   key: "Subscription",
  //   value: imgs.svg.setting_sidebar,
  //   role: "SuperAdmin",
  //   route: "subscription",
  //   //Component: Subscription
  // },
  // {
  //   key: "Settings",
  //   value: imgs.svg.setting_sidebar,
  //   role: "SuperAdmin",
  //   route: "settings",
  //   // Component: Settings
  // },
  {
    key: "Dashboard", value: imgs.svg.dashboard_sidebar, role: "Admin", route: "home",
    Component: ShowAdminDashboard
  },
  {
    key: "Teachers", value: imgs.svg.teachers_profile, role: "Admin", route: "teachers",
    Component: Teachers,
  },
  {
    key: "Students", value: imgs.svg.students_profile, role: "Admin", route: "students",
    Component: Students,
  },
  {
    key: "Courses", value: imgs.svg.book, role: "Admin", route: "courses",
    Component: AllCourses,
    //Component: CoursesDashboard,
  },
  // {
  //   key: "Settings", value: imgs.svg.setting_sidebar, role: "Admin", route: "settings",
  //   Component: AdminSettings,
  // },
];

function Dashboard() {
  const globalState = useSelector((state) => state.globalState);

  const role = globalState.role;
 
  const location = useLocation();
  const urlLocation = location.pathname.split("/")[location.pathname.split("/").length - 1];
 
  const tabList = tabs.filter((tab) => tab.role === role);
  let selectedTab;

  if (role === "Student") {
    selectedTab = "";
  } else {
    selectedTab = urlLocation.length ? tabList.find((tab) => tab.route === urlLocation)?.key : tabList[0].key;
  }
  //const selectedTab = urlLocation.length ? tabList.find((tab) => tab.route == urlLocation).key : tabList[0].key;

  const [activeTab, setActiveTab] = useState(selectedTab);
  const [entity, setEntity] = useState("");
  const [showCourse, setShowCourse] = useState(false);
  const [addSchool, setAddSchool] = useState(false);
  const [addAdmin, setaddAdmin] = useState(false);
  const [showAddProfile, setShowshowAddProfile] = useState(false);

  const toggleAddSchool = (overflow) => {
    setAddSchool(!addSchool);
    //document.body.style.overflow = overflow;
  };

  const toggleCourse = (overflow) => {
    setShowCourse(!showCourse);
    //document.body.style.overflow = overflow;
  };

  const toggleshowAddProfile = (overflow, entity) => {
    setShowshowAddProfile(!showAddProfile);
    setEntity(entity);
    // document.body.style.overflow = overflow;
  }

  const toggleAddAdmin = () => {
    setaddAdmin(!addAdmin)
  }

  return (
    <>
      {role === "Student" ? (
        <StudentDashboard />
      ) :
        <div className="dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="dashboardBody">
                  {role !== "Student" && (
                    <Sidebar
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      tabList={tabList}
                      toggleCourse={toggleCourse}
                      toggleAddSchool={toggleAddSchool}
                      toggleshowAddProfile={toggleshowAddProfile}
                      toggleAddAdmin={toggleAddAdmin}
                    />
                  )}
                  <div className="dashboardMainBody">


                    <Routes>
                      <Route path="/" element={<Navigate to="/dashboard/home" />} />
                      <Route path="*" element={<Navigate to="/ErrorPagenotFound" />} />
                      {tabList.map((tab) => (
                        <Route key={tab.key} path={tab.route} element={
                          React.createElement(tab.Component, tab.props ? tab.props : {})
                        } />
                      ))}
                      {tabList.map((tab) => (
                        <Route path={tab.route} element={tab.component} />
                      ))}
                    </Routes>

                    {/* {role === "Student" ? (
                    <StudentDashboard />
                  ) : role === "Teacher" ? (
                    <TeacherDashboard
                      tabList={tabList}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  ) : role === "Admin" ? (
                    <AdminDashboard
                      tabList={tabList}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  ) : role === "SuperAdmin" ? (
                    <SuperadminDashboard
                      tabList={tabList}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  ) : (
                    ""
                  )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      {showCourse && (
        <CreateCourse
          setShowCourse={setShowCourse}
          toggleCourse={toggleCourse}
        />
      )}

      {addSchool && (
        <AddSchool setAddSchool={addSchool} toggleAddSchool={toggleAddSchool} />
      )}

      {showAddProfile && <AddProfilePopup toggleshowAddProfile={toggleshowAddProfile} entity={entity} />}

      {addAdmin && <AddAdmin toggleAddAdmin={toggleAddAdmin} />}
    </>
  );
}

export default Dashboard;
