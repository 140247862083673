import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TextToSpeech {
    voice: any
    isLoading: boolean;
    success: boolean;
    error: string | null;
    generatorId: any,
}

const initialState: TextToSpeech = {
    voice: null,
    success: false,
    isLoading: false,
    error: null,
    generatorId: null,
};

const textToSpeechSplice = createSlice({
    name: 'texttospeech',
    initialState,
    reducers: {
        setTextToSpeech: (state: TextToSpeech, action: PayloadAction<TextToSpeech>) => {
            state.voice = action.payload;
            state.success = true;
            state.isLoading = false;
            state.error = null;
        },
        setGeneratorId: (state: TextToSpeech, action: PayloadAction<TextToSpeech>) => {
            state.generatorId = action.payload;
            state.success = true;
            state.isLoading = false;
            state.error = null;
        },
        loading: (state: TextToSpeech, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.success = false;
            state.error = null;
        },
        seterror: (state: TextToSpeech, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.success = false;
            state.isLoading = false;
        },
        resetTextToSpeech: () => {
            return initialState;
        },
    },
});

export const { setTextToSpeech, loading, seterror, resetTextToSpeech, setGeneratorId } = textToSpeechSplice.actions;

export default textToSpeechSplice.reducer;