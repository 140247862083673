import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UnlinkDrive {
    message: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: UnlinkDrive = {
    message: null,
    isLoading: false,
    error: null,
};


const unlinkGoogleDriveSlice = createSlice({
    name: "unlinkdrive",
    initialState,
    reducers: {
        unlinkGoogleDrive: (state: UnlinkDrive, action: PayloadAction<UnlinkDrive>) => {
            state.message = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: UnlinkDrive, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: UnlinkDrive, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetunlinkGoogleDrive: () => initialState,

    },
});

export const {
    unlinkGoogleDrive,
    setloading,
    seterror,
    resetunlinkGoogleDrive,
} = unlinkGoogleDriveSlice.actions;

export default unlinkGoogleDriveSlice.reducer;
