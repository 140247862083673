import { Dispatch } from "redux";
import api from "../../api/api";

import {
  setGoogleToken,
  setloading,
  seterror,
  resetGoogleToken,
} from "../reducers/getGoogleTokenReducer";

const getGoogleTokenAction = (token: string) => async (dispatch: Dispatch) => {
  dispatch(setloading(true));

  try {
    const response = await api.get(`/auth/google-login-token/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    dispatch(setloading(false));

    if (response.data.status === "success") {
      dispatch(setGoogleToken(response?.data?.data));
    }
    else {
      dispatch(seterror(response?.data?.message));
    }
  } catch (error : any) {
    dispatch(seterror(error?.response?.data?.message));
    dispatch(setloading(false));
  }
};

export { getGoogleTokenAction, resetGoogleToken };
