import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CourseFiles {
    courseFiles: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: CourseFiles = {
    courseFiles: [],
    isLoading: false,
    error: null,
};

const getCourseFilesSlice = createSlice({
    name: "getCourseFiles",
    initialState,
    reducers: {
        setUploadedCourseFiles: (state: CourseFiles, action: PayloadAction<CourseFiles>) => {
            state.courseFiles = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: CourseFiles, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: CourseFiles, action: PayloadAction<string>) => {
            state.courseFiles = [];
            state.error = action.payload;
            state.isLoading = false;
        },
        resetUploadedCourseFiles: () => {
            return initialState;
        },
    },
});

export const {
    setUploadedCourseFiles,
    setloading,
    seterror,
    resetUploadedCourseFiles,
} = getCourseFilesSlice.actions;

export default getCourseFilesSlice.reducer;
