import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { useSelector } from "react-redux";
import api from "../../../../../../api/api";
import { Link } from "react-router-dom";
import FilteredInput from "../../../../../../components/FilteredInput/FilteredInput";

const Message = () => {
    const [getEnrollStudent, setGetEnrollStudent] = useState({});
    const [getuserid, setuserid] = useState();
    const [getstatuspopup, setstatuspopup] = useState(false);
    const [getstudentname, setstudentname] = useState("");
    const [getstudentimage, setstudentimage] = useState("");
    const [getchathistory, setchathistory] = useState([]);
    const { token } = useAuth();
    const globalState = useSelector((state) => state.globalState);
    const teacherProfile = globalState?.teacher_details?.data?.ai_avatar;
    useEffect(() => {
      api
        .get("/auth/teacher/get-student-chat-list/", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            //return 1;
  
            if (response?.data) {
              setGetEnrollStudent(response?.data?.data);
            }
            // localStorage.setItem('datalist', JSON.stringify(response?.data ?? ''));
          }
        })
        .catch((error) => {});
  
      api
        .get("/auth/get-user-id/", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response?.data?.status === 200) {
            setuserid(response?.data?.user_id);
          }
        })
        .catch((error) => {
          //
        });
    }, []);
    const chatopenfn = (student_id, studentname, student_image) => {
      setstudentname(studentname);
      setstudentimage(student_image);
      const datadtl = {
        student_user_id: student_id,
        teacher_user_id: getuserid,
      };
      const url_chatbot = "/ai/api/student_chat_history/";
      api
        .post(url_chatbot, datadtl)
        .then((response) => {
          // setroomid(response?.data?.room_id);
          setchathistory(response?.data?.data);
          setstatuspopup(true);
        })
        .catch((error) => {});
    };
  
    const [search, setSearch] = useState("");
    const serachhandleInputChange = (event) => {
      const { value } = event.target;
      setSearch(value);
    };
    return (
      <>
        <div className="msg_sec_new">
          <div className="msg_sec_new_body_inner">
            <div className="msg_sec_new_body_inner_left">
              <div className="msg_left_search">
                <i className="fas fa-search"></i>
                <input
                  type="search"
                  value={search}
                  onChange={serachhandleInputChange}
                  placeholder="Search here"
                />
              </div>
              <div className="msg_sec_new__left_body">
                {Array.isArray(getEnrollStudent) ? (
                  getEnrollStudent
                    .filter((studentdts) => {
                      // Filter by search
                      let searchMatch = true;
                      if (search) {
                        searchMatch = studentdts?.name
                          ?.toLowerCase()
                          .includes(search?.toLowerCase());
                      }
  
                      return searchMatch;
                    })
                    .map((studentdts, index) => (
                      <div
                        className="msg_sec_new__left_single active"
                        onClick={() =>
                          chatopenfn(
                            studentdts.user,
                            studentdts.name,
                            studentdts.student_profile_pic
                          )
                        }
                        onDragStart={(e) => e.preventDefault()}
                      >
                        <Link className="msg_sec_new__left_single_inner">
                          <img src={studentdts.student_profile_pic} alt="" />
                          <div className="msg_data_sec">
                            <h6>{studentdts.name}</h6>
                            {/* <p>On going class</p> */}
                          </div>
                          {/* <span>11:11</span> */}
                        </Link>
                      </div>
                    ))
                ) : (
                  <p>No chat found.</p> // Or any other fallback UI
                )}
              </div>
            </div>
            <div className="msg_sec_new_body_inner_right">
              {getstatuspopup ? (
                <>
                  <div className="right_chat_sec_new">
                    <div className="right_chat_sec_new_head">
                      <img src={getstudentimage} alt="" />
                      <span>
                        <h6>{getstudentname}</h6>
                        {/* <p>Online</p> */}
                      </span>
                    </div>
                    <div className="right_chat_sec_new_body_sec">
                      {getchathistory.length > 0 ? (
                        getchathistory.map((chatdtls, index) => (
                          <>
                            <div
                              key={`left_${index}`}
                              className="right_chat_sec_left"
                            >
                              <img src={getstudentimage} alt="Chat" />
                              <span>{chatdtls.query}</span>
                            </div>
                            <div
                              key={`right_${index}`}
                              className="right_chat_sec_right"
                            >
                              <span>{chatdtls.answer}</span>
                              <img src={teacherProfile} alt="Teach" />
                            </div>
                          </>
                        ))
                      ) : (
                        <p>No Messages found.</p> // Or any other fallback UI
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  export default Message;