import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api, { shareUrl } from "../../../../../../api/api";
import { Create_lesson_logo } from "../../../../../../assets/images/images";
import UniversalChatbot from "../../../../../../components/universalChatbot/UniversalChatbot";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { changeScrollPopup } from "../../../../../../utils/utlis";
import CourseDetailsHeader from "./CourseDetailsHeader";
import CourseTab from "./CourseTab";
import FilteredInput from "../../../../../../components/FilteredInput/FilteredInput";
import {
  deleteLessonById,
  resetdeleteLesson,
} from "../../../../../../state/actions/deleteLessonAction";
import {
  deleteAssignmentById,
  resetdeleteAssignment,
} from "../../../../../../state/actions/deleteAssignmentAction";
import { alert_image,assignments_count } from "../../../../../../assets/images/images";
import MenuItem from "@mui/material/MenuItem";
import StyledMenu from "../../../../../../components/StyleMenu";

const LessonCard = ({ lesson, assignment, course, teacher_id }) => {
  const { token } = useAuth();
  const navigate = useNavigate();

  const [showDropdown2, setShowDropdown2] = useState(false);

  const [assignments, setAssignments] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const deleteAssignmentState = useSelector((state) => state.deleteAssignment);
  const { message, isLoading, error, success } = useSelector(
    (state) => state.deleteLesson
  );

  useEffect(() => {
    localStorage.removeItem("assignmentanalyticsdetails");
    api
      .get(`assignments/get-assignments-by-lesson/${lesson.lesson_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`, // `Token ${token}`
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAssignments(response?.data?.data ?? []);
        }
      })
      .catch((error) => {
        setAssignments([]);
      });
  }, [deleteAssignmentState.success, success]);

  const [deleteAssignment, setdeleteAssignment] = useState(false);
  const [deleteLesson, setdeleteLesson] = useState(false);

  const deleteCourseId = (id) => {
    dispatch(deleteLessonById(id, token));
  };

  const deleteAssignmentReq = (id) => {
    dispatch(deleteAssignmentById(id, token));
  };

  const onPreviewLesson = (lesson) => {
    navigate("/NewLessons", { state: { course, teacher_id, lesson } });
  };

  useEffect(() => {
    if (success) {
      setdeleteLesson(false);
    }
    if (deleteAssignmentState.success) {
      setdeleteAssignment(false);
    }
  }, [deleteAssignmentState, success])

  return (
    <div className="view-lessons-created-course-bg-wrapper">
      <div className="view-lessons-created-course-wrapper">
        <div className="view-lessons-created-course-inner-prewview-container">
          <div className="view-lessons-created-course-inner-preview-title">
            {/* <i
                className={`fa-solid ${
                  showDropdown2 ? "fa-angle-down" : "fa-angle-right"
                }`}
              ></i> */}

            <p>{lesson.name || ""}</p>
          </div>
          <div className="view-lessons-created-course-inner-preview-btn-folder">
            {/* <p className="assignments-count"
              style={{ fontSize: "14px", padding: "6px", fontWeight: "600" }}
            >
              Assignment: {assignments?.length}
            </p> */}
            <div
                className="assignments_count_details"
                onClick={(e) => {
                  e.preventDefault();
                 
                  const course_id = course?.course_id;
                  const lesson_id = lesson?.lesson_id;
                  navigate(
                    `/course-assignment-list/${lesson_id}`,
                    { state: { course, teacher_id, lesson } }
                  );
                }}
              >
                <img src={assignments_count}/>
                {`  ${lesson?.assignment_count} Assignment`}
            </div>
            <button
              type="button"
              onClick={() => onPreviewLesson(lesson)}
              className="Preview-lesson-btn"
            >
              Preview Lesson
            </button>
            <div
              className="View-Progress-btn"
              onClick={(e) => {
                e.preventDefault();
                const course_id = course?.course_id;
                const lesson_id = lesson?.lesson_id;
                navigate(`/course/${course_id}/lesson/${lesson_id}/progress`, {
                  state: { course, teacher_id, lesson, assignments },
                });
              }}
            >
              Assignment Progress
            </div>
            {/* {dropDown(lesson, assignment)} */}
            {/* <button type="button" className="Create-Assignment-btn">
                Create Assignment
              </button> */}
            {/* <i class="fa-solid fa-trash" data-tooltip-id="delete-lesson" onClick={() => setdeleteLesson(true)}></i>
              <Tooltip
                id="delete-lesson"
                place="bottom"
                content="Delete Lesson"
                type="light"
                effect="float"
              /> */}

            <div className="edit-course-dropdown-menu-container">
              <div
                className="edit-course-or-delete-course"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </div>
              <StyledMenu
                id="demo-customized-menu"
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                
                {/* <MenuItem onClick={() => onPreviewLesson(lesson)} disableRipple>
                  <button
                    type="button"
                    className="course-edit-or-delete-folder"
                  >
                    <a href="#">
                      <i className="fa-solid fa-pencil"></i>
                    </a>
                    <p>Edit</p>
                  </button>
                </MenuItem> */}
                <MenuItem
                  onClick={() => (setdeleteLesson(true), setAnchorEl(null))}
                  disableRipple
                >
                  <button
                    type="button"
                    className="course-edit-or-delete-folder"
                  >
                    <a href="#">
                      <i className="fa-solid fa-trash"></i>
                    </a>
                    <p>Delete Lesson</p>
                  </button>
                </MenuItem>
                {/* {assignments?.length > 0 && (
                  <MenuItem
                    onClick={() => (
                      setdeleteAssignment(true), setAnchorEl(null)
                    )}
                    disableRipple
                  >
                    <button
                      type="button"
                      className="course-edit-or-delete-folder"
                    >
                      <a href="#">
                        <i className="fa-solid fa-trash"></i>
                      </a>
                      <p>Delete Assignment</p>
                    </button>
                  </MenuItem>
                )} */}
              </StyledMenu>
            </div>
          </div>
        </div>
        {/* {showDropdown2 && (
            <div className="assignments-sec-container">
              <div className="Overview-Lesson-date-container">
                <div className="Overview-Lesson-date">
                  <span>Lesson Start Date:</span>
                  <div className="Overview-Lesson-date-counts">
                    <p>{dayjs(lesson.start_date).format("MMM DD, YYYY")} </p>
                  </div>
                </div>
                <div className="Overview-Lesson-date">
                  <span>Lesson End Date:</span>
                  <div
                    className="Overview-Lesson-date-counts"
                    onClick={() => handleTabClick("students")}
                  >
                    <p>
                      {lesson.end_date !== null
                        ? dayjs(lesson.end_date).format("MMM DD, YYYY")
                        : "- -"}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <p
                className="Overview-Lesson-description"
                dangerouslySetInnerHTML={{ __html: lesson.description }}
              />
              {assignments.length > 0 && (
                <div className="preview-assignments-wrapper">
                  <div className="preview-assignments-wrapper">
                    <h2>Current Assignment</h2>
                    <div className="preview-assignments-inner-section">
                      {assignments.map((assignment, index) => (
                        <AssignmentCard
                          key={index}
                          assignment={assignment}
                          lesson={lesson}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )} */}
      </div>
      {deleteLesson && (
        <form action="" className="delete-course-form">
          <div className="delete-course-form-container" data-aos="zoom-in">
            <div className="delete-course-alert-image">
              <img src={alert_image} alt="" />
            </div>
            <h2>Are you sure you want to delete this lesson?</h2>
            <div className="delete-course-form-btn-folder">
              <button
                type="button"
                onClick={() => setdeleteLesson(false)}
                className="not-delete-btn"
              >
                No
              </button>
              {isLoading ? (
                <div className="loading-spinner" />
              ) : (
                <button
                  type="button"
                  onClick={() => deleteCourseId(lesson.lesson_id)}
                  disabled={isLoading}
                  className="delete-btn"
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </form>
      )}

      {deleteAssignment && (
        <form action="" className="delete-course-form">
          <div className="delete-course-form-container" data-aos="zoom-in">
            <div className="delete-course-alert-image">
              <img src={alert_image} alt="" />
            </div>
            <h2>Are you sure you want to delete this assignment?</h2>
            <div className="delete-course-form-btn-folder">
              <button
                type="button"
                onClick={() => setdeleteAssignment(false)}
                className="not-delete-btn"
              >
                No
              </button>
              {deleteAssignmentState?.isLoading ? (
                <div className="loading-spinner" />
              ) : (
                <button
                  type="button"
                  onClick={() =>
                    deleteAssignmentReq(assignments[0]?.assignment_id)
                  }
                  disabled={deleteAssignmentState?.isLoading}
                  className="delete-btn"
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default LessonCard;
