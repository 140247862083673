import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { plus_rounded, google_drive } from "../../assets/images/images";
import { Link, useLocation } from "react-router-dom";
import GoogleLoginComponent from "../GoogleLogin/GoogleLoginComponent";

const Sidebar = ({
  activeTab,
  setActiveTab,
  tabList,
  toggleCourse,
  toggleAddSchool,
  toggleshowAddProfile,
  toggleAddAdmin,
}) => {
  const { role } = useSelector((state) => state.globalState);
  const location = useLocation();
  const urlLocation = location.pathname.split("/")[location.pathname.split("/").length - 1];

  let selectedTab;

  if (role === "Student") {
    selectedTab = "";
  } else {
    selectedTab = urlLocation.length ? tabList.find((tab) => tab.route === urlLocation)?.key : tabList[0].key;
  }


  return (
    <>
      <div className="dashboardSidePannel">
        {role === "Teacher" && activeTab === tabList[0].key ? (
          <div className="create-course" onClick={() => toggleCourse("hidden")}>
            <ReactSVG src={plus_rounded} />
            <p className="create-course-text">Create Course</p>
          </div>
        ) : null}

        {(role === "Admin" || role === "SuperAdmin") &&
          selectedTab === "Teachers" ? (
          <div className="create-course" onClick={() => toggleshowAddProfile("hidden", "Teacher")}>
            <ReactSVG src={plus_rounded} />
            <p className="create-course-text">Add Teacher</p>
          </div>
        ) : null}

        {(role === "Admin" || role === "SuperAdmin") &&
          selectedTab === "Students" ? (
          <div className="create-course" onClick={() => toggleshowAddProfile("hidden", "Student")}>
            <ReactSVG src={plus_rounded} />
            <p className="create-course-text">Add Student</p>
          </div>
        ) : null}

        {role === "SuperAdmin" && selectedTab === "Schools" ? (
          <div
            className="create-course"
            onClick={() => toggleAddSchool("hidden")}
          >
            <ReactSVG src={plus_rounded} />
            <p className="create-course-text">Add School</p>
          </div>
        ) : null}

        {tabList
          .filter((tab) => tab.role === role)
          .map((tab, index) => {
            return (
              <Link
                to={`/dashboard/${tab.route.toLowerCase()}`}
                key={index}
                onClick={() => setActiveTab(tab.key)}
                className={(tab.key === selectedTab ? "active" : "") + " dashboardlink"}
              >
                <ReactSVG
                  src={tab.value}
                  className={
                    selectedTab === tab.key ? "svg-active" : "svg-inactive"
                  }
                />
                {tab.key}
              </Link>
            );
          })}
        {role === "Admin" ? (
          <div className="create-course"
            onClick={toggleAddAdmin}
          >
            <ReactSVG src={plus_rounded} />
            <p className="create-course-text">Add Admin</p>
          </div>
        ) : null}

        {/* <GoogleLoginComponent /> */}
      </div>
    </>
  );
};

export default Sidebar;
