import { Dispatch } from "redux";
import api from "../../api/api";
import {
  setSyncSchoology, setloading, seterror, resetSyncSchoology
} from "../reducers/setSyncSchoologyReducer";

// const setSyncSchoologydtls = (token: any,data: any ) => async (dispatch: Dispatch) => {
//    dispatch(setloading(true));
//    try {
//      const result = await api
//        .post(
//         `/courses/process-teacher-data-from-schoology/`,
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Token ${token}`,
//           },
//         }
//        );
//        if(result){
//         dispatch(setloading(false));
//         if (result.data?.status === 200) {
//                 dispatch(setSyncSchoology(result.data.message));
//           }
//           else {
//             dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
//           }
//        }
//    }
//    catch (error: any) {
//     dispatch(seterror(error.response.data.message));
//    }
// }

const setSyncSchoologydtls = (token: any, data: any) => async (dispatch: Dispatch) => {
  dispatch(setloading(true));
  try {
    const result = await api.post(
      `/courses/process-teacher-data-from-schoology/`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        timeout: 100000,
      }
    );

    dispatch(setloading(false));

    if (result.status === 200) {
      dispatch(setSyncSchoology(result.data.message));
    } else {
      dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
    }
  } catch (error: any) {
    // Check if the error response exists and has a status code
    if (error.response) {
      const statusCode = error.response.status;
      let errorMessage = "An error occurred.";

      if (statusCode === 502) {
        errorMessage = "502 Bad Gateway: The server is currently unavailable. Please try again later.";
      } else if (statusCode === 500) {
        errorMessage = "500 Internal Server Error: Something went wrong on the server.";
      } else if (statusCode === 404) {
        errorMessage = "404 Not Found: The requested resource could not be found.";
      } else {
        errorMessage = error.response.data?.message ?? "An unexpected error occurred.";
      }

      dispatch(seterror(errorMessage));
    } else {
      // Handle network errors or unexpected errors
      dispatch(seterror(error.response.data.error ?? "Something Went Wrong!"));
    }
  }
};

export { setSyncSchoologydtls, resetSyncSchoology };

