import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import api from "../../../../../../api/api";
import { getUserId } from "../../../../../../api/get_user_id";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import FilteredInput from "../../../../../../components/FilteredInput/FilteredInput";

function MyTeacher({ profileFirstName }) {
  const [getEnrollTeacher, setGetEnrollTeacher] = useState({});
  const [userId, setUserId] = useState();
  const [getstatuspopup, setstatuspopup] = useState(false);
  const [getstudentname, setstudentname] = useState("");
  const [getstudentimage, setstudentimage] = useState("");
  const [getchathistory, setchathistory] = useState([]);
  const { token } = useAuth();
  const globalState = useSelector((state) => state.globalState);
  const studentProfile =
    globalState?.student_details?.data?.student_profile_pic;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showLeftPanel, setShowLeftPanel] = useState(true); // New state for controlling visibility

  useEffect(() => {
    api
      .get("/students/get-teacher-chat-list/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response?.data) {
            setGetEnrollTeacher(response?.data?.data);
          }
        }
      })
      .catch((error) => {});

    getUserId(token).then((user_id) => {
      if (user_id !== null) {
        setUserId(user_id);
      }
    });
  }, [token]);
  const [getTecherID, setTeacherId] = useState('');
  const chatopenfn = (teacher_user_id, studentname, student_image,teacherId) => {
    setTeacherId(teacherId);
    setstudentname(studentname);
    setstudentimage(student_image);
    const datadtl = {
      student_user_id: userId,
      teacher_user_id: teacher_user_id,
    };
    const url_chatbot = "/ai/api/student_chat_history/";
    api
      .post(url_chatbot, datadtl)
      .then((response) => {
        setchathistory(response?.data?.data);
        setstatuspopup(true);
        // setIsMobileView(false);
        if (isMobile) {
          setShowLeftPanel(false); // Hide left panel on mobile when chat is opened
        }
      })
      .catch((error) => {});
  };

  const [search, setSearch] = useState("");
  const serachhandleInputChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const handleBackButtonClick = () => {
    setstatuspopup(false); // Hide chat section
    setShowLeftPanel(true); // Show left panel, especially for mobile
  };

  return (
    <>
      <section className="msg_sec_new_inner_teacher">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="msg_sec_new_body_inner">
                {(!isMobile || showLeftPanel) && (
                  <div className="msg_sec_new_body_inner_left">
                    <div className="msg_left_search">
                      <i className="fas fa-search"></i>
                      <input
                        type="search"
                        value={search}
                        onChange={serachhandleInputChange}
                        placeholder="Search here"
                      />
                    </div>
                    <div className="msg_sec_new__left_body">
                      {Array.isArray(getEnrollTeacher) ? (
                        getEnrollTeacher
                          .filter((teacherdtls) => {
                            // Filter by search
                            let searchMatch = true;
                            if (search) {
                              searchMatch = teacherdtls?.name
                                ?.toLowerCase()
                                .includes(search?.toLowerCase());
                            }

                            return searchMatch;
                          })
                          .map((teacherdtls, index) => (
                            <div
                              className="msg_sec_new__left_single active"
                              onClick={() =>
                                chatopenfn(
                                  teacherdtls.user,
                                  teacherdtls.name,
                                  teacherdtls.ai_avatar,
                                  teacherdtls.teacher_id
                                )
                              }
                              onDragStart={(e) => e.preventDefault()}
                            >
                              <Link className="msg_sec_new__left_single_inner">
                                <img src={teacherdtls.ai_avatar} alt="" />
                                <div className="msg_data_sec">
                                  <h6>{teacherdtls.name}</h6>
                                  {/* <p>On going class</p> */}
                                </div>
                              </Link>
                            </div>
                          ))
                      ) : (
                        <p>No Teacher found.</p> // Or any other fallback UI
                      )}
                    </div>
                  </div>
                )}

                <div className="msg_sec_new_body_inner_right">
                  {getstatuspopup ? (
                    <>
                      <div className="right_chat_sec_new">
                        <div className="right_chat_sec-profile">
                          <i class="fa-solid fa-chevron-left" onClick={handleBackButtonClick}></i>
                          <NavLink to={`/teacher/${getTecherID}`}>
                            <div className="right_chat_sec_new_head">
                              <img src={getstudentimage} alt="" />
                              <span>
                                <h6>{getstudentname}</h6>
                                {/* <p>Online</p> */}
                              </span>
                            </div>
                          </NavLink>
                        </div>
                        <div className="right_chat_sec_new_body_sec">
                          {getchathistory.length > 0 ? (
                            getchathistory.map((chatdtls, index) => (
                              <>
                                <div
                                  key={`right_${index}`}
                                  className="right_chat_sec_right"
                                >
                                  <span>{chatdtls.query}</span>
                                  <img src={studentProfile} alt="Teach" />
                                </div>
                                <div
                                  key={`left_${index}`}
                                  className="right_chat_sec_left"
                                >
                                  <img src={getstudentimage} alt="Chat" />
                                  <span>{chatdtls.answer}</span>
                                </div>
                              </>
                            ))
                          ) : (
                            <p>No Chat Found.</p> // Or any other fallback UI
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {profileFirstName}
    </>
  );
}

export default MyTeacher;