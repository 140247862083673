import moment from "moment";
import Datetime from "react-datetime";
import { format } from "date-fns";
import dayjs from "dayjs";

import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../../api/api";
import {
    calender_image,
    email_image,
    gender_image,
    location_image,
    profile_image,
    studentDashboard_AI_chatbot_image,
  } from "../../../../../../assets/images/images";
  
import imgs from "../../../../../../assets/images/imgs";
import {
  isRequired,
  isValidEmail,
  isValidMobileNumber,
} from "../../../../../../utils/validator";

import BackButton from "../../../../../../components/BackButton/BackButton";
import FilteredInput from "../../../../../../components/FilteredInput/FilteredInput";

const StudentProfileDetails = () => {
    const { token } = useAuth();
    const [subjects, setSubjects] = useState([]);
    const autocompleteInputRef = useRef(null);
    const [getinfoStudent, setinfoStudent] = useState({});
    const [selectedCountry, setSelectedCountry] = useState({
        name: "United States",
        iso2: "us",
        dialCode: "1",
        format: "+...-...-....",
        hasAreaCodes: false,
      });
    const {  student_id } = useParams();
    const [student, setStudent] = useState(null);
    function getKeysByValuesAsString(object, valueArray) {
        const keys = valueArray.map((value) => {
          const values = Object.values(object);
          const index = values.findIndex((item) => item === value);
    
          if (index === -1) {
            return null; // Value not found
          }
    
          const keys = Object.keys(object);
          return keys[index];
        });
    
        return keys.join(",");
      }
      useEffect(() => {
        api
          .get(`/students/teacher/get-student/?student_id=${student_id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setinfoStudent(response?.data?.data ?? []);
            }
          })
          .catch((error) => {
            //
          });
      }, [student_id]);
    function formatDate2(dateString) {
        try {
          if (!dateString) {
            return "-";
          }
          const options = { year: "numeric", month: "short", day: "numeric" };
          const date = new Date(dateString);
          if (
            !date.toLocaleDateString("en-US", options).replace(/\s/g, " ") ===
            "Invalid Date"
          ) {
            return "-";
          }
          return date.toLocaleDateString("en-US", options).replace(/\s/g, " ");
        } catch (error) {
          return "-";
        }
      }
      
  function isValidDate(val) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let selectedDate = new Date(val);
    if (selectedDate > today) {
      return false;
    }
    return true;
  }

  const handleDateChanged = (value) => {
    let error = "";
    error = isRequired(value);
    setFormErrors({
      ...formErrors,
      ["dob"]: error,
    });
    setFormState((prev) => ({
      ...prev,
      ["dob"]: format(new Date(value), "yyyy-MM-dd"),
    }));
  };
  const globalState = useSelector((state) => state.globalState);
  const [formErrors, setFormErrors] = useState({
    name: "",
    country_code: "",
    // phone: "",
    dob: "",
    gender: "",
    email: "",
    language: "",
    location: "",
    introduction: "",
    subject_ids: "",
    ai_avatar: "",
    ai_name: "",
    ai_tone_name: "",
    ai_welcome_msg: "",
  });
  const [formState, setFormState] = useState({
    name: student?.name || "",
    // phone: student?.phone || "",
    country_code: student?.country_code || "+1",
    dob: student?.dob || "",
    gender: student?.gender || "",
    email: globalState?.email || student?.email || "",
    language: student?.language || "",
    location: student?.location || "",
    introduction: student?.introduction || "",
    subject_ids: student?.subject_ids || "",
    ai_avatar: null,
    ai_name: "",
    ai_tone_name: "",
    ai_welcome_msg: "",
  });
 
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let error = "";
    if (name === "name") {
      error = isRequired(value);
    } else if (name === "email") {
      error = isRequired(value) || isValidEmail(value);
    }
    // else if (name === "phone") {
    //   error =
    //     isRequired(value) ||
    //     isValidMobileNumber(value, selectedCountry.dialCode);
    // }
    else if (name === "gender") {
      error = isRequired(value);
    } else if (name === "language") {
      error = isRequired(value);
    } else if (name === "location") {
      error = isRequired(value);
    } else if (name === "introduction") {
      error = isRequired(value);
    } else if (name === "subject_ids") {
      error = isRequired(value);
    } else if (name === "ai_avatar") {
      error = isRequired(value);
    } else if (name === "ai_name") {
      error = isRequired(value);
    } else if (name === "ai_tone_name") {
      error = isRequired(value);
    } else if (name === "ai_welcome_msg") {
      error = isRequired(value);
    } else if (name === "country_code") {
      error = isValidMobileNumber(value, selectedCountry.dialCode);
    }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });
    if (name === "subject_ids") {
      setFormState((prev) => ({
        ...prev,
        subject_ids: getKeysByValuesAsString(
          globalState?.datalist?.subjects || [],
          subjects
        ),
      }));
    } else {
      setFormState((prev) => ({ ...prev, [name]: value }));
    }
  };
return (
        <>
    <div className="Teacher-student-edit-profile-wrapper">
                      <div className="view-course-Details-back-btn-folder Teacher-student-edit-profile-back-btn">
                        <BackButton buttonName={"Student Profile"} />
                        {/* <span style={{ fontWeight: "600", width: "100%", textAlign: "center" }}>Student Profile</span> */}
                        {/* <i
                          onClick={() => navigate(-1)}
                          className="fa-solid fa-arrow-left"
                        ></i>
                        <h2>Student Profile</h2> */}
                      </div>
                      <div className="Teacher-student-edit-profile-container">
                        <div className="Teacher-student-edit-profile-left-section">
                          <div className="Personalize-your-AI-Assistant-uplode-image">
                            <img
                              src={
                                getinfoStudent?.student_profile_pic ||
                                imgs.png.student_chatbot_image
                              }
                              alt=""
                            />
                            {/* <input
                              type="file"
                              id="choos-profile-image"
                              name="ai_avatar"
                              onChange={handleFileInput}
                            /> */}
                            {/* <div className="dashboard-popup-teacher-profile-details-image-plus-btn Teacher-student-edit-profile-btn">
                              <img src={student_edit_icon} alt="" />
                            </div> */}
                          </div>
                          <span>{getinfoStudent?.name}</span>
                        </div>
                        <div className="Teacher-student-edit-profile-right-section">
                          <h2>About Student</h2>
                          <div className="Teacher-student-edit-profile-inner-des-wrapper">
                            <p className="Teacher-student-edit-profile-Joining-date">
                              Joining Date:{" "}
                              <span>
                                {formatDate2(getinfoStudent?.joined_at)}
                              </span>
                            </p>
                            <p>{getinfoStudent.introduction}</p>
                          </div>
                        </div>
                      </div>
                      {/* <ViewMoreComponent
                        showMore={showMore1}
                        handleViewMoreClick={handleViewMoreClick1}
                        data={temp_student_data?.student_assessment}
                        header_image={imgs.png.award}
                        header_text="Student Assessment (AI)"
                      />
                      <ViewMoreComponent
                        showMore={showMore2}
                        handleViewMoreClick={handleViewMoreClick2}
                        data={temp_student_data?.student_assessment2}
                        header_image={imgs.png.book_2}
                        header_text="AI Recommendation"
                      /> */}

                      <div className="dashboard-popup-teacher-profile-details-form">
                        <div className="TeacherProfile-Language-folder TeacherProfile-Subject-folder">
                          <label>Subject Interested in</label>
                          <div className="TeacherProfile-Language-inner-folder">
                            {getinfoStudent?.interests &&
                            Object.values(getinfoStudent?.interests).length >
                              0 ? (
                              Object.values(
                                getinfoStudent?.interests
                              ).map((interest, index) => <p>{interest}</p>)
                            ) : (
                              <h6>No interests are selected</h6>
                            )}
                            {/* <p>Chemistry</p>
                            <p>Biochemistry</p> */}
                          </div>
                        </div>
                        <div className="dashboard-popup-teacher-profile-details-form-container">
                          <div className="dashboard-popup-teacher-profile-details-input">
                            <label>Name</label>
                            <input
                              disabled
                              type="name"
                              name="name"
                              style={
                                formErrors.name
                                  ? { border: "1px solid red" }
                                  : null
                              }
                              value={getinfoStudent?.name || ""}
                              placeholder="Name"
                              onChange={handleInputChange}
                            />
                            <div className="teacher-create-popup-gender-input-folder">
                              <div className="form-icon">
                                <img src={profile_image} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="dashboard-popup-teacher-profile-details-input">
                            <label>Email Address</label>
                            <input
                              type="text"
                              placeholder="email id"
                              name="email"
                              disabled
                              style={
                                formErrors.email
                                  ? { border: "1px solid red" }
                                  : null
                              }
                              value={getinfoStudent?.email || ""}
                              onChange={handleInputChange}
                            />
                            <div className="form-icon">
                              <img src={email_image} alt="" />
                            </div>
                          </div>

                          {/* <div className="dashboard-popup-teacher-profile-details-input">
                            <label>Phone Number</label>
                            <input
                              type="text"
                              placeholder="Mobile Number"
                              name="phone"
                              style={
                                formErrors.phone
                                  ? { border: "1px solid red" }
                                  : null
                              }
                              value={
                                getinfoStudent?.country_code +
                                " " +
                                getinfoStudent?.phone
                              }
                              onChange={handleInputChange}
                            />
                            <div className="form-icon">
                              <img src={call_image} alt="" />
                            </div>
                          </div> */}
                          <div className="dashboard-popup-teacher-profile-details-input">
                            <label>Gender</label>
                            <div className="dashboard-popup-teacher-profile-gender-folder">
                              <select
                                disabled
                                name="gender"
                                style={
                                  formErrors.gender
                                    ? { border: "1px solid red" }
                                    : null
                                }
                                value={getinfoStudent?.gender || ""}
                                onChange={handleInputChange}
                              >
                                <option value="">Choose Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                              </select>
                              <div className="form-icon">
                                <img src={gender_image} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="dashboard-popup-teacher-profile-details-input">
                            <label>Birth Date</label>
                            <Datetime
                              name="dob"
                              className={
                                formErrors.dob ? "datetime-error" : "datetime"
                              }
                              timeFormat={false}
                              inputProps={{
                                placeholder: "MM:DD:YYYY",
                                disabled: true,
                              }}
                              max={moment().toDate()} // assuming 'today' is a moment object
                              value={
                                getinfoStudent?.dob
                                  ? moment(getinfoStudent.dob)
                                  : null
                              }
                              closeOnSelect={true}
                              onChange={handleDateChanged}
                              id="Birth-Date-input"
                              dateFormat="MMM DD, YYYY"
                              isValidDate={isValidDate}
                            />
                            <div className="form-icon">
                              <img src={calender_image} alt="" />
                            </div>
                          </div>

                          <div className="dashboard-popup-teacher-profile-details-input">
                            <label>Location</label>
                            <input
                              type="text"
                              placeholder="Enter location"
                              ref={autocompleteInputRef}
                              name="location"
                              style={
                                formErrors.location
                                  ? { border: "1px solid red" }
                                  : null
                              }
                              disabled
                              value={getinfoStudent.location || ""}
                              onChange={handleInputChange}
                            />
                            <div className="form-icon location-icon">
                              <img src={location_image} />
                            </div>
                          </div>
                        </div>
                        <div className="TeacherProfile-Language-folder">
                          <label>Language</label>
                          <div className="TeacherProfile-Language-inner-folder">
                            {getinfoStudent?.language &&
                            Object.values(getinfoStudent?.language).length >
                              0 ? (
                              Object.values(
                                getinfoStudent?.language
                              ).map((lang, index) => <p>{lang}</p>)
                            ) : (
                              <h6>No language are selected</h6>
                            )}
                            {/* <p>English</p>
                            <p>English</p>
                            <p>English</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    </>
)
};

export default StudentProfileDetails;