import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { formatTimeAssignment } from "../../utils/utlis";
import { useAuth } from "../../features/auth/AuthContext";

const AssignmentTimer = ({
  duration,
  allotted_time,
  onTimeUp,
  isTimerPaused,
  timers,
  setTimers,
  currentStep,
  isAssignmentLoading,
  assignmentClockTimer,
}) => {
  const { assignment_id } = useParams();
  const { token } = useAuth();
  const getInitialSeconds = () => {
    const savedSeconds = localStorage.getItem(`timerSeconds${assignment_id}${token}`);
    return savedSeconds ? parseInt(savedSeconds, 10) : duration;
  };

  const [secondsRemaining, setSecondsRemaining] = useState(getInitialSeconds);
  const hasTimeUpBeenCalled = useRef(false);

  useEffect(() => {
    if (isAssignmentLoading) return;

    const interval = setInterval(() => {
      setSecondsRemaining((prevSeconds) => {
        const newSeconds = Math.max(prevSeconds - 1, 0);
        localStorage.setItem(`timerSeconds${assignment_id}${token}`, newSeconds);
        return newSeconds;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isAssignmentLoading, assignment_id]);

  useEffect(() => {
    const savedTimers = JSON.parse(localStorage.getItem(`assignmentTimers${assignment_id}${token}`)) || [];
    const processedTimers = savedTimers.map(timer => timer === null ? 0 : timer);
    setTimers(processedTimers);

    const interval = setInterval(() => {
      if (!assignmentClockTimer) return;
      setTimers((prevTimers) => {
        const updatedTimers = [...prevTimers];
        if (updatedTimers[currentStep] === undefined) {
          updatedTimers[currentStep] = 0;
        }
        updatedTimers[currentStep] += 1;
        localStorage.setItem(`assignmentTimers${assignment_id}${token}`, JSON.stringify(updatedTimers));
        return updatedTimers;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [currentStep, assignmentClockTimer, assignment_id, setTimers]);

  useEffect(() => {
    if (secondsRemaining <= 0 && !hasTimeUpBeenCalled.current) {
      onTimeUp();
      hasTimeUpBeenCalled.current = true;
    }
  }, [secondsRemaining, onTimeUp]);

  useEffect(() => {
    if (isTimerPaused) return;

    const intervalId = setInterval(() => {
      setSecondsRemaining((prevSeconds) => Math.max(prevSeconds - 1, 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isTimerPaused]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        localStorage.setItem(`timerSeconds${assignment_id}${token}`, secondsRemaining);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [secondsRemaining, assignment_id]);

  const minutes = Math.floor(secondsRemaining / 60);
  const seconds = secondsRemaining % 60;

  const [secondsElapsed, setSecondsElapsed] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (assignmentClockTimer && !isAssignmentLoading) {
        setSecondsElapsed((prevSeconds) => prevSeconds + 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [assignmentClockTimer, isAssignmentLoading]);

  const hours = Math.floor(secondsElapsed / 3600);
  const mins = Math.floor((secondsElapsed % 3600) / 60);
  const secs = secondsElapsed % 60;

  const formattedTime = [
    hours.toString().padStart(2, '0'),
    mins.toString().padStart(2, '0'),
    secs.toString().padStart(2, '0')
  ].join(':');

  return (
    <div className="AssingmentMCQs-Time-remaining-folder">
      <p>Allotted Time: {allotted_time} Minutes</p>
      <div className="AssingmentMCQs-Time-remaining">
        <p>Timer (Q.{currentStep + 1}):</p>
        <span>
          <i className="fa-solid fa-clock"></i>
        </span>
        <span>
          {formatTimeAssignment(timers[currentStep]) || 0}
        </span>
      </div>
    </div>
  );
};

export default AssignmentTimer;
