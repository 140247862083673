import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../features/auth/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCourseList, resetgetAllCoursesList } from "../../../state/actions/getAllCoursesAction";
import CustomTabs from '../../../components/CustomTabs/CustomTabs';
import CoursesList from './CoursesList';
import PaginationView from "../../../components/Pagination/PaginationView";
import SearchComponent from "../../../components/SearchComponent/SearchComponent";
import {
    search,
    srot
} from "../../../assets/images/images";
import { toast } from "react-toastify";
import SortPopup from '../../../pages/customSort/SortPopup';
import {
    getCourseBySchoolId,
    resetCourseListBySchoolId,
} from "../../../state/actions/getCourseListBySchoolIDAction";
import { addToHistory } from '../../../state/reducers/historyReducer';

function AllCourses() {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchOption, setsearchOption] = useState("");
    const [sortOption, setSortOption] = useState("");
    const [isVisible, setIsVisible] = useState(false);

    const tabList = [
        {
            title: "Courses",
            displayItem: <CoursesList currentPage={currentPage} setCurrentPage={setCurrentPage} />,
            key: "", // Add the title property
        },
        // {
        //     title: "Sales Courses",
        //     displayItem: <CoursesList currentPage={currentPage} setCurrentPage={setCurrentPage} />,
        //     key: "sales",
        // },
        // {
        //     title: "Individual Courses",
        //     displayItem: <CoursesList currentPage={currentPage} setCurrentPage={setCurrentPage} />,
        //     key: "individual",
        // },
        {
            title: "Expired Courses",
            displayItem: <CoursesList currentPage={currentPage} setCurrentPage={setCurrentPage} />,
            key: "expired",
        },
    ];

    const [activeTab, setActiveTab] = useState(tabList[0].key);
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { role } = useSelector((state) => state.globalState);
    const { courses, count, next, previous, totalPages, error } = useSelector(
        (state) => state.getCoursesbySchoolId
    ); 
    const deleteCourseById = useSelector((state) => state.deleteCourseById);
    const globalState = useSelector((state) => state.globalState);
    // const token = "e8e92304ad0b1e4370334cb4940e912b76eda727";
    const school_id = globalState.school_id;

    const handleSearch = (e) => {
        setsearchOption(e.target.value)
    }

    const handleSortOptionChange = (newSortOption) => {
        setSortOption(newSortOption);
    };

    // const getCourseListRequest = () => {
    //     dispatch(getAllCourseList(token, activeTab, currentPage));
    // };

    const getCourseBySchoolIdRequest = () => {
        dispatch(getCourseBySchoolId(role, school_id, token, currentPage, searchOption, sortOption, activeTab));
    };

    useEffect(() => {
        getCourseBySchoolIdRequest();

        if (deleteCourseById?.message?.length) {
            toast.success(deleteCourseById?.message);
        }
    }, [activeTab, currentPage, searchOption, sortOption, deleteCourseById]);

    useEffect(() => {
        return () => {
            dispatch(resetCourseListBySchoolId());
        };
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [activeTab]);

    useEffect(() => {
        dispatch(addToHistory('/dashboard/courses'))
    }, [])

    const renderActiveTabContent = () => {
        const activeTabData = tabList.find((tab) => tab.key === activeTab);
        return activeTabData ? activeTabData.displayItem : null;
    };

    return (

        <div className="row">
            <div className="col-lg-12 col-md-12">
                <div className="search_header-container">
                    <SearchComponent
                        search={search}
                        searchPlaceholder="Search"
                        searchValue="search"
                        onchange={handleSearch}
                        name="searchOption"
                        value={searchOption}
                    />
                    <div className="filter-wrapper">
                        <div className='course-sortby-popup-wrapper'>
                            <div className="lessonviewprogress-filder-tab"
                                onClick={() => {
                                    setIsVisible(!isVisible)
                                }}
                            >
                                <span>
                                    {/* <img src={srot}/> */}
                                    <i class="fa-solid fa-arrow-down-wide-short"></i>
                                </span>
                                <p>Sort</p>
                                
                            </div>
                            {isVisible && <SortPopup
                                isVisible={isVisible}
                                handleApplyClick={() => setIsVisible(!isVisible)}
                                sortOption={sortOption}
                                onSortOptionChange={handleSortOptionChange}
                            />}
                        </div>
                    </div>
                </div>
                <div className="studentDashboardSidePannel Custom_Tabs_section">
                    {tabList.map((tab) => (
                        <button
                            key={tab.key}
                            //to={`/dashboard/${tab.route.toLowerCase()}`}
                            className={`tab-link ${activeTab === tab.key ? 'active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                setActiveTab(tab.key);
                            }}
                        >{tab.title}
                            {/* <Tab isActive={activeTab === tab.key}>
            {tab.key}
          </Tab> */}
                        </button>
                    ))}
                </div>
                <div className="studentDashboardMainBody">
                    <div className='created-my-courses-container'>
                        {renderActiveTabContent()}
                    </div>
                </div>
                {/* <CustomTabs tabs={tabList} /> */}
            </div>
        </div>
    )
}

export default AllCourses