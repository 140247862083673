import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DeleteUploadedFile {
    message: any;
    isLoading: boolean;
    error: string | null;
}

const initialState: DeleteUploadedFile = {
    message: null,
    isLoading: false,
    error: null,
};

const deleteUploadedFileSlice = createSlice({
    name: "deleteuploadedFile",
    initialState,
    reducers: {
        setdeleteUploadedFile: (state: DeleteUploadedFile, action: PayloadAction<DeleteUploadedFile>) => {
            state.message = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: DeleteUploadedFile, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: DeleteUploadedFile, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetdeleteUploadedFile: () => {
            return initialState;
        },
    },
});

export const {
    setdeleteUploadedFile,
    setloading,
    seterror,
    resetdeleteUploadedFile,
} = deleteUploadedFileSlice.actions;

export default deleteUploadedFileSlice.reducer;
