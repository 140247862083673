import React from 'react';
import "./TopRatingReview.css"
import LinearWithValueLabel from '../../../utils/LinearProgresslabel';

function TopRatingReview({topratingreview_title, 
    rating, rating_numbers, 
    profile_title, Reply, 
    des, 
    Edit_Reply, 
    Delete_Reply, 
    time, 
    date, 
    topratingreview_profile, 
    send_message_icon 
}) {

    return (
        <>  
            <div className='topratingreview-section'>
                <div className='topratingreview-wrapper'>
                    <div className='topratingreview-left-section'>
                        <p className='topratingreview-title'>{topratingreview_title}</p>
                        <h2>{rating}</h2>
                        <div className='topratingreview-stars'>
                            <span><i class="fa-solid fa-star"></i></span>
                            <span><i class="fa-solid fa-star"></i></span>
                            <span><i class="fa-solid fa-star"></i></span>
                            <span><i class="fa-solid fa-star"></i></span>
                            <span><i class="fa-solid fa-star"></i></span>
                        </div>
                        <div className='rating-numbers'>
                            <p>{(rating_numbers)}</p>
                        </div>
                        <div className='topratingreview-progress-bar-wrapper'>
                            <div className='topratingreview-progress-bar-folder'>
                                <p>5 Star</p><LinearWithValueLabel value={70} />
                            </div>
                            <div className='topratingreview-progress-bar-folder'>
                                <p>4 Star</p><LinearWithValueLabel value={60} />
                            </div>
                            <div className='topratingreview-progress-bar-folder'>
                                <p>3 Star</p><LinearWithValueLabel value={50} />
                            </div>
                            <div className='topratingreview-progress-bar-folder'>
                                <p>2 Star</p><LinearWithValueLabel value={30} />
                            </div>
                            <div className='topratingreview-progress-bar-folder'>
                                <p>1 Star</p><LinearWithValueLabel value={10} />
                            </div>
                        </div>
                    </div>
                    <div className='topratingreview-right-section'>
                        <h2>Top Reviews</h2>
                        <div className='topratingreview-card-wrapper'>
                            <div className='topratingreview-card-folder'>
                                <div className='topratingreview-card-inner-header-wrapper'>
                                    <div className='topratingreview-card-inner-profile-folder'>
                                        <div className='topratingreview-card-inner-profile'>
                                            <img src={topratingreview_profile}/>
                                        </div>
                                        <p className='topratingreview-card-profile-title'>{profile_title}</p>
                                    </div>
                                    <div className='topratingreview_btn-container'>
                                        <button className='topratingreview-reply-btn'><i class="fa-solid fa-reply"></i> {Reply}</button>
                                        <span><i class="fa-solid fa-trash"></i></span>
                                    </div>
                                </div>
                                <div className='topratingreview-stars'>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                </div>
                                <div className='topratingreview-card-des-folder'>
                                    <p>{des}</p>
                                </div>
                                <div className='topratingreview-card-massege-wrapper'>
                                    <div className='topratingreview-card-inner-profile'>
                                        <img src={topratingreview_profile}/>
                                    </div>
                                    <textarea className='send-message' rows="2" cols="2" placeholder='Thank you for your Feedback! Charles Shelby |'/>
                                    <div className='send-message-icon'>
                                        <img src={send_message_icon}/>
                                    </div>
                                </div>
                                <div className='topratingreview-card-massege-edit-folder'>
                                    <button>{Edit_Reply}</button>
                                    <button>{Delete_Reply}</button>
                                </div>
                                <div className='topratingreview-card-date-folder'>
                                    <span className='time'>{time},</span>
                                    <span className='date'>{date}</span>
                                </div>
                            </div>
                            <div className='topratingreview-card-folder'>
                                <div className='topratingreview-card-inner-header-wrapper'>
                                    <div className='topratingreview-card-inner-profile-folder'>
                                        <div className='topratingreview-card-inner-profile'>
                                            <img src={topratingreview_profile}/>
                                        </div>
                                        <p className='topratingreview-card-profile-title'>{profile_title}</p>
                                    </div>
                                    <div className='topratingreview_btn-container'>
                                        <button className='topratingreview-reply-btn'><i class="fa-solid fa-reply"></i> {Reply}</button>
                                        <span><i class="fa-solid fa-trash"></i></span>
                                    </div>
                                </div>
                                <div className='topratingreview-stars'>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                </div>
                                <div className='topratingreview-card-des-folder'>
                                    <p>{des}</p>
                                </div>
                                <div className='topratingreview-card-massege-wrapper'>
                                    <div className='topratingreview-card-inner-profile'>
                                        <img src={topratingreview_profile}/>
                                    </div>
                                    <textarea className='send-message' rows="2" cols="2" placeholder='Thank you for your Feedback! Charles Shelby |'/>
                                    <div className='send-message-icon'>
                                        <img src={send_message_icon}/>
                                    </div>
                                </div>
                                <div className='topratingreview-card-massege-edit-folder'>
                                    <button>{Edit_Reply}</button>
                                    <button>{Delete_Reply}</button>
                                </div>
                                <div className='topratingreview-card-date-folder'>
                                    <span className='time'>{time},</span>
                                    <span className='date'>{date}</span>
                                </div>
                            </div>
                            <div className='topratingreview-card-folder'>
                                <div className='topratingreview-card-inner-header-wrapper'>
                                    <div className='topratingreview-card-inner-profile-folder'>
                                        <div className='topratingreview-card-inner-profile'>
                                            <img src={topratingreview_profile}/>
                                        </div>
                                        <p className='topratingreview-card-profile-title'>{profile_title}</p>
                                    </div>
                                    <div className='topratingreview_btn-container'>
                                        <button className='topratingreview-reply-btn'><i class="fa-solid fa-reply"></i> {Reply}</button>
                                        <span><i class="fa-solid fa-trash"></i></span>
                                    </div>
                                </div>
                                <div className='topratingreview-stars'>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                </div>
                                <div className='topratingreview-card-des-folder'>
                                    <p>{des}</p>
                                </div>
                                <div className='topratingreview-card-massege-wrapper'>
                                    <div className='topratingreview-card-inner-profile'>
                                        <img src={topratingreview_profile}/>
                                    </div>
                                    <textarea className='send-message' rows="2" cols="2" placeholder='Thank you for your Feedback! Charles Shelby |'/>
                                    <div className='send-message-icon'>
                                        <img src={send_message_icon}/>
                                    </div>
                                </div>
                                <div className='topratingreview-card-massege-edit-folder'>
                                    <button>{Edit_Reply}</button>
                                    <button>{Delete_Reply}</button>
                                </div>
                                <div className='topratingreview-card-date-folder'>
                                    <span className='time'>{time},</span>
                                    <span className='date'>{date}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default TopRatingReview;