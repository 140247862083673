import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GoogleDriveCourseSync {
    message: any;
    isLoading: boolean;
    error: string | null;
}

const initialState: GoogleDriveCourseSync = {
    message: null,
    isLoading: false,
    error: null,
};

const googleDriveCourseSyncSlice = createSlice({
    name: "googleDriveCourseSync",
    initialState,
    reducers: {
        setgoogleDriveCourseSync: (state: GoogleDriveCourseSync, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: GoogleDriveCourseSync, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: GoogleDriveCourseSync, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetgoogleDriveCourseSync: () => {
            return initialState;
        },
    },
});

export const {
    setgoogleDriveCourseSync,
    setloading,
    seterror,
    resetgoogleDriveCourseSync,
} = googleDriveCourseSyncSlice.actions;

export default googleDriveCourseSyncSlice.reducer;
