import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GetFileStatus {
    fileStatus: any,
    uploadProgress: string | null,
    isLoading: boolean;
    error: string | null;
}

const initialState: GetFileStatus = {
    fileStatus: {},
    uploadProgress: null,
    isLoading: false,
    error: null,
};

const getFileStatusSlice = createSlice({
    name: "getFileStatus",
    initialState,
    reducers: {
        getfilestatus: (state: GetFileStatus, action: PayloadAction<GetFileStatus>) => {
            state.fileStatus = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setFileUploadProgress: (state: GetFileStatus, action: PayloadAction<string>) => {
            state.uploadProgress = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: GetFileStatus, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: GetFileStatus, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetgetfilestatus: () => {
            return initialState;
        },
    },
});

export const {
    getfilestatus,
    setFileUploadProgress,
    setloading,
    seterror,
    resetgetfilestatus,
} = getFileStatusSlice.actions;

export default getFileStatusSlice.reducer;
