import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RemoveStudents {
    message: any;
    isLoading: boolean;
    error: string | null;
}

const initialState: RemoveStudents = {
    message: null,
    isLoading: false,
    error: null,
};

const removeStudentsFromCourseSlice = createSlice({
    name: "removeStudentsFromCourse",
    initialState,
    reducers: {
        setremovestudents: (state: RemoveStudents, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: RemoveStudents, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: RemoveStudents, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetremovestudents: () => {
            return initialState;
        },
    },
});

export const {
    setremovestudents,
    setloading,
    seterror,
    resetremovestudents,
} = removeStudentsFromCourseSlice.actions;

export default removeStudentsFromCourseSlice.reducer;
