import React from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';  // Update the import path as necessary

function ErrorBoundaryWithNavigation(props) {
    const navigate = useNavigate();

    return <ErrorBoundary {...props} navigate={navigate} />;
}

export default ErrorBoundaryWithNavigation;
