import {
  Navigate
} from "react-router-dom";
import { useAuth } from "../features/auth/AuthContext";

function CheckRoute({ component: Component }) {
  const { token } = useAuth();

  if (token) {
    return <Navigate to="/dashboard/home" replace />;
  } else {
    return <Component />;
  }
}

export default CheckRoute;