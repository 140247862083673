import React, { useState } from 'react';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';

// Tab component
const Tab = ({ isActive, onClick, children }) => {
  return (
    {children}
    // <button
    //   className={isActive ? 'active' : ''}
    //   onClick={onClick}
    // >
    //   {children}
    // </button>
  );
};

// Tabs container component
const CustomTabs = ({ tabs, url }) => {

  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const lastPathPart = pathParts[pathParts.length - 1];

  let selectedTab = lastPathPart.length ? tabs.find((tab) => tab.route === lastPathPart)?.key : tabs[0].key;

  const [activeTab, setActiveTab] = useState(selectedTab);


  const renderActiveTabContent = () => {
    const activeTabData = tabs.find((tab) => tab.key === activeTab);
    // console.log(activeTabData)
    return activeTabData ? activeTabData.displayItem : null;
  };

  return (
    <>
    <div className="studentDashboardSidePannel Custom_Tabs_section">
      {tabs.map((tab) => (
        <Link
          key={tab.key}
          to={`${url}/${tab.route}`}
          className={`tab-link ${selectedTab === tab.key ? 'active' : ''}`}
          onClick={(e) => {
            // e.preventDefault(); // Prevent the default link behavior
            setActiveTab(tab.key);
          }}
        >{tab.title}
          {/* <Tab isActive={activeTab === tab.key}>
            {tab.key}
          </Tab> */}
        </Link>
      ))}
      </div>
      <div className='created-my-courses-container'>
        <Routes>
          <Route path={`/`} element={<Navigate to={`${url}/profile`} />} />
          {tabs.map((tab) => (
            <Route
              key={tab.key}
              path={tab.route}
              element={tab.displayItem}
            />
          ))}
        </Routes>
        {/* {renderActiveTabContent()} */}
      </div>
      {/* <div className="studentDashboardMainBody">
      </div> */}
      
      
      </>
  );
};

export default CustomTabs;