import React, { useEffect } from "react";
import { aboutus_logo , aboutus_bg_image, aboutus_bg_image2, aboutus_bg_image1, aboutus_bg_image3,  aboutus_teacher_logo, aboutus_teacher_logo2,aboutus_teacher_logo3, aboutus_list_icon, aboutus_book_image, aboutus_our_Vision_image, aboutus_Educational_Revolution_image, aboutus_Educational_Revolution_image_bg, aboutus_Educational_Revolution_image_bg2, aboutus_our_Vision_image2} from "../../assets/images/images";
import HomeNavbar from '../home/components/HomeNavbar';
import '../about/aboutus.css';
import {NavLink, NavLink as RouterNavLink, useNavigate } from "react-router-dom"; // Renamed to RouterNavLink
import AOS from "aos";
import "aos/dist/aos.css";
import 'animate.css';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const About = () => {

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
    
        // Navigate to the home page
        navigate("/");
    
        // Use setTimeout to delay the scroll to the specific section
        setTimeout(() => {
        const element = document.getElementById('revolution_sec_new');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        }, 100); // Adjust the timeout as needed
    };

    AOS.init({
        duration: 1200,
    });

    useEffect(() => {
        {
            const wow = new WOW.WOW();
            wow.init();
        }
    }, []);

    return (
        <>
            <HomeNavbar />
            
            <section className='aboutUs-section'>
                <div className='aboutUs-wrapper'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <div className='aboutUs-conatiner'>
                                    <div className='aboutUs-left-section'>
                                        <div className='aboutUs-left-section-image wow fadeInLeftBig' data-wow-duration="1.2s">
                                            <img src={aboutus_logo} alt='aboutUs' />
                                        </div>
                                        <h2 className="wow fadeInLeftBig" data-wow-duration="1.8s">SRDS.ai: Empowering Education with AI</h2>
                                        <p className="wow fadeInLeftBig" data-wow-duration="2s">SRDS.ai, a leading AI Learning Management System, is at the forefront of educational innovation. 
                                            Developed specifically for Saddle River Day School, our platform integrates cutting-edge AI technology 
                                            with traditional learning methods to create a personalized, efficient, and engaging learning environment. 
                                            Our commitment is to provide an educational experience that is not only informative but also transformative, 
                                            adapting to the unique needs of each student and educator.
                                        </p>
                                    </div>
                                    <div className='aboutUs-right-section'>
                                        <div className='aboutUs-right-section-image wow animate__zoomIn' data-wow-duration="1.2s">
                                            <img src={aboutus_bg_image} alt='aboutUs background' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>

            <section className='aboutUs-for-teacher-section'>
                <div className='aboutUs-for-teacher-wrapper'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <div className='aboutUs-logo-image-folder'>
                                    <div className='aboutUs-aboutus-teacher-logo-image'>
                                        <img src={aboutus_teacher_logo} alt='aboutUs-aboutus-teacher-logo-image'/>
                                    </div>
                                </div>
                                <div className='aboutUs-for-teacher-conatiner'>
                                    <div className='aboutUs-for-teacher-right-section'>
                                        <div className='aboutUs-for-teacher-right-section-image wow animate__zoomIn' data-wow-duration="1.2s">
                                            <img src={aboutus_bg_image1} alt='aboutUs background' />
                                        </div>
                                    </div>
                                    <div className='aboutUs-for-teacher-left-section'>
                                        <div className='aboutUs-for-teacher-list-container'>
                                            <div className='aboutUs-for-teacher-list-list-folder wow animate__fadeInRight'  data-wow-duration="1.2s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>Streamlined Course Management:</p>
                                            </div>
                                            <div className='aboutUs-for-teacher-list-list-folder wow animate__fadeInRight' data-wow-duration="1.5s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>Simplify administrative tasks and focus on impactful teaching.</p>
                                            </div>
                                            <div className='aboutUs-for-teacher-list-list-folder wow animate__fadeInRight' data-wow-duration="2s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>AI-Enhanced Tools: Automate grading and gain valuable insights for tailored teaching strategies.</p>
                                            </div>
                                            <div className='aboutUs-for-teacher-list-list-folder wow animate__fadeInRight' data-wow-duration="2.5s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>Real-Time Student Tracking: Adapt teaching methods with actionable analytics for improved outcomes.</p>
                                            </div>
                                            <div className='aboutUs-for-teacher-book-image'>
                                                <img src={aboutus_book_image} alt='aboutUs background' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>

            <section className='aboutUs-for-student-section'>
                <div className='aboutUs-for-student-wrapper'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <div className='aboutUs-logo-image-folder'>
                                    <div className='aboutUs-aboutus-teacher-logo-image wow animate__zoomIn' data-wow-duration="1.2s">
                                        <img src={aboutus_teacher_logo2} alt='aboutUs-aboutus-student-logo-image'/>
                                    </div>
                                </div>
                                <div className='aboutUs-for-student-conatiner'>
                                    <div className='aboutUs-for-student-left-section'>
                                        <div className='aboutUs-for-student-list-container'>
                                            <div className='aboutUs-for-student-list-list-folder wow animate__fadeInLeft' data-wow-duration="1.2s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>Personalized Learning Paths:</p>
                                            </div>
                                            <div className='aboutUs-for-student-list-list-folder wow animate__fadeInLeft' data-wow-duration="1.5s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>Experience learning tailored to individual styles and needs.</p>
                                            </div>
                                            <div className='aboutUs-for-student-list-list-folder wow animate__fadeInLeft' data-wow-duration="2s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>Interactive Content: Engage with dynamic, participative courses for deeper understanding.</p>
                                            </div>
                                            <div className='aboutUs-for-student-list-list-folder wow animate__fadeInLeft' data-wow-duration="2.5s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>Future-Ready Skills: Develop critical thinking and problem-solving abilities for real-world challenges.</p>
                                            </div>
                                            <div className='aboutUs-for-student-book-image'>
                                                <img src={aboutus_book_image} alt='aboutUs background' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='aboutUs-for-teacher-right-section'>
                                        <div className='aboutUs-for-teacher-right-section-image'>
                                            <img src={aboutus_bg_image2} alt='aboutUs background' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>

            <section className='aboutUs-for-teacher-section'>
                <div className='aboutUs-for-teacher-wrapper'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <div className='aboutUs-logo-image-folder'>
                                    <div className='aboutUs-aboutus-teacher-logo-image'>
                                        <img src={aboutus_teacher_logo3} alt='aboutUs-aboutus-student-logo-image'/>
                                    </div>
                                </div>
                                <div className='aboutUs-for-parent-conatiner'>
                                    <div className='aboutUs-for-teacher-right-section'>
                                        <div className='aboutUs-for-teacher-right-section-image wow animate__zoomIn' data-wow-duration="1.2s">
                                            <img src={aboutus_bg_image3} alt='aboutUs background' />
                                        </div>
                                    </div>
                                    <div className='aboutUs-for-parent-left-section'>
                                        <div className='aboutUs-for-parent-list-container'>
                                            <div className='aboutUs-for-parent-list-list-folder wow animate__fadeInRight' data-wow-duration="1.2s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>Insight into Progress:</p>
                                            </div>
                                            <div className='aboutUs-for-parent-list-list-folder wow animate__fadeInRight' data-wow-duration="1.2s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>Get a clear view of your child’s academic journey and achievements.</p>
                                            </div>
                                            <div className='aboutUs-for-parent-list-list-folder wow animate__fadeInRight' data-wow-duration="1.2s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>Enhanced Engagement: Stay involved in your child's education with regular updates and AI-powered insights.</p>
                                            </div>
                                            <div className='aboutUs-for-parent-list-list-folder wow animate__fadeInRight' data-wow-duration="1.2s">
                                                <img src={aboutus_list_icon} alt="aboutus_list_icon"/>
                                                <p>Support Child’s Growth: Be equipped to support your child’s learning and development effectively.</p>
                                            </div>
                                            <div className='aboutUs-for-parent-book-image'>
                                                <img src={aboutus_book_image} alt='aboutUs background' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>

            <section>
                <div className='aboutUs-our-vision-section'>
                    <div className='aboutUs-our-vision-wrapper'>
                        <div className='aboutUs-our-vision-container'>
                            <div className='aboutUs-our-vision-folder wow animate__fadeInUpBig' data-wow-duration="1.2s">
                                <h2>Our Vision</h2>
                                <p>We are more than just an LMS; we are a catalyst for a comprehensive educational transformation. 
                                    Pro School AI brings a multidimensional approach to education, contrasting with traditional methods. 
                                    Our platform ensures a holistic learning experience, preparing the next generation for a rapidly evolving future. 
                                </p>
                                <div className='aboutus_our_Vision_image'>
                                    <img src={aboutus_our_Vision_image} alt='aboutUs background' />
                                </div>
                            </div>
                            <div className='aboutUs-our-vision-folder aboutUs-our-vision-folder2 wow animate__fadeInDownBig' data-wow-duration="1.2s">
                                <h2>Our Commitment</h2>
                                <p>Our commitment is to an educational experience that is innovative and enriching for teachers, 
                                    students, and parents alike. By continuously advancing our platform, we ensure Pro School AI 
                                    remains a leader in educational innovation. 
                                </p>
                                <div className='aboutus_our_Vision_image2'>
                                    <img src={aboutus_our_Vision_image2} alt='aboutUs background' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Educational-Revolution-section'>
                <div className='Educational-Revolution-wrapper'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <div className='Educational-Revolution-conatiner'>
                                    <h2 className="wow fadeInLeft" data-wow-duration="1.2s">Join Our Educational Revolution</h2>
                                    <div className='Educational-Revolution-inner-conatiner wow animate__zoomIn' data-wow-duration="1.2s">
                                        <p>Embrace a future where education goes beyond learning; it’s about thriving in a dynamic world. Pro School AI is your partner in this transformative journey. 
                                           Let's shape the future of education together.
                                        </p>
                                        <div className='aboutus_Educational-Revolution_image'>
                                            <img src={aboutus_Educational_Revolution_image} alt='aboutUs background' />
                                        </div>
                                    </div>
                                    <div className='aboutus_Educational-Revolution_image-bg wow fadeInLeft' data-wow-duration="1.2s">
                                        <img src={aboutus_Educational_Revolution_image_bg} alt='aboutUs background' />
                                    </div>
                                    <div className='aboutus_Educational-Revolution_image-bg2 wow animate__fadeInRight' data-wow-duration="1.2s">
                                        <img src={aboutus_Educational_Revolution_image_bg2} alt='aboutUs background' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>

            <div className='footer-section' id="footer-section">
                <div className='footer-wraper'>
                    <div className='footer-link-folder'>
                        <p onClick={() => {
                navigate("/");
              }}>Saddle River Day School</p>
                        {/* <div className='footer-Join-our-newsletter-folder'>
                            <p>Join our newsletter</p>
                            <div className='footer-Join-our-newsletter-search-folder'>
                                <input type="email" placeholder="Your email"></input>
                                <button>Subscribe</button>
                            </div>
                        </div> */}
                    </div>
                    <div className="footer-Quick-Links-folder">
                        <h2>Quick Links</h2>
                        <ul className="footer-Quick-Links">
                            <li>
                                <a href="revolution_sec_new" onClick={handleClick}>Features</a>
                            </li>
                            {/* <li><NavLink to="/Services">Services</NavLink></li> */}
                            {/* <li><NavLink to="/Contact">Contact Us</NavLink></li> */}
                            <li>
                                <RouterNavLink to="/about">About Us</RouterNavLink>
                            </li>
                            <li>
                                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                            </li>
                            {/* <li><NavLink to="/Help">Help</NavLink></li>
                            <li><NavLink to="/Privacy">Privacy</NavLink></li> */}
                        </ul>
                    </div>
                    <div className="footer-Quick-Links-folder">
                        <h2>Contact</h2>
                        <div className="footer-contact">
                            <div className="footer-contact-folder">
                                <a><i class="fa-solid fa-phone"></i></a>
                                <span><a href="tel:+1 201-327-4050">+1 201-327-4050</a></span>
                            </div>
                            <div className="footer-contact-folder">
                                <a><i class="fa-solid fa-envelope"></i></a>
                                <a href = "mailto: noreply@saddleriverday.org">kmerges@saddleriverday.org</a>
                            </div>
                            <div className="footer-contact-folder">
                                <a><i class="fa-solid fa-location-dot"></i></a>
                                <a href="https://www.google.com/maps/place/Saddle+River+Day+School/@41.0307394,-74.0880125,17z/data=!3m1!4b1!4m6!3m5!1s0x89c2e436c9e7ef3b:0xf060c34a65b01898!8m2!3d41.0307394!4d-74.0854376!16zL20vMGRkYzZ5?entry=ttu" target="_blank">Saddle River Day School, 147 Chestnut Ridge Road, Saddle River, NJ 07458​​.</a>
                            </div>
                        </div>
                    </div>
                    <div className='footer-get-in-touch-folder'>
                        <span>Get in touch</span>
                        <div className='footer-social-link-folder'>
                            <a href='https://www.facebook.com/saddleriverdayschool' target="_blank"><i className="fa-brands fa-facebook"></i></a>
                            <a href='https://twitter.com/srDSrebels' target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
                            <a href='https://www.instagram.com/saddleriverday/' target="_blank"><i className="fa-brands fa-instagram"></i></a>
                            <a href='https://vimeo.com/srds' target="_blank"><i class="fa-brands fa-vimeo-v"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default About;