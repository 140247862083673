import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setFileIdByName,
    setloading,
    seterror,
    resetFileIdByName,
} from "../reducers/getFileIdByNameReducer";

const getFileIdByName = (data: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.post(`/ai/api/get-file-id-by-name/`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });

        if (result) {
            dispatch(setloading(false));
            dispatch(setFileIdByName(result.data.files));
        }
    } catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
};

export { getFileIdByName, resetFileIdByName };

