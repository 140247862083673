import React from "react";

const StudentAssignmentScore = ({
  showScore,
  getSubmissiondtls,
  onclick,
  title,
  isCurrentAssignment,
  onclick2,
  title2,
}) => {
  return (
    <div className="AssingmentMCQs-Congratulations-popup-wrapper">
      <div className="AssingmentMCQs-Congratulations-popup-container">
        {!isCurrentAssignment ? (
          <i
            class="fa-solid fa-x"
            id="AssingmentMCQs-close-btn"
            onClick={showScore}
          ></i>
        ) : (
          <></>
        )}
        <span className="your-score-title">Your score</span>
        <div className="your-score-folder">
          <span className="your-score-check">
            <i class="fa-solid fa-circle-check"></i>
          </span>
          <div className="your-score">
            <span>{getSubmissiondtls?.marks_obtained ?? 0}</span>
            <span>/{getSubmissiondtls?.total_marks ?? 0}</span>
          </div>
        </div>
        {/* <p>{getSubmissiondtls?.feedback ?? 0}</p> */}
        <p>{getSubmissiondtls?.feedback?.replace(/(\\\()(.*?)(\\\))/g, (match, p1, p2, p3) => `$$${p1}${p2}${p3}$$`) ?? 0}</p>
        <button
          onClick={onclick}
          type="button"
          className="View-Course"
        >
          {title}
        </button>
        {isCurrentAssignment && <span style={{ margin: "0px 5px" }}><button
          onClick={onclick2}
          //onClick={() => navigate(-2)}
          type="button"
          className="View-Course"
        >
          {title2}
        </button> </span>}
      </div>
    </div>
  );
};

export default StudentAssignmentScore;


// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import { Average_Animation_gift, heigh_Animation_gift, low_Animation_gift } from "../../../../../../assets/images/images";
// import PracticeAssignmentPopup from "./PracticeAssignmentPopup/PracticeAssignmentPopup";
// import { Tooltip } from "react-tooltip";


// const StudentAssignmentScore = ({
//   showScore,
//   getSubmissiondtls,
//   onclick,
//   title,
//   isCurrentAssignment,
//   onclick2,
//   title2,
//   title3,
//   assignment
// }) => {

//   const [showPopup, setShowPopup] = useState(false);
//   const globalState = useSelector((state) => state.globalState);

//   const handlePracticeAssignmentClick = () => {
//     setShowPopup(true);
//   };

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   const calculatePercentage = (marksObtained, totalMarks) => {
//     if (totalMarks === 0 || totalMarks === null || marksObtained === null) {
//       return 0;
//     }
//     return ((marksObtained / totalMarks) * 100).toFixed(2);
//   };

//   return (
//     <div className="AssingmentMCQs-Congratulations-popup-wrapper">
//       <div className="AssingmentMCQs-Congratulations-popup-container">
//         <div className="AssingmentMCQs-Congratulations-popup-image">
//           {calculatePercentage(getSubmissiondtls?.marks_obtained, getSubmissiondtls?.total_marks) > 79.99 ? (
//             <img src={heigh_Animation_gift} alt="Description of GIF" />
//           ) : calculatePercentage(getSubmissiondtls?.marks_obtained, getSubmissiondtls?.total_marks) > 59.99 ?
//             (<img src={Average_Animation_gift} alt="Description of GIF" />) : (<img src={low_Animation_gift} alt="Description of GIF" />)}
//         </div>
//         <span className="your-score-title">Your score</span>
//         <div className="your-score-folder">
//           <span className="your-score-check">
//             <i class="fa-solid fa-circle-check"></i>
//           </span>
//           <div className="your-score">
//             <span>{getSubmissiondtls?.marks_obtained ?? 0}</span>
//             <span>/{getSubmissiondtls?.total_marks ?? 0}</span>
//           </div>
//         </div>
//         <div className="Congratulations-assignment-btns">
//           <button onClick={onclick} type="button" className="View-Course">
//             {title}
//           </button>
//           {isCurrentAssignment && (
//             <button onClick={onclick2} type="button" className="view-result">
//               {title2}
//             </button>
//           )}
//           <div className="practice-assignment">

//             <button
//               onClick={handlePracticeAssignmentClick}
//               type="button"
//               className="practice-assignment-btn"
//             >
//               {title3}
//             </button>
//             <Tooltip
//               id="show-message"
//               place="bottom"
//               content="Upgrade Subscription Plan"
//               type="light"
//               effect="float"
//             />
//           </div>
//         </div>
//       </div>
//       {showPopup && <PracticeAssignmentPopup assignment={assignment} onClose={handleClosePopup} />}
//     </div>
//   );
// };

// export default StudentAssignmentScore;