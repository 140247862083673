import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Ring } from "@uiball/loaders";
import IconButton from "@mui/material/IconButton";
import { search } from "../../../../assets/images/images";
import { useAuth } from "../../../../features/auth/AuthContext";
import { toast } from "react-toastify";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  isRequired,
  isValidEmail,
  isValidPassword,
} from "../../../../utils/validator";
import { profileLogin, resetProfileLogin } from "../../../../state/actions/profileLoginAction";
import {
  superAdminLogin,
  resetsuperAdminLogin,
} from "../../../../state/actions/superadminLoginAction";
// import SearchBar from "../SearchBar/SearchBar";

function SuperAdminLogin() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("SuperAdmin");
  const { login } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectPath = localStorage.getItem("redirectAfterSignup");
  const profilelogin = useSelector((state) => state.profilelogin);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    // Check if Enter key is pressed and ignore tab switch
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior (e.g., form submission)
      handleSubmit(e); // Call the submit function
      return;
    }
    const { name, value } = e.target;
    let error = "";
    if (name === "email") {
      error = isRequired(value, "email") || isValidEmail(value);
    } else if (name === "password") {
      error = isRequired(value, "password");
      //   || isValidPassword(value);
    }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};

    errors.email =
      isRequired(formData.email, "email") || isValidEmail(formData.email);
    errors.password =
      isRequired(formData.password, "password") ||
      //isValidPassword(formData.password);

      setFormErrors(errors);

    if (!Object.values(errors).some((error) => error)) {
      await superadminloginFunc(
        formData.email.toLocaleLowerCase(),
        formData.password
      );
    }
  };

  const superadminloginFunc = () => {
    const req = {
      email: formData.email,
      password: formData.password,
    };

    dispatch(profileLogin("superadmin",req));
  };

  useEffect(() => {
    const failedErrorMessage =
      "Oops, something went wrong. Please try again later.";
    if (profilelogin?.token?.length) {
      login(profilelogin?.token, activeTab, formData.email || "");
      toast.success("Login successful.", {
        position: toast.POSITION.TOP_CENTER,
      });
      if (redirectPath !== null && redirectPath !== "") {
        navigate(redirectPath);
        localStorage.removeItem("redirectAfterSignup");
        return;
      }
    } else if (profilelogin.error?.length) {
      //const errorMessage = formatApiErrors(profilelogin?.error);
      toast.error(profilelogin?.error || failedErrorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(resetProfileLogin());
    }
  }, [profilelogin]);

  useEffect(() => {
    return () => {
      dispatch(resetProfileLogin());
    };
  }, []);

  return (
    <>
      <div className="superadmin-login-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="superadmin-login-wrapper">
                <div className="superadmin-login-container">
                  <p>Welcome to</p>
                  <h2>ProTeacher.ai</h2>
                  <form
                    className="superadmin-login-form"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <span>Login</span>
                    <div className="signup-form">
                      <p className="email-or-phoneNumber-title">Email</p>
                      <div className="signup-input edit-email">
                        <FormControl
                          margin="normal"
                          fullWidth
                          error={!!formErrors.email}
                        >
                          <TextField
                            name="email"
                            size="small"
                            value={formData.email}
                            error={!!formErrors.email}
                            onChange={handleChange}
                            InputProps={{
                              sx: {
                                borderRadius: "35px",
                                backgroundColor: "#F5F5F5",
                                padding: "0px",
                                fontSize: "15px",
                              },
                            }}
                          />
                          <FormHelperText>{formErrors.email}</FormHelperText>
                        </FormControl>
                      </div>
                      <p className="email-or-phoneNumber-title">Password</p>
                      <div className="signup-input hide-show-password">
                        <FormControl
                          margin="normal"
                          fullWidth
                          error={!!formErrors.password}
                        >
                          <TextField
                            size="small"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={formData.password}
                            error={!!formErrors.password}
                            onChange={handleChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setShowPassword(
                                        (prevShowPassword) => !prevShowPassword
                                      )
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              sx: {
                                borderRadius: "35px",
                                backgroundColor: "#F5F5F5",
                              },
                            }}
                          />
                          <FormHelperText>{formErrors.email}</FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                    <div className="signup-btn">
                      {loading ? (
                        <Ring />
                      ) : (
                        <button type="submit" disabled={loading}>
                          Login
                        </button>
                      )}
                    </div>
                    {/* <div className="Already-have-an-accout-folder">
                                    <span>
                                        Don't have an account?
                                        <a href="#">Register</a>
                                    </span>
                                </div> */}
                  </form>
                </div>
                {/* <SearchBar
                            search= {search}
                            searchPlaceholder="Search"
                            searchValue="search"
                        /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuperAdminLogin;
