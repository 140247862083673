import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { avatar } from "../../../../assets/images/images";
import { useAuth } from "../../../../features/auth/AuthContext";
import { LetestTeacher, resetLetestTeacher } from "../../../../state/actions/teacherListAction";
import DashboardListTable from "./DashboardListTable";
import { CircularProgress, Grid } from "@mui/material";

const DashboardList = () => {
    const [activeTab, setActiveTab] = useState("Student"); // Default active tab is 'lessons'
    const handleTabClick = (newActiveTab) => {
        setActiveTab(newActiveTab);
        // getLetestTeacheretils();
    };
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { school_id } = useSelector((state) => state.globalState);
    const letestteacherlist = useSelector((state) => state.letestteacherlist);

    const getLetestTeacheretils = () => {
        dispatch(LetestTeacher(token, activeTab, school_id));
    };

    useEffect(() => {
        getLetestTeacheretils();
        return () => {
            dispatch(resetLetestTeacher());
        };
    }, [activeTab]);
    useEffect(() => {
        return () => {
            dispatch(resetLetestTeacher());
        };
    }, []);

    const [isLoading, setIsLoading] = useState(true); // Initialize as true for initial loading state

    return (
        <>
            <div className="Dashboard-List-Table">
                <div className="Dashboard-List-Table-wrapper">
                    <div className="view-lessons-section">
                        <div className="view-lessons-wrapper">
                            {
                                <div className="view-lessons-btn-container teacehr-tab-setion">

                                    <button
                                        className={`tab-button ${activeTab === "Teacher" ? "active" : ""
                                            }`}
                                        onClick={() => handleTabClick("Teacher")}
                                    >
                                        Recently Added Teachers
                                    </button>
                                    <button
                                        className={`tab-button ${activeTab === "Student" ? "active" : ""
                                            }`}
                                        onClick={() => handleTabClick("Student")}
                                    >
                                        Recently Added Students
                                    </button>
                                    {/* <button className="Dashboard-List-Table-view-all">View All</button> */}
                                </div>
                            }
                            <div className="tab-content">
                                {activeTab === "Student" && (
                                    <div className="Dashboard-List">
                                        <div className="Dashboard-List-wrapper">
                                            <table className="Dashboard-List-Recently-Added-table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Student ID</th>
                                                        <th>Email</th>
                                                        <th>Joining Date</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <>
                                                    {letestteacherlist.isLoading ? (
                                                        <div style={{ display: "table-caption" }}>
                                                            <Grid
                                                                height={"50vh"}
                                                                xs={12}
                                                                container
                                                                justifyContent={"center"}
                                                                alignItems={"center"}
                                                                textAlign={"center"}
                                                            >
                                                                <CircularProgress size={80} />
                                                            </Grid>
                                                        </div>

                                                    ) :
                                                        letestteacherlist.students.length > 0 ? (
                                                            <tbody>
                                                                {letestteacherlist.students.map((teacher, index) => (
                                                                    <DashboardListTable
                                                                        role="Student"
                                                                        key={index}
                                                                        tid={teacher.student_id}
                                                                        avatar={teacher.student_profile_pic}
                                                                        name={teacher.name}
                                                                        teacherId={teacher.uid}
                                                                        email={teacher.email}
                                                                        joiningDate={teacher.created_at}
                                                                    />
                                                                ))}
                                                            </tbody>

                                                        ) : (
                                                            <p style={{ textAlign: "center", marginTop: "10px", margin: "0px auto" }}>No Students Found</p>
                                                        )
                                                    }

                                                    {/* {letestteacherlist.students.map((teacher, index) => (
                                                    <>
                                                        <DashboardListTable
                                                            role="Student"
                                                            key={index}
                                                            tid={teacher.student_id}
                                                            avatar={teacher.student_profile_pic}
                                                            name={teacher.name}
                                                            teacherId={teacher.uid}
                                                            email={teacher.email}
                                                            joiningDate={teacher.created_at}
                                                        />
                                                    </>
                                                ))} */}
                                                </>
                                            </table>
                                        </div>
                                    </div>
                                )}

                                {activeTab === "Teacher" && (
                                    <div className="Dashboard-List">
                                        <div className="Dashboard-List-wrapper">
                                            <table className="Dashboard-List-Recently-Added-table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Teacher ID</th>
                                                        <th>Email</th>
                                                        <th>Joining Date</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                    {letestteacherlist.isLoading ? (
                                                        <div style={{ display: "table-caption" }}>
                                                            <Grid
                                                                height={"50vh"}
                                                                xs={12}
                                                                container
                                                                justifyContent={"center"}
                                                                alignItems={"center"}
                                                                textAlign={"center"}
                                                            >
                                                                <CircularProgress size={80} />
                                                            </Grid>
                                                        </div>
                                                    ) :
                                                        letestteacherlist.teachers.length > 0 ? (
                                                            <tbody>
                                                            {letestteacherlist.teachers.map((teacher, index) => (
                                                                <DashboardListTable
                                                                    role="Teacher"
                                                                    key={index}
                                                                    tid={teacher.teacher_id}
                                                                    avatar={teacher.ai_avatar}
                                                                    name={teacher.name}
                                                                    teacherId={teacher.uid}
                                                                    email={teacher.email}
                                                                    joiningDate={teacher.created_at}
                                                                />
                                                            ))}
                                                            </tbody>
                                                        ) : (
                                                            <p style={{ textAlign: "center", marginTop: "10px", margin: "0px auto" }}>No Teacher Found</p>
                                                        )
                                                    }
                                                    {/* {letestteacherlist.teachers.map((teacher, index) => (
                                                        <>
                                                            <DashboardListTable
                                                                role="Teacher"
                                                                key={index}
                                                                tid={teacher.teacher_id}
                                                                avatar={teacher.ai_avatar}
                                                                name={teacher.name}
                                                                teacherId={teacher.uid}
                                                                email={teacher.email}
                                                                joiningDate={teacher.created_at}
                                                            />
                                                        </>
                                                    ))} */}
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DashboardList;
