import "react-datepicker/dist/react-datepicker.css";
import "../../../styles/dashboard.css";

import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate,useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import api from "../../../../../api/api";
import chat_img from "../../../../../assets/images/chat-img-1.png";
import dayjs from "dayjs";
import {
  alert_image,
  course_demo_image,
  course_image,
  dashborad_Courses_image3,
  graduation,
  plus_rounded,
  Teacher_Dashboard_Default,
  dashborad_Courses_book_image,
  Asset_teacher,
  Asset_teacher2,
  teacher_reviews_profile,
  teacher_reviews_profile_aword,
  course_bg_image,
  course_info_image
} from "../../../../../assets/images/images";
import StyledMenu from "../../../../../components/StyleMenu";
import Welcome from "../../../../../components/popup/Welcome";
import { useAuth } from "../../../../../features/auth/AuthContext";
import { updateGlobalState } from "../../../../../state/global/slice";
import PopupSection from "../Dashboard_popup";
import CreateCourse from "./course/CreateCourse";
import Courses from "../../../../../modules/teacher/courses/Courses";
import AOS from "aos";
import "aos/dist/aos.css";
import Card from "./course/Card";
import imgs from "../../../../../assets/images/imgs";
import UniversalChatbot from "../../../../../components/universalChatbot/UniversalChatbot";
import Settings from "../../settings/Settings";
import { changeScrollPopup } from "../../../../../utils/utlis";
import Sidebar from "../../../../../components/Sidebar/Sidebar";
import DashboardheaderTab from "../../../../../components/DashboardTab";
import DashboardReviewCard from "../../../../../components/SuperAmin/Dashboard/DashboardReviewCard/DashboardReviewCard";
import CourseCard from "../../../../../components/Courses/CourseCard";
import TableRow from "../../../../../components/SuperAmin/Dashboard/StudentReviewTableData/StudentReviewData";
import Teachers from "../../../../../components/Teachers/Teachers";
import Students from "../../../../../components/Students/Students";
import AdminDashboard from "../../../../../modules/admin/Dashboard/AdminDashboard";
import SchoolList from "../../../../../modules/superadmin/SchoolList/SchoolList";
import Message from "../../../../../modules/teacher/message/Message";
import DashboardMain from "../../../../../modules/teacher/dashboard/DashboardMain";
import TeacherHome from "./Tab/TeacherHome";

const TeacherDashboard = ({ tabList, activeTab, setActiveTab }) => {
  const { logout, token } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const [courseLoading, setcourseLoading] = useState(false)
  const [getDashboardCount, setgetDashboardCount] = useState({});
  const [dashboardCountLoading, setdashboardCountLoading] = useState(false);
  const [fullteacherdetails, setfullteacherdetails] = useState({});
  const globalState = useSelector((state) => state.globalState);
  // const [coursePage, setCoursePage] = useState({
  //   page: 1,
  //   total: 1,
  // });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const updateCoursePage = (page) => {
    // setCoursePage({ ...coursePage, page });
    setCurrentPage(page);
  };
  const updateCoursePageTotal = (total) => {
    // setCoursePage({ ...coursePage, total });
    setTotalPages(total);
  };

  const handlePageChange = (event, value) => {
    updateCoursePage(value);
  };
  useEffect(() => {
    getPaginatedCourseList(currentPage);

    window.scrollTo(0, 0);
  }, [currentPage]);

  const getPaginatedCourseList = async (page) => {
    updateCoursePage(page);
    const teacher_id = globalState.teacher_details?.data?.teacher_id ?? "";
    const url = `/courses/get-all-courses/teacher/${teacher_id}/?page=${page}`;
    setcourseLoading(true);
    api
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (Number(response.status) === 200) {
          setcourseLoading(false)
          if (response?.data) {
            const totalItems =
              Number(JSON.parse(JSON.stringify(response?.data))?.count) ?? 0;
            const totalPages = Math.ceil(totalItems / 12);
            // updateState("total_course", totalItems);
            updateState(
              "course_list",
              JSON.parse(JSON.stringify(response?.data))?.data ?? []
            );

            updateCoursePageTotal(totalPages);
            const data = response.data;
            setNextUrl(data?.next); // Update next URL
            setPrevUrl(data?.previous); // Update previous URL
          }
        }
      })
      .catch((error) => { setcourseLoading(false)});
      // .catch((error) => { });
  };
  useEffect(() => {
    // https://web.mycro.news/auth/check-user/?email=vaibhava@webarcitech.com
    // console.log(globalState?.email);
    const url = `auth/check-user/?email=${encodeURIComponent(globalState?.email) || ""}`;
    const headers = {
      "Content-Type": "application/json",
    };    
    api
      .get(
        url,
        { headers }
      ).catch((error) => {
        if(error.response.data.status === 400){
            logout();
           navigate('/login', { replace: true });
        }
      });



    api
      .get("/auth/get-teacher/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //return 1;
          if (response?.data) {
            // localStorage.setItem('teacher_details', false);
            // localStorage.setItem('teacher_details', JSON.stringify(response?.data ?? ''));
            updateState("teacher_details", response?.data ?? {});
          }

          setfullteacherdetails(response.data);

          // setShowForm(false);
          // setafterSucces(true);
          const teacher_id = response.data?.data?.teacher_id;
          api
            .get(`/courses/get-all-courses/teacher/${teacher_id}/`, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
              },
            })
            .then((response) => {
              if (Number(response.status) === 200) {
                //return 1;
                if (response?.data) {
                  updateState(
                    "total_course",
                    Number(JSON.parse(JSON.stringify(response?.data))?.count) ??
                    0
                  );
                  updateState(
                    "course_list",
                    JSON.parse(JSON.stringify(response?.data))?.data ?? []
                  );
                  const totalItems =
                    Number(JSON.parse(JSON.stringify(response?.data))?.count) ??
                    0;
                  const totalPages = Math.ceil(totalItems / 12);
                  updateCoursePageTotal(totalPages);
                }
                setWelcomePopupStatus(false);
                // setCourseList(JSON.parse(JSON.stringify(response?.data))?.data ?? [])
                // localStorage.setItem('my_courses', JSON.stringify(response.data));
              }
            })
            .catch((error) => {
              //setWelcomePopupStatus(false);
            });
        } else {
          setWelcomePopupStatus(false);
          setProfilePopupStatus(true);
        }
      })
      .catch((error) => {
        // logout();
        // navigate('/login', { replace: true });
        setWelcomePopupStatus(false);
        setProfilePopupStatus(true);
      });
    api
      .get("/auth/get-datalist/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          updateState("datalist", response?.data ?? {});
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    api
      .get("/courses/get-grade-list/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //return 1;
          if (response?.data) {
            updateState("grade_details", response?.data ?? {});
          }
          // localStorage.setItem('grade_details', JSON.stringify(response?.data ?? ''));
        }
      })
      .catch((error) => { });

    setdashboardCountLoading(true)
    api
      .get("/auth/teacher-dashboard-count/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //return 1;
          if (response?.data) {
            setgetDashboardCount(response?.data);
            setdashboardCountLoading(false)
          }
          // localStorage.setItem('datalist', JSON.stringify(response?.data ?? ''));
        }
      })
      .catch((error) => { setdashboardCountLoading(false) });

    // api
    // .get("/auth/teacher/get-student-chat-list/", {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     Authorization: `Token ${token}`,
    //   },
    // })
    // .then((response) => {
    //   console.log(response);
    //   if (response.status === 200) {
    //     //return 1;

    //     if (response?.data) {

    //       setGetEnrollStudent(response?.data?.data);
    //     }
    //     // localStorage.setItem('datalist', JSON.stringify(response?.data ?? ''));
    //   }
    // })
    // .catch((error) => {});
  }, [globalState?.has_profile]);

  const profileName = fullteacherdetails?.data?.name;

  // const teacherHomeProps = {
  //   profileFirstName: profileName,
  //   handlePageChange: handlePageChange,
  //   totalPages: totalPages,
  //   currentPage: currentPage,
  //   nextUrl: nextUrl,
  //   prevUrl: prevUrl,
  //   showCourse: showCourse,
  //   setShowCourse: setShowCourse,
  //   toggleCourse: toggleCourse,
  //   getDashboardCount: getDashboardCount,
  //   courseLoading: courseLoading
  // };

  // const settingProps = {
  //   isDashboard: true,
  // }


  // const tabList = [
  //   {
  //     key: "Dashboard", route: "home", value: imgs.svg.dashboard_sidebar, props: teacherHomeProps, Component: TeacherHome
  //     // <TeacherHome
  //     //   profileFirstName={profileName}
  //     //   handlePageChange={handlePageChange}
  //     //   totalPages={totalPages}
  //     //   currentPage={currentPage}
  //     //   nextUrl={nextUrl}
  //     //   prevUrl={prevUrl}
  //     //   showCourse={showCourse}
  //     //   setShowCourse={setShowCourse}
  //     //   toggleCourse={toggleCourse}
  //     //   getDashboardCount={getDashboardCount}
  //     // />
  //   },
  //   { key: "Message", route: "messages", value: imgs.svg.sms_sidebar, Component: Message },
  //   {
  //     key: "Settings", route: "settings", value: imgs.svg.setting_sidebar, props: settingProps, Component: Settings
  //     // <Settings isDashboard={true} /> 
  //   },
  // ];
  // const tabList = [
  //   { key: "Dashboard", value: imgs.svg.dashboard_sidebar, role: "Teacher" },
  //   { key: "Message", value: imgs.svg.sms_sidebar, role: "Teacher" },
  //   { key: "Settings", value: imgs.svg.setting_sidebar, role: "Teacher" },
  //   { key: "Dashboard", value: imgs.svg.setting_sidebar, role: "SuperAdmin" },
  //   { key: "Teachers", value: imgs.svg.setting_sidebar, role: "SuperAdmin" },
  //   { key: "Schools", value: imgs.svg.setting_sidebar, role: "SuperAdmin" },
  //   { key: "Courses", value: imgs.svg.setting_sidebar, role: "SuperAdmin" },
  //   {
  //     key: "Subscription",
  //     value: imgs.svg.setting_sidebar,
  //     role: "SuperAdmin",
  //   },
  //   { key: "Ratings", value: imgs.svg.setting_sidebar, role: "SuperAdmin" },
  //   { key: "Settings", value: imgs.svg.setting_sidebar, role: "SuperAdmin" },
  //   { key: "Dashboard", value: imgs.svg.setting_sidebar, role: "Admin" },
  //   { key: "Teachers", value: imgs.svg.setting_sidebar, role: "Admin" },
  //   { key: "Students", value: imgs.svg.setting_sidebar, role: "Admin" },
  //   { key: "Courses", value: imgs.svg.setting_sidebar, role: "Admin" },
  //   { key: "Settings", value: imgs.svg.setting_sidebar, role: "Admin" },
  // ];
  const onProfileClick = () => {
    setActiveTab("Settings");
  };
  // const [activeTab, setActiveTab] = useState(actTab);

  const getActiveTab = () => {
    switch (activeTab) {
      case tabList[0].key:
        return (
          <DashboardMain
            profileFirstName={profileName}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
            currentPage={currentPage}
            nextUrl={nextUrl}
            prevUrl={prevUrl}
            showCourse={showCourse}
            setShowCourse={setShowCourse}
            toggleCourse={toggleCourse}
            getDashboardCount={getDashboardCount}
            setgetDashboardCount={setgetDashboardCount}
          />
        );
      case tabList[1].key:
        return <Message />;
      case tabList[2].key:
        return <Settings isDashboard={true} />;
      default:
        return <DashboardMain />;
    }
  };
  const [welcomePopupStatus, setWelcomePopupStatus] = useState(false);
  const [profilePopupStatus, setProfilePopupStatus] = useState(false);
  const [showCourse, setShowCourse] = useState(false);

  const toggleCourse = (overflow) => {
    setShowCourse(!showCourse);
    //document.body.style.overflow = overflow;
  };

  useEffect(() => {
    if (welcomePopupStatus === true) {
      changeScrollPopup("hidden", "welcome-polly-popup-wrapper");
    } else {
      changeScrollPopup("auto", "welcome-polly-popup-wrapper");
    }
  }, [welcomePopupStatus]);
  return (
    <>
      {welcomePopupStatus === true && (
        <Welcome setWelcomePopupStatus={setWelcomePopupStatus} />
      )}
      {profilePopupStatus === true && (
        <PopupSection
          profilePopupStatus={profilePopupStatus}
          welcomePopupStatus={welcomePopupStatus}
          setWelcomePopupStatus={setWelcomePopupStatus}
          setProfilePopupStatus={setProfilePopupStatus}
        />
      )}
      <UniversalChatbot />

      <TeacherHome
        profileFirstName={profileName}
        handlePageChange={handlePageChange}
        totalPages={totalPages}
        currentPage={currentPage}
        nextUrl={nextUrl}
        prevUrl={prevUrl}
        showCourse={showCourse}
        setShowCourse={setShowCourse}
        toggleCourse={toggleCourse}
        getDashboardCount={getDashboardCount}
        setgetDashboardCount={setgetDashboardCount}
        dashboardCountLoading={dashboardCountLoading}
        setdashboardCountLoading={setdashboardCountLoading}
      />

      {/* <div className="dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="dashboardBody"> */}
      {/* <Sidebar
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  tabList={tabList}
                  toggleCourse={toggleCourse}
                /> */}
      {/* <div className="dashboardSidePannel">
                  {activeTab === tabList[0].key ? (
                    <div
                      className="create-course"
                      onClick={() => toggleCourse("hidden")}
                    >
                      <ReactSVG src={plus_rounded} />
                      <p className="create-course-text">Create Course</p>
                    </div>
                  ) : null}

                  {tabList.map((tab, index) => {
                    return (
                      <button
                        key={index}
                        onClick={() => setActiveTab(tab.key)}
                        className={activeTab === tab.key ? "active" : ""}
                      >
                        <ReactSVG
                          src={tab.value}
                          className={
                            activeTab === tab.key
                              ? "svg-active"
                              : "svg-inactive"
                          }
                        />
                        {tab.key}
                      </button>
                    );
                  })}
                </div> */}
      {/* {getActiveTab()} */}
      {/* <div className="dashboardMainBody"></div> */}
      {/* </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default TeacherDashboard;