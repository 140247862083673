import React from 'react';
import { course_demo_image, marketplace_Course_image } from "../../assets/images/images"; 
import { useNavigate } from 'react-router-dom';
import BackButton from '../BackButton/BackButton';
import MarketPlaceCourseCard from '../MarketPlaceCourse/MarketPlaceCourseCard';
import "./MarketPlaceShoppingCart.css"

function MarketPlaceShoppingCart() {
    const navigate= useNavigate();

    return (
        <>  
           <div className='marketplace-shopping-cart-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <BackButton buttonName="Shopping Cart" />
                            <div className='marketplace-shopping-cart-wrapper'>
                                <div className='marketplace-shopping-cart-left-section'>
                                    <div className="view-course-details-image course-bg-image">
                                        <img src={marketplace_Course_image} alt="" />
                                    </div>
                                    <div className='marketplace-shopping-cart-des-wrapper'>
                                        <span className='delete'><i class="fa-solid fa-trash"></i></span>
                                        <p className='course-name'>Science</p>
                                        <h2>Fundamental of Neuroscience</h2>
                                        <p className='teacher-name'>By Rehan blackman</p>
                                        <div className="studentDashboar-Suggested-Courses-profile-rate">
                                            <a href="#"><i className="fa-solid fa-star"></i></a>
                                            <p className="rate">4.1</p>
                                            <p className="number">(322) View Ratings</p>
                                        </div>
                                        <ul className='course-list-counts'>
                                            <li>4 lessons</li>
                                            <li>80 min of reading content</li>
                                            <li>25 assignments</li>
                                        </ul>
                                        <h2 className='marketplace-shopping-cart-price'>$20</h2>
                                    </div>
                                </div>
                                <div className='marketplace-shopping-cart-right-section'>
                                    <div className='marketplace-price-container'>
                                        <div className='marketplace-price-header'>
                                            <div className='marketplace-price'>
                                                <p>Price (01 courses)</p>
                                                <h2>$20</h2>
                                            </div>
                                            <div className='marketplace-inner-price'>
                                                <span>Summer Special</span>
                                                <span>-$4.99 <i class="fa-solid fa-x"></i></span>
                                            </div>
                                        </div>
                                        <div className='marketplace-price'>
                                            <p>Total</p>
                                            <h2>$15</h2>
                                        </div>
                                        <div className='cupen-Applied-btn'>
                                            <span>Summer Special</span>
                                            <button>Applied</button>
                                        </div>
                                        <button className='price-checkout-btn' onClick={() => navigate(`/addtocart`)}>Checkout</button>
                                    </div>
                                </div>
                            </div>
                            <h2 className='title'>You might also like</h2>
                            <div className='ratingCard-wrapper'>
                                <MarketPlaceCourseCard
                                    course_demo_image = {course_demo_image}
                                    title= "Rehan blackman"
                                    rate= "4.1"
                                    number= "(322)"
                                    Course_name= "Science"
                                    course_title= "Fundamental of Neuroscience"
                                    price = "$20"
                                    button_name1= "View Course"
                                    button_name2= "Enroll"
                                />
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </>
    )
}

export default MarketPlaceShoppingCart;