import React, { useEffect, useState } from "react";
import LessonAndAssingmentTab from "./LessonAndAssingmentTab";
import BackButton from "../BackButton/BackButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { search, srot } from "../../assets/images/images"; 
import { CircularProgress, Grid } from "@mui/material";
import { useAuth } from "../../features/auth/AuthContext";
import { updateGlobalState } from "../../state/global/slice";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import "./AssignmentTab.css";
import {
  getAssignmentsByLesson,
  resetAssignmentsList,
} from "../../state/actions/getAssignmentsByLessonAction";
import SearchComponent from "../SearchComponent/SearchComponent";
import SortPopup from '../../pages/customSort/SortPopup';
import { toast } from "react-toastify";
import { resetdeleteAssignment } from "../../state/reducers/deleteAssignmentReducer";

const MultiAssignment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const itemsPerPage = 12;
  const [selLession, setSelLession] = useState(-1);
  const { lesson_id } = useParams();
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteCourse, setdeleteCourse] = useState(false);
  const [loading, setLoading] = useState(false);
  const globalState = useSelector((state) => state.globalState);
  const [searchOption, setsearchOption] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const [sortOption, setSortOption] = useState("");
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const {
    count,
    next,
    previous,
    totalPages,
    error,
    assignments,
    isLoading,
  } = useSelector((state) => state.getassignmentsbyLesson);
  const [lessonlisting, setlessonlisting] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const teacher_id =
    location.state?.teacher_id ??
    globalState.teacher_details?.data?.teacher_id ??
    "";

  const refreshAssignments = () => {
    dispatch(getAssignmentsByLesson(lesson_id, currentPage, token, searchOption, sortOption));
    localStorage.removeItem("assignmentanalyticsdetails");
  };
  const deleteAssignment = useSelector((state) => state.deleteAssignment);
  const inial_value = (currentPage - 1) * itemsPerPage;
  
  useEffect(() => {
    refreshAssignments();
  }, [currentPage, lesson_id, token, dispatch, searchOption, sortOption]);

  useEffect(() => {
    setNextUrl(next); // Update next URL
    setPrevUrl(previous); // Update previous URL
    setlessonlisting(assignments);
  }, [assignments, next, previous]);

  useEffect(() => {
    return () => {
      dispatch(resetAssignmentsList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (deleteAssignment?.success) {
      toast.success("Assignment has been deleted successfully.");
      dispatch(resetdeleteAssignment());
    } else if (deleteAssignment?.error?.length) {
      toast.error("Something went wrong.");
      dispatch(resetdeleteAssignment());
    }
    return () => {
      dispatch(resetdeleteAssignment());
    };
  }, [deleteAssignment, dispatch]);

  const handleSortOptionChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };

  const handleSearch = (e) => {
    setsearchOption(e.target.value);
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    let suffix = "th";
    if (day % 10 === 1 && day !== 11) {
      suffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      suffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      suffix = "rd";
    }

    return `${day}${suffix} ${month}, ${year}`;
  }

  return (
    <>
      <div className="assignment-list-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <BackButton buttonName={"Back"} />
              <div className="assignment-list-section">
                <h2 className="student-Active-Lessons">Assignment</h2>
                <div className="search_header-container">
                  <SearchComponent
                    search={search}
                    searchPlaceholder="Search"
                    searchValue="search"
                    onchange={handleSearch}
                    name="searchOption"
                    value={searchOption}
                  />
                  <div className="filter-wrapper">
                    <div
                      className="lessonviewprogress-filder-tab"
                      onClick={() => {
                        setIsVisible(!isVisible);
                      }}
                    >
                      <span>
                        <i className="fa-solid fa-arrow-down-wide-short"></i>
                      </span>
                      <p>Sort</p>
                      <SortPopup
                        isVisible={isVisible}
                        handleApplyClick={() => {}}
                        sortOption={sortOption}
                        onSortOptionChange={handleSortOptionChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="assignment-list-wraper">
                  {isLoading ? (
                    <LoadingComponent loading_text="Educating the Future, Loading Now." />
                  ) : lessonlisting.length > 0 ? (
                    lessonlisting.map((lesson, index) => (
                      <LessonAndAssingmentTab
                        key={index}
                        type="Assignment"
                        titleHeader={inial_value + index}
                        title={lesson?.name}
                        Coursename={location?.state?.course?.name}
                        start_date={formatDate(lesson.start_date)}
                        end_date={
                          lesson.end_date != null
                            ? formatDate(lesson.end_date)
                            : "-"
                        }
                        Progress_btn="Assignment Progress"
                        isAssignmentTab={true}
                        course={location?.state?.course}
                        lesson={location?.state?.lesson}
                        assignment={lesson}
                        teacher_id={teacher_id}
                        subjecttitle={"Lesson: "}
                        Lessonname={location?.state?.lesson?.name}
                        Editbutton={true}
                        refreshAssignments={refreshAssignments} // Pass the refresh function
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No Assignment found.</td>
                    </tr>
                  )}
                  <Grid xs={12} container justifyContent={"center"}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      hideNextButton={
                        nextUrl === null || currentPage === totalPages
                      }
                      hidePrevButton={prevUrl === null || currentPage === 1}
                    />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiAssignment;
