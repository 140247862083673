import React, { useRef } from "react";
import { uplode_file } from "../../assets/images/images";
import "./GoogleDriveUpload.css";

const UploadFromDevice = ({
  handleFileChange,
  fileInputRef,
}) => {

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
        <div
          className="google-drive-popup-folder"
          onClick={handleIconClick}
          style={{ cursor: "pointer" }}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".pdf,.doc,.docx,.xlsx,.xls,.jpg,.jpeg,.png,.txt"
            onChange={handleFileChange}
          />
          <div className="google-drive-image">
            <img src={uplode_file} />
          </div>
          <p>Upload from Computer</p>
        </div>
  );
};

export default UploadFromDevice;
