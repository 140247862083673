import { createContext, useContext, useReducer, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import coursereducer from "../reducer/CourseReducer";
import {useAuth} from '../features/auth/AuthContext';
import api from "../api/api";

const CourseContext = createContext();


export const CourseContextProvider = ({ children }) => {
    const initialFilterState = {
        filter_data: [],
        all_data: [],
        total: 0,
        loading : false,
        error : true,
        errormsg : '',
        filters: {
        },
        extra_data: {},
    };
    const [state, dispatch] = useReducer(coursereducer, initialFilterState);

    const location = useLocation();


    // const { analytic_id } = useParams();

    
    const locationState = location.state;
    const { token } = useAuth();

    const updateLessionFilter = (event) => {
        let name = event.target.name;
        let value = event.target.value
        return dispatch({ type: "UPDATE_LESSION_FILTER", payload: { name, value } });
    };
    const getLessionProgress = async () => {
        dispatch({ type: "SET_LOADING" });
        let lessonId = locationState?.lesson?.lesson_id;
        api.get(`/lessons/get-lesson-status/` + lessonId + `/?teacher_id=${locationState.teacher_id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization:  `Token ${token}`,
            },
        }).then((response) => {
            if (response.status === 200) {
                if(response.data.count>0){
                    dispatch({ type: "SET_API_DATA", payload: response?.data?.data ?? [] });
                }else{
                    dispatch({ type: "API_ERROR", errormsg: response?.message?.message ?? 'No Record Found'});
                }
            }else{
                dispatch({ type: "API_ERROR", errormsg: response?.message?.message ?? 'Something went wrong'});
            }
        })
        .catch((error) => {
            dispatch({ type: "API_ERROR", errormsg: error });
        });
    }
    const getLessionAssignmentsProgress = async () => {
        dispatch({ type: "SET_LOADING" });
        let assignmentId = locationState?.assignment?.assignment_id;
        api.get(`/assignments/get-assignment-status/` + assignmentId + `/?teacher_id=${locationState.teacher_id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization:  `Token ${token}`,
            },
        }).then((response) => {
            if (response.status === 200) {
                if(response.data.data.length>0){
                    dispatch({ type: "SET_API_DATA", payload: response?.data?.data ?? [] });
                }else{
                    dispatch({ type: "API_ERROR", errormsg: response?.message?.message ?? 'No Record Found'});
                }
                
            }else{
                dispatch({ type: "API_ERROR", errormsg: response?.message?.message ?? 'Something went wrong'});
            }
        })
        .catch((error) => {
            dispatch({ type: "API_ERROR", errormsg: error });
        });
    }
    
    useEffect(() => {
        if(locationState?.assignment){
            getLessionAssignmentsProgress();
        }else if(locationState?.lesson){
            getLessionProgress();
        }
    }, []);

    
    
    return (
        <CourseContext.Provider
            value={
                {
                ...state,
                updateLessionFilter
                }
            }
        >
            {children}
        </CourseContext.Provider>
    );
};
    
export const useCourseContext = () => {
    return useContext(CourseContext);
};


// export { CourseContextProvider, CourseContext, useCourseContext };
    
