import { Dispatch } from "redux";
import api from "../../api/api";
import {
    submitgoogleToken,
    setloading,
    seterror,
    resetsubmitgoogleToken,
} from "../reducers/submitGoogleTokenReducer";

const submitGoogleToken = (data: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.post(`/auth/google-login-token/`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            if (result?.data?.status === "success") {
                dispatch(submitgoogleToken(result?.data));
            }
        }
    } catch (error: any) {
        dispatch(seterror(error?.response?.data?.message));
    }
};

export { submitGoogleToken, resetsubmitgoogleToken };

