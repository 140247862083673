import React, { useEffect, useState } from "react";
import TeachersList from "./TeachersList"; // Renamed the component here  
import {
  teacher_reviews_profile,
  teacher_reviews_profile_aword,
  search,
  srot
} from "../../assets/images/images";
import { ReactSVG } from "react-svg";
import {
  fetchAllSchoolData,
  resetAllTeacherBySchool,
} from "../../state/actions/getAllTeacherBySchoolAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../features/auth/AuthContext";
import { useParams } from "react-router-dom";
import PaginationView from "../../components/Pagination/PaginationView";
import SearchBar from "../Search/SearchBar";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import BackButton from "../../../src/components/BackButton/BackButton";
import SearchComponent from "../SearchComponent/SearchComponent";
import SortPopup from "../../pages/customSort/SortPopup";
import FilterPopup from "../../pages/customFilter/FilterPopup";
import TeacherFilter from "./TeacherFilter";
import { addToHistory } from '../../state/reducers/historyReducer';
import { CircularProgress, Grid } from "@mui/material";

const Teachers = () => {
  // const { school_id } = useParams(); // Call it unconditionally at the beginning
  const { token } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOption, setsearchOption] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [filterOption, setfilterOption] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isVisiblefillter, setIsVisiblefillter] = useState(false);
  const [grade, setgrade] = useState("");
  const [subject, setsubject] = useState("");
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.globalState);
  const { teachers, students, count, next, previous, totalPages, error, isLoading } = useSelector(
    (state) => state.fetchSchoolData
  );

  const filterArray = [
    { id: "Invited", name: "Invited & Enrolled" },
    { id: "Enrolled", name: "Enrolled" },
    // { id: "Rejected", name: "Rejected" },
    { id: "", name: "All" },
  ]

  useEffect(() => {
    setCurrentPage(1);
  }, [searchOption, sortOption, filterOption]);

  useEffect(() => {
    getAllteacherDetails();
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetAllTeacherBySchool());
    };
  }, [currentPage, searchOption, sortOption, filterOption, grade, subject]);

  useEffect(() => {
    dispatch(addToHistory('/dashboard/teachers'))
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetAllTeacherBySchool());
    };
  }, []); // Unconditionally called at the beginning
  const globalState = useSelector((state) => state.globalState);
  const getAllteacherDetails = () => {
    const type = 'Teacher'; // Define the type variable
    const school_id = globalState.school_id;
    dispatch(fetchAllSchoolData(currentPage, school_id, token, type, role, searchOption, filterOption, sortOption, grade, "", subject));
  };

  const [selectedValue, setSelectedValue] = useState('individual');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // This function will render only the text of the selected option
  const renderValue = (selected) => {
    return selected === 'individual' ? 'Individual' : 'Organization';
  };

  const handleSearch = (e) => {
    setsearchOption(e.target.value)
  }

  const handleSortOptionChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const handleApplyClick = () => {
    setIsVisible(false);
    setIsVisiblefillter(false)
  }

  // Example array of organizations
  const organizations = [
    { id: 1, name: 'Saddle River Day School' },
    { id: 2, name: 'American International School of Algiers' },
    { id: 3, name: 'Saddle River Day School' },
    { id: 4, name: 'Pine Grove Academy' },
    { id: 5, name: 'Saddle River Day School' },
    { id: 6, name: 'Pine Grove Academy' },
    // Add more organizations here
  ];

  return (
    <>
      <h2 className="dashboard-name-title">Teachers</h2>
      {/* <SearchBar
        search={search}
        setSearch={setSearch}
        filterArray={filterArray}
        sortOption={sortOption}
        setSortOption={setSortOption}
        filterOption={filterOption}
        setfilterOption={setfilterOption} /> */}

      {/* <SearchComponent
        search={search}
        searchPlaceholder="Search"
        searchValue="search"
        onchange={handleSearch}
        name="searchOption"
        value={searchOption}
      /> */}



      <div className="search_header-container">
        <SearchComponent
          search={search}
          searchPlaceholder="Search"
          searchValue="search"
          onchange={handleSearch}
          name="searchOption"
          value={searchOption}
        />
        <div className="filter-wrapper">
          {/* <div className="Filter-teacher-type-wrapper">
            <p>Filter teacher type:</p>
            <div className="Filter-teacher-type-select-dropdown">
              <FormControl variant="outlined" style={{ minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedValue}
                  onChange={handleChange}
                  renderValue={renderValue} // Use the renderValue prop here
                >
                  <MenuItem value="individual">
                    <FormControlLabel
                      control={<Radio checked={selectedValue === 'individual'} />}
                      label="Individual"
                      value="individual"
                    />
                  </MenuItem>
                  <MenuItem value="organization">
                    <FormControlLabel
                      control={<Radio checked={selectedValue === 'organization'} />}
                      label="Organization"
                      value="organization"
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div> */}
          <div className="lessonviewprogress-filder-tab"
            onClick={() => {
              if (!isVisiblefillter) {
                setIsVisiblefillter(true);
                setIsVisible(false);
              } else {
                setIsVisiblefillter(false);
              }
            }
            }
          >
            <span>
              <i className="fa-solid fa-sliders"></i>
            </span>
            <p>Filter</p>

          </div>
          <TeacherFilter
            gradeList={globalState?.grade_details?.grade}
            grade={grade}
            setgrade={setgrade}
            subjectList={globalState?.datalist?.subjects}
            subject={subject}
            setsubject={setsubject}
            // score={score}
            isVisiblefillter={isVisiblefillter}
            handleApplyClick={handleApplyClick}
          // handleApplyClick={handleApplyClick}
          // handleAssignmentStatusChange={handleAssignmentStatusChange}
          // fillterstatus={fillterstatus}
          // fillterScore={fillterScore}
          // handleScoreChange={handleScoreChange}

          />

          {/* <div className="Sort-dropdown">
          </div> */}
          <div className="lessonviewprogress-filder-tab"
            onClick={() => {
              if (!isVisible) {
                setIsVisible(true);
                setIsVisiblefillter(false);
              } else {
                setIsVisible(false);
              }
            }}
          >
            <span>
              {/* <img src={srot}/> */}
              <i class="fa-solid fa-arrow-down-wide-short"></i>
            </span>
            <p>Sort</p>

          </div>
          {isVisible && <SortPopup
            isVisible={isVisible}
            handleApplyClick={handleApplyClick}
            sortOption={sortOption}
            onSortOptionChange={handleSortOptionChange}
          />}
        </div>
      </div>
      {totalPages > 1 && (
        <div className="pagination_top_wrapper">
          <PaginationView
            totalPages={totalPages}
            next={next}
            previous={previous}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
      <div className="Dashboard-Review-Card-wrapper" style={{ marginTop: "30px" }}>
        {isLoading ? 
        <Grid
          height={"50vh"}
          xs={12}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress size={80} />
        </Grid> : teachers && teachers?.length > 0 ?  teachers.map((data, index) => (
          <>
            <TeachersList
              key={data.uid}
              teacher_id={data.uid}
              // Teacher_Grade={data.teacher_grade}
              teacher_reviews_profile={data.ai_avatar}
              teacher_reviews_profile_aword={teacher_reviews_profile_aword}
              teacher_reviews_title={data.name}
              teacher_reviews_star_number="3.5"
              teacher_reviews_subject={data.subjects}
              tid={data.teacher_id}
              isactive_status={data.active}
              user_id={data.user_id}
              getAllteacherDetails={getAllteacherDetails}
            />
          </>
        )) :
          <p style={{ textAlign: "center", marginTop: "10px", margin: "0px auto" }}>No Teachers Found</p>
        }
      </div>

      <div className="Select-organization-dropdown-menu-wrapper">
        <div className="Select-organization-dropdown-menu-popup-cross-btn">
          <i class="fa-solid fa-x"></i>
        </div>
        <div className="Select-organization-dropdown-menu-container">
          <BackButton buttonName={"Select organization"} />
          <div className="Select-organization-dropdown-serach">
            <input type="serach" placeholder="Search for organization" />
            <span><i class="fa-solid fa-magnifying-glass"></i></span>
          </div>
          <div className="Select-organization-dropdown-inner-wrapper">
            {organizations.map((org) => (
              <div key={org.id} className="Select-organization-dropdown-inner-folder">
                <input type="checkbox" id={`checkbox-${org.id}`} />
                <label htmlFor={`checkbox-${org.id}`}>{org.name}</label>
              </div>
            ))}
          </div>
          <div className="Select-organization-dropdown-btn">
            <button>Apply</button>
            <button>Clear All</button>
          </div>
        </div>
      </div>
      {totalPages > 1 && (
        <PaginationView
          totalPages={totalPages}
          next={next}
          previous={previous}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};

export default Teachers;