import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./utils/ScrollToTop";
import { QueryClient, QueryClientProvider } from "react-query";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CourseWrapper from "./CourseWrapper";
import { wsURL } from "./api/api";
import { useAuth } from "./features/auth/AuthContext";
import useWebSocket from "./hooks/useWebSocket";
import Dashboardheader from "./pages/home/components/dashboard/Dashboardheader";
import CheckRoute from "./routes/checkRoutes";
import PrivateRoute from "./routes/privateRoutes";
import ErrorBoundaryWithNavigation from "./ErrorBoundaryWithNavigation"; // Make sure this is imported correctly
import LoadingComponent from "./components/LoadingComponent/LoadingComponent";
import {
  adminroutes,
  commonroutes,
  coursesroutes,
  landingpages,
  studentroutes,
  superadminroutes,
  teacherroutes,
} from "./routes/routes";

const queryClient = new QueryClient();

function App() {
  const globalState = useSelector((state) => state.globalState);
  const { token } = useAuth();
  const email = useSelector((state) => state.globalState.email);
  const id =
    globalState?.role === "Student"
      ? globalState?.student_details?.data?.student_id
      : globalState?.role === "Teacher"
      ? globalState?.teacher_details?.data?.teacher_id
      : globalState?.school_details?.school_id;
  const { notifications } = useWebSocket(`${wsURL}ws/notification/${id}/`);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Suspense fallback={<LoadingComponent loading_text="Educating the Future, Loading Now." />}>
          <ErrorBoundaryWithNavigation token={token} userEmail={email}>
            <div className="App">
              {token && (
                <Dashboardheader
                  notifications={notifications}
                  hasNewNotification={notifications?.length > 0}
                />
              )}
              <ScrollToTop />
              <Routes>
                {[
                  ...landingpages,
                  ...commonroutes,
                  ...studentroutes,
                  ...teacherroutes,
                  ...coursesroutes,
                  ...adminroutes,
                  ...superadminroutes,
                ].map((route, index) => {
                  const {
                    path,
                    component: Component,
                    isPrivate,
                    isCheckRoute,
                    hasWrapper,
                  } = route;

                  if (isCheckRoute) {
                    return (
                      <Route
                        key={index}
                        path={path}
                        element={<CheckRoute component={Component} />}
                      />
                    );
                  }

                  if (isPrivate) {
                    return (
                      <Route
                        key={index}
                        path={path}
                        element={<PrivateRoute element={<Component />} />}
                      />
                    );
                  }

                  if (hasWrapper) {
                    return (
                      <Route
                        key={index}
                        path={path}
                        element={
                          <CourseWrapper>
                            <PrivateRoute element={<Component />} />
                          </CourseWrapper>
                        }
                      />
                    );
                  }

                  return <Route key={index} path={path} element={<Component />} />;
                })}
              </Routes>
            </div>
          </ErrorBoundaryWithNavigation>
        </Suspense>
        <ToastContainer />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
