import React from "react";
import { payment_card } from "../../../../../../assets/images/images";
import PaymentInputFeilds from "./paymentCoustomInput";
import "./payment.css";
import BackButton from "../../../../../../components/BackButtonFolder/BackButtonFolder";

function Payment() {

  return <>
    <div className="payment-section">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <BackButton
                        buttonName="Choose your payment method"
                    />
                    <div className="payment-wrapper">
                        <div className="payment-left-section">
                            <div className="payment-left-des-container">
                                <p>Safe money transfer using  your bank account.
                                   Visa, Mastercard, Discover, and American express.
                                </p>
                                <div className="payment-card-wrapper">
                                    <div className="payment-card">
                                        <img src={payment_card}/>
                                    </div>
                                    <div className="payment-card">
                                        <img src={payment_card}/>
                                    </div>
                                    <div className="payment-card">
                                        <img src={payment_card}/>
                                    </div>
                                    <div className="payment-card">
                                        <img src={payment_card}/>
                                    </div>
                                </div>
                            </div>
                            <form className="payment-details-container">
                                <div className="payment-details-inner-input">
                                    <PaymentInputFeilds
                                        cardNumber="Card Number"
                                        placeholder="Enter card number"
                                    />
                                </div>
                                <div className="payment-details-date-folder">
                                    <div className="payment-details-inner-date">
                                        <PaymentInputFeilds
                                            cardNumber="Card Expiry"
                                            placeholder="MM/YY"
                                        />
                                    </div>
                                    <div className="payment-details-inner-date">
                                        <PaymentInputFeilds
                                            cardNumber="CVV"
                                            placeholder="000"
                                        />
                                    </div>
                                </div>
                                <div className="payment-details-inner-input">
                                    <PaymentInputFeilds
                                        cardNumber="Name on card"
                                        placeholder="Enter your name"
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="payment-right-section">
                            <h2>Billing Summary</h2>
                            <p className="payment-course-title">Course: <span>Fundamental of Neuroscience</span></p>
                            <div className="payment-price-container">
                                <div className="payment-price-folder">
                                    <span>price:</span><span>$ 20</span>
                                </div>
                                <div className="payment-price-folder">
                                    <span>Total:</span><span>$ 20</span>
                                </div>
                            </div>
                            <div className="Promo-code">
                                <a href="#">Have a Promo code?</a>
                            </div>
                            <button className="Proceed-to-Pay">Proceed to Pay</button>
                            <div className="payment-info-folder">
                                <p>Have a any question? Write to our support team at <a href="#">help@proteache.ai</a> or visit our <a href="#">Help Center.</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </> 
}

export default Payment;