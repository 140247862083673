import { CircularProgress, Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import api from "../../../../../../api/api";
import imgs from "../../../../../../assets/images/imgs";
import StudentTeacherprofileChatbot from "../../../../../../components/StudentTeacherprofileChatbot";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import StudentDashboardChatbot from "../chatbot/StudentChatbot";
import StudentSuggesionCourseData from "../course/StudentSuggesionCourseData";
import { useNavigate } from "react-router-dom";
import FilteredInput from "../../../../../../components/FilteredInput/FilteredInput";
import { updateGlobalState } from "../../../../../../state/global/slice";

function StudentHome({
  removeTeacherActiveChat,
  profileFirstName,
  showTooltip,
  setShowTooltip,
  teacherChat,
  setTeacherChat,
  onClickTeacher,
  activeTeacher,
  handleTeacherClick,
  chatbotTeacherStatus,
  setTeacherchatbotStatus,
  getTeacherActiveChat,
  setTeacherActiveChat,
  setActiveTab
}) {
  const navigate = useNavigate();
  const globalState = useSelector((state) => state.globalState);
  const dispatch = useDispatch();
  const [TempglobalState, setTempglobalState] = useState("");

  const get_teacher_list = globalState?.get_teacher_list?.data ?? [];

  const [showteacherProfile, setShowteacherProfile] = useState(false); // State to control visibility
  const modalRef = useRef();
  const modalContentRef = useRef(); // Reference to the modal content

  // Function to open the modal
  const openModal = () => {
    setShowteacherProfile(true);
  };


  const toggleteacherProfile = () => {
    // setShowteacherProfile(!showteacherProfile); // Toggle the visibility state
    setShowteacherProfile(false); // Toggle the visibility state
    //document.body.style.overflow = showteacherProfile ? "auto" : "hidden";
  };

  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };

  // Event listener to close modal if clicked outside the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
        toggleteacherProfile();
      }
    };

    // Attach the event listener
    if (showteacherProfile) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showteacherProfile]);


  const [search, setSearch] = useState("");
  const serachhandleInputChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };
  const changeSubjectFillter = (event) => {
    const selectedSubject = event.target.value;
    setTempglobalState(selectedSubject);
  };
  const statusPriority = (status) => {
    switch (status) {
      case "Pending":
        return 1;
      case "Active":
        return 2;
      default:
        return 3;
    }
  };

  const { token } = useAuth();
  const [courses, setCourses] = useState(globalState?.student_courses);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const itemsPerPage = 12; // Define number of items per page
  // Function to fetch courses using Axios
  const [loading, setLoading] = useState(false); // Add this state to your component

  const fetchCourses = async (page) => {
    try {
      setLoading(true); // Set loading to true before the request
      const response = await api.get(`courses/student/my-courses-dashboard/`, {
        params: {
          page: page,
          search: search,
          filter: TempglobalState,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });

      const data = response.data;

      setCourses(data.data);
      setLoading(false);
      setTotalPages(
        data.count >= 5
          ? Math.ceil(data.count / itemsPerPage)
          : Math.floor(data.count / itemsPerPage)
      ); // Calculate total pages
      setNextUrl(data.next); // Update next URL
      setPrevUrl(data.previous); // Update previous URL
    } catch (error) {
      console.error("Fetching error:", error);
    } finally {
      setLoading(false); // Set loading to false after the request is completed or if an error occurs
    }
  };

  // Effect to fetch data when currentPage changes
  useEffect(() => {
    fetchCourses(currentPage);
  }, [currentPage, TempglobalState, search, globalState.student_details]);

  useEffect(() => {
    api
      .get("/auth/get-teacher-list/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          updateState("get_teacher_list", response?.data ?? {});
        }
      })
      .catch((error) => { });
  }, [])

  // Function to handle page change
  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };

  const activeCourses =
    globalState?.student_details?.data?.my_courses?.active_courses;

  const assignmentsCount =
    globalState?.student_details?.data?.my_courses?.pending_assignments;
  // Correct: Creating a copy of the array and then sorting
  let coursesSortedArray = [];
  if (Array.isArray(courses) && courses.length) {
    coursesSortedArray = [...courses].sort((a, b) => {
      return statusPriority(a.status) - statusPriority(b.status);
    });
  }

  return (
    <>
      <div className="studentDashboar-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="studentDashboar-wrapper">
                <h2>Hello {profileFirstName}!</h2>
                <div className="studentDashboar-actives-container">
                  <div
                    className="studentDashboar-actives-folder"
                    onClick={openModal}
                  >
                    <div className="studentDashboar-actives-image">
                      <img src={imgs.png.Teacher_profile} alt="" />
                    </div>
                    <div className="studentDashboar-actives-course-count">
                      <h2>
                        {
                          get_teacher_list.filter(
                            (teacher) =>
                              teacher?.first_course?.course_id !== null
                          )?.length
                        }
                      </h2>
                      <p>My Teacher</p>
                    </div>
                  </div>
                  {showTooltip && (
                    <div
                      className={`teacher_tooltip ${showTooltip ? "show" : ""}`}
                    >
                      <p>
                        Click on "My Teacher" tab to chat with the teacher's AI.
                      </p>
                      <button
                        onClick={() => setShowTooltip(false)}
                        className="tooltip_btn"
                      >
                        Got it
                      </button>
                      <img
                        src={imgs.png.tooltip_trangle}
                        alt="tooltip trangle"
                        className="tooltip_trangl`e"
                      />
                      `
                    </div>
                  )}
                  <div className="studentDashboar-actives-folder">
                    <div className="studentDashboar-actives-image">
                      <img src={imgs.png.Active_course} alt="" />
                    </div>
                    <div
                      className="studentDashboar-actives-course-count"
                      onClick={() => {
                        navigate("/dashboard/my-courses/active-courses");
                        setActiveTab("My Courses")
                      }}
                    >
                      <h2>{activeCourses}</h2>
                      <p>
                        {activeCourses > 1 ? "Active Courses" : "Active Course"}
                      </p>
                    </div>
                  </div>
                  {/* <div className="studentDashboar-actives-folder">
                    <div className="studentDashboar-actives-image">
                      <img src={imgs.png.Asssignment_list} alt="" />
                    </div>
                    <div className="studentDashboar-actives-course-count"
                      onClick={() => {
                        navigate("/StudentActiveAssignment");
                      }}
                    >
                      <h2>{assignmentsCount}</h2>
                      <p>
                        {assignmentsCount > 1 ? "Assignments" : "Assignment"}{" "}
                      </p>
                    </div> 
                  </div>*/}
                </div>
                <div className="studentDashboar-Suggested-Courses-wrapper">
                  <div className="studentDashboar-Suggested-Courses-short-by-filter-container">
                    <div className="studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut">
                      {/* <p>Suggested Courses</p> */}
                      <div className="studentDashboar-search-filter">
                        <input
                          type="text"
                          name="search"
                          value={search}
                          onChange={serachhandleInputChange}
                          placeholder="Search courses"
                        />
                        <div className="serach-input-icon">
                          <ReactSVG src={imgs.svg.search} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="studentDashboar-Suggested-Courses-short-by-filter-section">
                      <div className="studentDashboar-Suggested-Courses-short-by-filter-inner-folder">
                        <span>Filter By :</span>
                        <select
                          className="studentDashboar-course-filter"
                          onChange={changeSubjectFillter}
                        >
                          <option value="">Suggested</option>
                          <option value="English">English</option>
                          <option value="Physics">Physics</option>
                          <option value="Chemistry">Chemistry</option>
                          <option value="Math">Math</option>
                          <option value="Science">Science</option>
                        </select>
                      </div>
                      {/* <div className="studentDashboar-Suggested-Courses-short-by-filter-inner-folder">
                          <span>Sort By :</span>
                          <select className="studentDashboar-course-filter">
                            <option>None</option>
                            <option value="High to Low">Price : High to Low</option>
                            <option value="Low to High">Price : Low to High</option>
                          </select>
                        </div> */}
                    </div>
                  </div>
                  <div className="studentDashboar-Suggested-Courses-container">
                    {loading ? <Grid
                      height={"50vh"}
                      xs={12}
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <CircularProgress size={80} />
                    </Grid> :
                      coursesSortedArray?.length > 0 && !loading ? (
                      coursesSortedArray
                        // Map to components
                        .map((course, index) => (
                          <StudentSuggesionCourseData
                            key={index}
                            course={course}
                          />
                        ))
                    ) : (
                          <p>No Courses Found</p>
                    )}
                  </div>
                </div>
                <br />
                <Grid xs={12} container justifyContent={"center"}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    hideNextButton={
                      nextUrl === null || currentPage === totalPages
                    } // Disable if no next URL and on the last page
                    hidePrevButton={prevUrl === null || currentPage === 1} // Hide if no previous URL and on the first page
                  />
                </Grid>
                <div className="StudentChatbot-section">
                  {getTeacherActiveChat.map((teacher, index) => (
                    <div key={index}>
                      {chatbotTeacherStatus && (
                        <StudentDashboardChatbot
                          key={index}
                          removeTeacherActiveChat={() =>
                            removeTeacherActiveChat(teacher)
                          }
                          right={index * 30}
                          ai_name={`${teacher.name}`}
                          teacher_id={teacher?.user}
                          courseId={
                            teacher?.first_course
                              ? teacher?.first_course?.course_id ?? ""
                              : ""
                          }
                          isMinimize={teacher.isMinimized}
                          chatbotStatus={chatbotTeacherStatus}
                          lesson_name={
                            teacher?.first_lesson
                              ? teacher?.first_lesson?.lesson_name ?? ""
                              : ""
                          }
                          ai_tone={teacher?.ai_tone}
                          ai_avatar={teacher?.ai_avatar}
                          ai_welcome_msg={teacher?.ai_welcome_msg}
                        />
                      )}
                    </div>
                  ))}
                </div>
                {showteacherProfile && (
                  <div ref={modalRef} className="studentDashboar-teacher-popup-section">
                    <div ref={modalContentRef}
                      className="studentDashboar-teacher-popup-wrapper"
                      data-aos="zoom-in"
                    >
                      <h2 className="studentDashboar-teacher-title">
                        My Teachers
                      </h2>
                      <div
                        className="studentDashboar-teacher-popup-cross-btn"
                        onClick={toggleteacherProfile}
                      >
                        <i class="fa-solid fa-x"></i>
                      </div>
                      <div className="studentDashboar-teacher-popup-main-container">
                        <div className="studentDashboar-teacher-popup-container">
                          {get_teacher_list?.filter(
                            (teacher) =>
                              teacher?.first_course?.course_id !== null
                          )?.length > 0 ? (
                            get_teacher_list
                              ?.filter(
                                (teacher) =>
                                  teacher?.first_course?.course_id !== null
                              )
                              ?.map((teacher, index) => (
                                <StudentTeacherprofileChatbot
                                  key={index}
                                  closeTeacherProfile={toggleteacherProfile}
                                  onClickTeacher={() => onClickTeacher(teacher)}
                                  studentDashboar_teacher_image={
                                    teacher.ai_avatar ??
                                    imgs.png.tearcher_profile_image
                                  }
                                  teacherName={teacher.name}
                                  teacherId={teacher?.teacher_id}
                                  teacherTitleValue={teacher?.title}
                                />
                              ))
                          ) : (
                            <p>Please enroll in one of the course.</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentHome;