import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GoogleToken {
    googleToken: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: GoogleToken = {
    googleToken: null,
    isLoading: false,
    error: null,
};


const googleTokenSlice = createSlice({
    name: "getGoogleToken",
    initialState,
    reducers: {
        setGoogleToken: (state: GoogleToken, action: PayloadAction<GoogleToken>) => {
            state.googleToken = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: GoogleToken, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: GoogleToken, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
            state.googleToken = null;
        },
        resetGoogleToken: () => initialState,
    },
});

export const {
    setGoogleToken,
    setloading,
    seterror,
    resetGoogleToken,
} = googleTokenSlice.actions;

export default googleTokenSlice.reducer;
