import React, { useEffect, useState } from 'react';
import CustomTabs from '../../CustomTabs/CustomTabs';
import TeacherProfileSetting from "../../../pages/home/components/dashboard/student/teacher/TeacherProfileSetting";
import Assistant from '../../../pages/home/components/settings/tabs/Assitant';
import CreatedCourses from './CreatedCourses';
import { getTeacherDetails, resetTeacherDetails } from "../../../state/actions/getTeacherByTeacherIdAction";
import { updateGlobalState } from "../../../state/global/slice";
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../features/auth/AuthContext';
import { useParams } from 'react-router-dom';
import Profile from "../../../pages/home/components/settings/tabs/Profile";
import TeacherProfile from './TeacherProfile';
import BackButton from '../../BackButton/BackButton';
import { useCustomNavigation } from '../../../hooks/useCustomNavigation';
import { addToHistory } from '../../../state/reducers/historyReducer';

function TeacherDetails() {

    const { token } = useAuth();
    const { teacher_id } = useParams();
    const dispatch = useDispatch();
    const { navigateBackOrDashboard } = useCustomNavigation('/dashboard/teachers');
    const [isTeacherDetailsLoaded, setIsTeacherDetailsLoaded] = useState(false);
    const getteacherbyTeacherId = useSelector((state) => state.getteacherbyTeacherId);


    const tabList = [
        {
            title: getteacherbyTeacherId?.teacher?.data?.name +" Details",
            displayItem: <TeacherProfile/>,
            //displayItem: <Profile />,
            key: "Teacher Details", // Add the title property
            route: "profile",
        },
        {
            title: "AI Assistant",
            displayItem: <Assistant />,
            key: "AI Assistant", // Add the title property
            route: "ai-assistant",
        },
        {
            title: "Courses",
            displayItem: <CreatedCourses />,
            key: "Created Course", // Add the title property
            route: "courses",
        },
    ];

    const updateState = (key, value) => {
        dispatch(updateGlobalState({ key: key, value: value }));
    };

    const getTeacherDetailsRequest = () => {
        dispatch(getTeacherDetails(teacher_id, token))
    }

    useEffect(() => {
        getTeacherDetailsRequest();
    }, [teacher_id])

    useEffect(() => {
        if (Object.keys(getteacherbyTeacherId?.teacher).length > 0) {
            const teacherResponse = getteacherbyTeacherId?.teacher ?? {};
            // Update the state
            updateState("teacher_details", teacherResponse);
            setIsTeacherDetailsLoaded(true)
        }
    }, [getteacherbyTeacherId?.teacher])

    useEffect(() => {
        return () => {
            dispatch(resetTeacherDetails());
        }
    }, [])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div
                        className="view-course-Details-back-btn-folder"
                        onClick={navigateBackOrDashboard}
                    >
                        <i
                            // onClick={() => navigate(-1)}
                            class="fa-solid fa-chevron-left"
                        ></i>

                        <h2>Back</h2>
                    </div>
                    {/* <BackButton buttonName={"Back"} /> */}
                    {isTeacherDetailsLoaded && <CustomTabs tabs={tabList} url={`/TeacherProfile/${teacher_id}`} />}
                </div>
            </div>
        </div>
    )
}

export default TeacherDetails