import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setTextToSpeech, loading, seterror, resetTextToSpeech, setGeneratorId
} from "../reducers/getTextToSpeechReducer";

const getTextToSpeech = (data: any, mode: any, voice: any, token: any) => async (dispatch: Dispatch) => {
    dispatch(loading(true));
    try {
        const result = await api
            .post(
                `/lessons/text-to-speech/?mode=${mode}&voice=${voice}`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            )

        if (result) {
            dispatch(loading(false));
            if (result.data?.status === 200) {
                if (result.data?.data) {
                    dispatch(setTextToSpeech(result?.data?.data));
                    dispatch(setGeneratorId(result?.data?.generator_id))
                } else {
                    dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
                }
            }
            else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
}

export { getTextToSpeech, resetTextToSpeech }
