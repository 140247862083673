import { Dispatch } from "redux";
import api from "../../api/api";
import {
    getcoursefilestatus,
    setCourseFileUploadProgress,
    setloading,
    seterror,
    resetgetcoursefilestatus,
} from "../reducers/courseFileUploadStatusReducer";

const getCourseFileStatus = (id: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));

    const completedIds = new Set();

    const checkStatus = async () => {
        try {

            if (completedIds.has(id)) {
                // If ID is already completed or failed, do not call API
                return;
            }

            const result = await api.get(`/courses/api/file-upload/${id}/`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                });

            if (result) {

                if (result.data.data.status.toLowerCase() === "completed") {
                    completedIds.add(id);
                    dispatch(setCourseFileUploadProgress("100"));
                    dispatch(setloading(false));
                    dispatch(getcoursefilestatus(result.data.data));
                    // setTimeout(checkStatus, 5000);
                }
                else if (result.data.data.status.toLowerCase() === "failed") {
                    completedIds.add(id);
                    dispatch(setCourseFileUploadProgress("0"));
                    dispatch(setloading(false));
                    dispatch(getcoursefilestatus(result.data.data));
                    // setTimeout(checkStatus, 5000);
                }
                else {
                    switch (result.data.data.status.toLowerCase()) {
                        case "started": dispatch(setCourseFileUploadProgress("5"));
                            break;
                        case "uploaded": dispatch(setCourseFileUploadProgress("20"));
                            break;
                        case "processing": dispatch(setCourseFileUploadProgress("40"));
                            break;
                        case "chunking": dispatch(setCourseFileUploadProgress("60"));
                            break;
                        case "parsed": dispatch(setCourseFileUploadProgress("80"));
                            break;
                        case "vector": dispatch(setCourseFileUploadProgress("90"));
                            break;
                        default: dispatch(setCourseFileUploadProgress("0"));
                    }
                    dispatch(setloading(false));
                    dispatch(getcoursefilestatus(result.data.data));
                    setTimeout(checkStatus, 5000);
                }

            }
        } catch (error: any) {
            // dispatch(seterror(error.response.data.message));
        }
    }

    checkStatus();

};

export { getCourseFileStatus, resetgetcoursefilestatus };

