import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api, { wsURL } from "../../../../../../api/api";
import AssignmentTimer from "../../../../../../components/assignmentTimer/AssignmentTimer";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import useSubmitAssignment from "../../../../../../hooks/useAssignmentSubmission";
import useWebSocketConnection from "../../../../../../hooks/useWebSOcketConnection";
import Question from "../../../questions/Question";
import StudentAssignmentScore from "./StudentAssignmentScore";
import BackButton from "../../../../../../components/BackButton/BackButton";
import QuestionNew from "../../../questions/Question_new";
import { CircularProgress, Grid } from "@mui/material";
import StudentLessonPreview from "./StudentLessonPreview";
import "./StudentAssignmentPreview.css";

const StudentAssignmentPreview = () => {
  const globalState = useSelector((state) => state.globalState);
  const studentId = globalState.student_details?.data?.student_id ?? "";
  const navigate = useNavigate();
  const location = useLocation();
  const { assignment_id, course_id, lesson_id } = useParams();
  const { token } = useAuth();
  const [assignment, setAssignment] = useState(
    location?.state?.assignment || {}
  );

  const lesson = location?.state.lesson;
  const [assignmentIndex, setAssignmentIndex] = useState(0);
  const [detailedResult, setdetailedResult] = useState(false);
  const [showScorePopup, setshowScorePopup] = useState(false);
  const [answeredList, setAnsweredList] = useState([]);
  const [getSubmissiondtls, setSubmissiondtls] = useState([]);
  const [congratulationMessgae, setcongratulationMessgae] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignmentLoading, setisAssignmentLoading] = useState(false);
  const [showSubmit, setShowPopup] = useState(false);
  const [assignmentClockTimer, setassignmentClockTimer] = useState(true);
  const [isLessonPreviewEnabled, setIsLessonPreviewEnabled] = useState(false);
  const [
    isVirtualAssistantPreviewEnabled,
    setIsVirtualAssistantPreviewEnabled,
  ] = useState(false);
  const [isTimerPaused, setIsTimerPaused] = useState(false);
  const [timers, setTimers] = useState(
    Array(assignment?.assignment_json?.length).fill(0)
  );
  const [currentStep, setCurrentStep] = useState(0);
  const assignmentJSON = assignment?.assignment_json?.map((item) => {
    return { ...item, student_answer: null, time_taken: "0" };
  });

  const [startIndex, setStartIndex] = useState(1);
  const [lastIndex, setLastIndex] = useState(assignmentJSON?.length);
  const [rangIndex, setRangIndex] = useState(4);
  useEffect(() => {
    const sAPDtls_AssignmentId = localStorage.getItem(
      `sAPDtls_AssignmentId${assignment_id}${token}`
    );
    if (sAPDtls_AssignmentId &&
      sAPDtls_AssignmentId == assignment_id
    ) {
      const sAProgressDtls = localStorage.getItem(
        `studentAssignmentProgressDtls${assignment_id}${token}`
      );
      if (sAProgressDtls) {
        setAnsweredList(JSON.parse(sAProgressDtls));
        const sAPCurrentStep = localStorage.getItem(
          `studentAssignmentProgressCurrentStep${assignment_id}${token}`
        );
        setCurrentStep(Number(sAPCurrentStep));
        const sAPCurrentStepForQuestion = localStorage.getItem(
          `studentAssignmentProgressCurrentStepForQuestion${assignment_id}${token}`
        );
        setStartIndex(sAPCurrentStepForQuestion);
        // const sAPFinalStep = localStorage.getItem(
        //   `studentAssignmentProgressFinalStep${assignment_id}`
        // );
        // if (sAPFinalStep) {
        //   setShowPopup(sAPFinalStep === "true");
        // }
      }
    }
  }, []);
  useEffect(() => {
    getAssignment(lesson_id, assignment_id);
  }, [lesson_id, assignment_id]);

  const getAssignment = async (lesson_id) => {
    setisAssignmentLoading(true);
    await api
      .get(`assignments/get-assignment/${assignment_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setisAssignmentLoading(false);
          setAssignment(response?.data?.data);
          setIsLessonPreviewEnabled(response?.data?.data?.lesson_preview);
          setIsVirtualAssistantPreviewEnabled(
            response?.data?.data?.virtual_assistant
          );
          // console.log("response?.data?.data[0]?.virtual_assistant",response?.data?.data[0]?.virtual_assistant);
          const assignmentData = response?.data?.data;
          if (
            !assignmentData ||
            !assignmentData.assignment_status ||
            !assignmentData.assignment_status.status
          ) {
            createAssignmentStatus(assignment_id);
          }
        }
      })
      .catch(() => {
        setisAssignmentLoading(false);
      });
  };

  // const [currentStep, setCurrentStep] = useState(0);
  const [submission_id, setSubmission_id] = useState(0);
  // const [timers, setTimers] = useState(Array(assignment?.assignment_json?.length).fill(0));
  const totalSteps = assignmentJSON?.length;
  const [getSocket, setSocket] = useState(null);

  const handleNext = async (answeredQuestion) => {
    const existingIndex = answeredList.findIndex(
      (question) => question.question_id === answeredQuestion.question_id
    );

    let updatedList;
    if (existingIndex !== -1) {
      updatedList = answeredList.map((question, index) =>
        index === existingIndex ? answeredQuestion : question
      );
    } else {
      updatedList = [...answeredList, answeredQuestion];
    }

    setAnsweredList(updatedList);
    localStorage.setItem(`sAPDtls_AssignmentId${assignment_id}${token}`, assignment_id);
    localStorage.setItem(
      `studentAssignmentProgressDtls${assignment_id}${token}`,
      JSON.stringify(updatedList)
    );
    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
      localStorage.setItem(
        `studentAssignmentProgressCurrentStep${assignment_id}${token}`,
        currentStep + 1
      );
      setStartIndex(startIndex + 1);
      localStorage.setItem(
        `studentAssignmentProgressCurrentStepForQuestion${assignment_id}${token}`,
        startIndex
      );
      // localStorage.setItem(
      //   `studentAssignmentProgressFinalStep${assignment_id}`,
      //   false
      // );
    } else {
      setShowPopup(true);
      // localStorage.setItem(
      //   `studentAssignmentProgressFinalStep${assignment_id}`,
      //   true
      // );
      setassignmentClockTimer((prevIsRunning) => !prevIsRunning);
    }
    // handleSubmitAssignment(answeredQuestion);
  };

  const socket = useWebSocketConnection(
    `${wsURL}//ws/assignment_submission/${parseInt(studentId)}/`
  );
  const { isLoadingAssignment, error, submitAssignment } = useSubmitAssignment(
    socket
  );
  useEffect(() => {
    const initializeWebSocket = () => {
      if (socket) {
        let reconnectInterval = 1000; // Initial reconnect interval in ms
        const maxReconnectInterval = 30000; // Max reconnect interval
        let reconnectAttempts = 0; // Number of reconnect attempts
        const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
        const maxReconnectAttempts = 10; // Maximum number of reconnect attempts

        //  Event handler for when the WebSocket connection is established
        socket.onopen = () => {
          // console.log("WebSocket connection opened");
          reconnectAttempts = 0; // Reset reconnect attempts on successful connection
          reconnectInterval = 1000; // Reset reconnect interval
        };

        socket.onmessage = handleWebSocketMessage;

        socket.onclose = () => {
          if (reconnectAttempts < maxReconnectAttempts) {
            setTimeout(() => {
              reconnectAttempts++;
              socket.close(); // Make sure to close old sockets if not yet closed
              initializeWebSocket();
              reconnectInterval = Math.min(
                reconnectInterval * reconnectDecay,
                maxReconnectInterval
              );
            }, reconnectInterval);
          } else {
            // console.log("WebSocket connection closed");
            // console.log(
            //   "Max reconnect attempts reached, will not attempt further reconnects."
            // );
          }
        };

        socket.onerror = (event) => {
          setIsLoading(false);
          // Handle WebSocket errors
          console.error("WebSocket error observed:", event.message);
        };
      }
    };
    initializeWebSocket();
  }, [socket]);
  // useEffect(() => {
  //   if (socket) {
  //     socket.onmessage = handleWebSocketMessage;

  //     socket.onerror = (event) => {
  //       setIsLoading(false);
  //       // Handle WebSocket errors
  //       console.error("WebSocket error observed:", event.message);
  //     };
  //   }
  // }, [socket]);
  // useEffect(() => {
  //   if (studentId) {
  //     const newWebSocketURL = `${wsURL}//ws/assignment_submission/${parseInt(studentId)}/`
  //     let reconnectInterval = 1000; // Initial reconnect interval in ms
  //     const maxReconnectInterval = 30000; // Max reconnect interval
  //     let reconnectAttempts = 0; // Number of reconnect attempts
  //     const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
  //     const maxReconnectAttempts = 3; // Maximum number of reconnect attempts

  //     let socket = new WebSocket(newWebSocketURL);
  //     setSocket(socket);

  //       // Event handler for when the WebSocket connection is established
  //       socket.onopen = () => {
  //         console.log("WebSocket connection opened");
  //         reconnectAttempts = 0; // Reset reconnect attempts on successful connection
  //         reconnectInterval = 1000; // Reset reconnect interval
  //       };

  //     socket.onmessage = handleWebSocketMessage;

  //     socket.onclose = () => {
  //       console.log("WebSocket connection closed");
  //       if (reconnectAttempts < maxReconnectAttempts) {
  //         setTimeout(() => {
  //           reconnectAttempts++;
  //           socket.close(); // Make sure to close old sockets if not yet closed
  //           socket = new WebSocket(newWebSocketURL); // Create a new socket and attempt to connect
  //           reconnectInterval = Math.min(reconnectInterval * reconnectDecay, maxReconnectInterval);
  //         }, reconnectInterval);
  //       } else {
  //         console.log("Max reconnect attempts reached, will not attempt further reconnects.");
  //       }
  //     };

  //     socket.onerror = (event) => {
  //       setIsLoading(false);
  //       // Handle WebSocket errors
  //       console.error("WebSocket error observed:", event.message);
  //     };
  //   }
  // }, [socket]);
  // useEffect(() => {
  //   if (studentId) {
  //     const newWebSocketURL = `${wsURL}//ws/assignment_submission/${parseInt(studentId)}/`;
  //     let reconnectInterval = 1000; // Initial reconnect interval in ms
  //     const maxReconnectInterval = 30000; // Max reconnect interval
  //     let reconnectAttempts = 0; // Number of reconnect attempts
  //     const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
  //     const maxReconnectAttempts = 3; // Maximum number of reconnect attempts

  //     const initializeWebSocket = () => {
  //       const ws = new WebSocket(newWebSocketURL);
  //       ws.onopen = () => {
  //         console.log("WebSocket connection opened");
  //         reconnectAttempts = 0; // Reset reconnect attempts on successful connection
  //         reconnectInterval = 1000; // Reset reconnect interval
  //       };

  //       ws.onmessage = handleWebSocketMessage;

  //       ws.onclose = () => {
  //         console.log("WebSocket connection closed");
  //         if (reconnectAttempts < maxReconnectAttempts) {
  //           setTimeout(() => {
  //             reconnectAttempts++;
  //             initializeWebSocket(); // Reinitialize WebSocket
  //             reconnectInterval = Math.min(reconnectInterval * reconnectDecay, maxReconnectInterval);
  //           }, reconnectInterval);
  //         } else {
  //           console.log("Max reconnect attempts reached, will not attempt further reconnects.");
  //         }
  //       };

  //       ws.onerror = (event) => {
  //         setIsLoading(false);
  //         console.error("WebSocket error observed:", event);
  //       };

  //       setSocket(ws);
  //     };

  //     initializeWebSocket(); // Initialize WebSocket connection
  //   }
  // }, [studentId]);

  const handleSubmitAssignment = async (questionAnswers) => {
    setIsTimerPaused(true);
    setIsLoading(true); // Start loading when submitting
    const payload = {
      student_id: parseInt(studentId),
      assignment_id: assignment?.assignment_id,
      rubric_id: assignment?.rubric_id ?? null,
      questions_answers: questionAnswers ?? answeredList,
      // last_question: isLastQuestion
    };

    // console.log('Sending payload:', payload);
    try {
      submitAssignment(payload);
    } catch (error) {
      setIsLoading(false);
      console.error("WebSocket error", error);
    }
  };

  const createAssignmentStatus = async (assignment_id) => {
    const payload = {
      student_id: globalState.student_details.data.student_id,
      assignment_id: assignment_id,
      status: 3,
      completed_name: assignment.name,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    await api
      .post(`assignments/create-assignment-status/`, payload, {
        headers,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          localStorage.setItem("assignmentStatusId", response?.data?.data?.id);
        }
      })
      .catch(() => {});
  };

  const updateAssignmentStatus = async (status_id, score) => {
    const payload = {
      status: 1,
      score: score,
      completed_at: new Date().toISOString(),
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    await api
      .put(`assignments/update-assignment-status/${status_id}/`, payload, {
        headers,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          localStorage.removeItem("assignmentStatusId");
        }
      })
      .catch(() => {});
  };

  const handleWebSocketMessage = async (event) => {
    // console.log('Received raw WebSocket message:', event.data); // Log the raw message
    try {
      const data = JSON.parse(event.data);

      // console.log('Parsed WebSocket message:', data); // Log the parsed data

      // if (data?.response.last_question && data?.response.evaluation_complete) {
      setSubmissiondtls(data);
      setIsLoading(false); // Stop loading when last question is processed
      setShowPopup(false);
      setcongratulationMessgae(true);
      await getAssignment(lesson_id);
      // }
    } catch (error) {
      setIsLoading(false);
      console.error("WebSocket message error:", error);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setStartIndex(startIndex - 1);
      setIsTimerPaused(false);
    }
  };

  const handleTimeUp = () => {
    // Handle timer up
  };

  const isLastQuestion = assignmentJSON?.length === currentStep + 1;

  const showScore = () => {
    setcongratulationMessgae(false);
  };

  const showDetailedResult = () => {
    setAssignmentIndex(0);
    setdetailedResult(!detailedResult);
    setshowScorePopup(false);
  };

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [showStudentLessonPreview, setShowStudentLessonPreview] = useState(
    isSwitchOn
  );

  useEffect(() => {
    setShowStudentLessonPreview(isSwitchOn);
  }, [isSwitchOn]);

  const handleSwitchToggle = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const getStepNumbers = () => {
    const steps = [];
    const currentIndexVal = currentStep + 1;
    const middleRange = Math.floor(rangIndex / 2);
    let start = Math.max(1, currentIndexVal - middleRange);
    let end = Math.min(lastIndex, currentIndexVal + middleRange);

    if (currentIndexVal <= middleRange) {
      end = Math.min(rangIndex, lastIndex);
    } else if (currentIndexVal >= lastIndex - middleRange) {
      start = Math.max(1, lastIndex - rangIndex + 1);
    }

    if (start > 1) steps.push(1);
    if (start > 2) steps.push("...");

    for (let i = start; i <= end; i++) {
      steps.push(i);
    }

    if (end < lastIndex - 1) steps.push("...");
    if (end < lastIndex) steps.push(lastIndex);

    return steps;
  };

  return (
    <>
      <div id="AssingmentMCQs-section">
        <div className="AssingmentMCQs-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="AssingmentMCQs-header-main-contaiiner">
                  <div
                    className="view-course-Details-back-btn-folder"
                    id="AssingmentMCQs-back-btn"
                  >
                    <BackButton buttonName={assignment?.name} />
                  </div>
                  {isLessonPreviewEnabled && (
                    <div className="AssingmentMCQs-show-lesson-preview">
                      <h2>Show Lesson Preview</h2>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isSwitchOn}
                          onChange={handleSwitchToggle} // Use onChange event
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  )}
                </div>
                {isAssignmentLoading ? (
                  <>
                    <p style={{ textAlign: "center" }}>
                      Loading Assignment... Please wait!
                    </p>
                    <Grid
                      height={"50vh"}
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid item xs={12}>
                        <CircularProgress size={80} />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <div className="AssingmentMCQs-container">
                    {showStudentLessonPreview && (
                      <StudentLessonPreview lesson={lesson} />
                    )}
                    <div
                      className={`AssingmentMCQs-right-section ${
                        showStudentLessonPreview ? "" : "full-width"
                      }`}
                    >
                      <AssignmentTimer
                        duration={(assignment?.duration_minutes ?? 0) * 60}
                        allotted_time={assignment?.duration_minutes}
                        onTimeUp={handleTimeUp}
                        isTimerPaused={isTimerPaused}
                        timers={timers}
                        setTimers={setTimers}
                        currentStep={currentStep}
                        isAssignmentLoading={isAssignmentLoading}
                        assignmentClockTimer={assignmentClockTimer}
                      />
                      <div className="AssingmentMCQs-main-wrapper">
                        <div className="step-container">
                        {getStepNumbers().map((step, index) => {
                            const isAnswered = answeredList.some(
                              (question) => question.question_id === step
                            );
                            const stepchecker = currentStep + 1 === step ? true : false;
                            return (
                              <div
                                key={index}
                                className={`step ${
                                  stepchecker ? "active" : ""
                                } ${isAnswered && !stepchecker ? "answered" : ""}`}
                              >
                                {step}
                              </div>
                            );
                          })}
                        </div>
                        {assignmentJSON?.map((assign, index) => (
                          <div key={index}>
                            <Question
                              duration={
                                (assignment?.duration_minutes ?? 0) * 60
                              }
                              assignment={assign}
                              index={index}
                              currentStep={currentStep}
                              handleBack={handleBack}
                              handleNext={handleNext}
                              isLast={isLastQuestion}
                              lesson={lesson}
                              course_id={course_id}
                              lesson_id={lesson_id}
                              assignment_id={assignment_id}
                              timers={timers}
                              virtualAssistant={
                                isVirtualAssistantPreviewEnabled
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {congratulationMessgae && (
          <StudentAssignmentScore
            showScore={showScore}
            getSubmissiondtls={getSubmissiondtls}
            onclick={() => navigate(-2)}
            title={"Go to Course"}
            isCurrentAssignment={true}
            onclick2={showDetailedResult}
            title2={"View Detailed Result"}
          />
        )}
        {showSubmit && (
          <form
            action=""
            className="delete-course-form"
            id="delete-AssingmentMCQs-form"
          >
            <div className="delete-course-form-container">
              {isLoading ? (
                <>
                  <p>
                    Submission is in progress. Please keep this browser tab open
                    to ensure your submission completes successfully.
                  </p>
                  <Grid
                    height={"50vh"}
                    xs={12}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CircularProgress size={80} />
                  </Grid>
                  {/* <button style={{marginTop:"25px"}}
                    onClick={() => navigate(-2)}
                    type="button"
                    className="View-Course"
                  >
                    Go to Course
                  </button> */}
                </>
              ) : (
                <>
                  <h2>Are you sure you want to submit the assignment?</h2>
                  <div className="delete-course-form-btn-folder">
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={() => {
                        setIsTimerPaused(false);
                        setShowPopup(false);
                        setassignmentClockTimer(true);
                      }}
                      className="not-delete-btn"
                    >
                      Back
                    </button>
                    {isLoading ? (
                      <div className="loading-spinner-course" />
                    ) : (
                      <button
                        onClick={() => {
                          handleSubmitAssignment();
                     
                          // setcongratulationMessgae(true);
                          // setShowPopup(false);
                        }}
                        type="button"
                        className="delete-btn"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </form>
        )}
      </div>

      {detailedResult && (
        <div className="AssignmentViewProgress-mock-test-wrapper">
          <div className="AssignmentViewProgress-mock-test-container">
            {
              <QuestionNew
                assignment={
                  assignment?.submission?.student_answers_json[assignmentIndex]
                }
                ai_evaluation_json={assignment?.submission?.ai_evaluation_json}
                index={assignmentIndex}
                currentStep={assignmentIndex + 1}
                totalSteps={assignment?.submission?.student_answers_json.length}
                handleNext={() =>
                  assignmentIndex <
                  assignment?.submission?.student_answers_json.length - 1
                    ? setAssignmentIndex((p) => p + 1)
                    : null
                }
                handleBack={() => setAssignmentIndex((p) => p - 1)}
                isLast={
                  assignmentIndex ===
                  assignment?.submission?.student_answers_json.length - 1
                }
                isFirst={assignmentIndex === 0}
                close={
                  <span
                    onClick={showDetailedResult}
                    className="AssignmentViewProgress-mock-test-close-btn"
                  >
                    <i className="fa-solid fa-x"></i>
                  </span>
                }
                questions={getSubmissiondtls?.questions}
              />
            }
          </div>
        </div>
      )}
    </>
  );
};

export default StudentAssignmentPreview;
