import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Ring } from "@uiball/loaders";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  check_icno_new,
  image1,
  srds_admin
} from "../../../assets/images/images";
import ForgetPassword from "../../../components/ForgetPassword"; // import the ForgotPassword component
import { useAuth } from "../../../features/auth/AuthContext";
import "../../../index.css";
import { profileLogin, resetProfileLogin } from "../../../state/actions/profileLoginAction";
import {
  isRequired,
  isValidEmail,
  isValidPassword,
} from "../../../utils/validator";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ChangePassword from "../../../components/ChangePassword/ChangePassword";

const AdminLogin = () => {
  const { login } = useAuth();
  const location = useLocation();
  const roleFromState = location.state?.role || "";
  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, setchangePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Admin");
  const navigate = useNavigate();
  const redirectPath = localStorage.getItem("redirectAfterSignup");
  const dispatch = useDispatch();
  const profilelogin = useSelector((state) => state.profilelogin);
  const firstlogin = useSelector((state) => state.firstlogin);
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  const handleSignUpClick = (event) => {
    event.preventDefault();
    navigate("/admin-signup", { state: { role: "Admin" } });
  };

  const handleChange = (e) => {
    // Check if Enter key is pressed and ignore tab switch
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior (e.g., form submission)
      handleSubmit(e); // Call the submit function
      return;
    }
    const { name, value } = e.target;
    let error = "";
    if (name === "email") {
      error = isRequired(value, "email") || isValidEmail(value);
    } else if (name === "password") {
      error = isRequired(value, "password") || isValidPassword(value);
    }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};

    errors.email =
      isRequired(formData.email, "email") || isValidEmail(formData.email);
    errors.password =
      isRequired(formData.password, "password") ||
      isValidPassword(formData.password);

    setFormErrors(errors);

    if (!Object.values(errors).some((error) => error)) {
      await loginUser(formData.email.toLocaleLowerCase(), formData.password);
    }
  };

  const loginUser = () => {
    const req = {
      email: formData.email.toLocaleLowerCase(),
      password: formData.password,
    };

    dispatch(profileLogin("schooladmin", req));
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [completeModal, setcompleteModal] = useState(false);
  const customModalStyleNew = {
    modal: {
      background: "#F5F9FF",
      width: "650px",
      borderRadius: "10px",
    },
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default Enter key behavior

      handleSubmit(e);
    }
  };

  const onChangePasswordClose = () => {
    setchangePassword(false);
    dispatch(resetProfileLogin());
  };

  useEffect(() => {
    const failedErrorMessage =
      "Oops, something went wrong. Please try again later.";
    if (profilelogin?.first_login) {
      setchangePassword(!changePassword);
    }
    else if (profilelogin?.token?.length) {
      setchangePassword(false);
      login(profilelogin?.token, activeTab, formData.email || "", profilelogin.school_id);
      toast.success("Login successful.", {
        position: toast.POSITION.TOP_CENTER,
      });
      if (redirectPath !== null && redirectPath !== "") {
        navigate(redirectPath);
        localStorage.removeItem("redirectAfterSignup");
        return;
      }
    } else if (profilelogin?.error?.length) {
      setchangePassword(false);
      //const errorMessage = formatApiErrors(profilelogin?.error);
      toast.error(profilelogin?.error || failedErrorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(resetProfileLogin());
    }
  }, [profilelogin]);

  useEffect(() => {
    return () => {
      dispatch(resetProfileLogin());
    };
  }, []);

  return (
    <div className="login" onKeyPress={handleKeyPress}>
      <div className="login-wrapper">
        <form
          action=""
          className="signup-right-section"
          onSubmit={handleSubmit}
        >
          <div className="Register-container admin-login">
            <span className="welcome-text">Welcome to</span>
            <img src={image1} width={200} alt="" />
            <div className="signup-form">
              <p className="email-or-phoneNumber-title">Email</p>
              <div className="signup-input edit-email">
                <FormControl
                  margin="normal"
                  fullWidth
                  error={!!formErrors.email}
                >
                  <TextField
                    name="email"
                    size="small"
                    value={formData.email}
                    error={!!formErrors.email}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        borderRadius: "35px",
                        backgroundColor: "#F5F5F5",
                        padding: "0px",
                        fontSize: "15px",
                      },
                    }}
                  />
                  <FormHelperText>{formErrors.email}</FormHelperText>
                </FormControl>
              </div>
              <p className="email-or-phoneNumber-title">Password</p>
              <div className="signup-input hide-show-password">
                <FormControl
                  margin="normal"
                  fullWidth
                  error={!!formErrors.password}
                >
                  <TextField
                    size="small"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    error={!!formErrors.password}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPassword(
                                (prevShowPassword) => !prevShowPassword
                              )
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: {
                        borderRadius: "35px",
                        backgroundColor: "#F5F5F5",
                      },
                    }}
                  />
                  <FormHelperText>
                    <ul>
                      {formErrors.password.split(".").map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </FormHelperText>
                </FormControl>
              </div>
            </div>

            <div>
              <div className="forgot-password-folder">
                <div
                  className="forget-password-btn"
                  onClick={() => setIsModalOpen(true)}
                >
                  Forget Password?
                </div>
              </div>

              {isModalOpen && (
                <ForgetPassword
                  isModalOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                />
              )}

              {completeModal && (
                <Modal
                  open={completeModal}
                  onClose={() => setcompleteModal(false)}
                  center
                  styles={customModalStyleNew}
                >
                  <div className="passwor_update_succ">
                    <img src={check_icno_new} alt="" />
                    <p>Your Password has been updated successfully</p>
                  </div>
                </Modal>
              )}
            </div>

            <div className="signup-btn">
              {loading ? (
                <Ring />
              ) : (
                <button type="submit" disabled={loading}>
                  Login
                </button>
              )}
            </div>
            {/* <div className="Already-have-an-accout-folder">
              <span>
                Don't have an account?{" "}
                <a href="#" onClick={handleSignUpClick}>
                  {" "}
                  Register
                </a>
              </span>
            </div> */}
          </div>
        </form>
        <div className="signup-left-section">
          <div className="signup-image">
            <img
              src={
                srds_admin
              }
              alt="Background"
            />
          </div>
        </div>
      </div>
      {changePassword && (
        <ChangePassword
          changePassword={changePassword}
          onChangePasswordClose={onChangePasswordClose}
          userId={profilelogin?.user_id}
        />
      )}
    </div>
  );
};

export default AdminLogin;