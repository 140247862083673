import React from "react";
import {
  Privacy_Policy_title,
  Privacy_Policy_image,
  half_circle_bg_image,
  half_circle_bg_image2,
  book_logo3,
  Proschool_logo,
} from "../../assets/images/images";
import { NavLink, useNavigate } from "react-router-dom";
import HomeNavbar from "../home/components/HomeNavbar";
import { useDispatch, useSelector } from 'react-redux';

const PrivacyPolicy = () => {

  const navigate = useNavigate();
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior

    // Navigate to the home page
    navigate("/");

    // Use setTimeout to delay the scroll to the specific section
    setTimeout(() => {
      const element = document.getElementById("revolution_sec_new");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the timeout as needed
  };

  return (
    <>
      <HomeNavbar />
      <div className="Terms-and-Conditions-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Terms-and-Conditions-wrapper">
                <div className="Privacy_Policy_title">
                  <img src={Privacy_Policy_title} />
                </div>
                <div className="Terms-and-Conditions-image">
                  <img src={Privacy_Policy_image} />
                </div>
                <div className="Terms-and-Cond itions-list-wrapper">
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>1. Introduction</h2>
                    <p>
                      Welcome to SRDS.ai, an innovative Learning Management System (LMS) platform. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect the personal information of users on our platform. By accessing or using our services, you signify your acceptance of the terms and provide us with your consent to the practices outlined in this policy. Our commitment to transparency means we strive to inform our users about how their data is handled. We encourage you to read this Privacy Policy carefully. By continuing to use our services, you acknowledge that you have read, understood, and agreed to this Privacy Policy.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>2. Information Collected</h2>
                    <ol>
                      <li>
                        <strong>Information You Provide:</strong>
                        <p>When you use our services, you may provide us with personal information such as your name, contact information, educational background, and other relevant data. This may include, but is not limited to, academic qualifications, enrollment details, feedback, coursework submissions, and communications with instructors. We access files and associated metadata (such as file names, creation dates, and folder structures) stored in your Google Drive to enable file management, sharing, and submission functionalities within the SRDS platform.</p>
                      </li>
                      <li>
                        <strong>Information We Collect Automatically:</strong>
                        <p>We may automatically collect certain information about your use of our services, including your IP address, device information, browser type, access times, and usage patterns . This helps us to understand how you interact with our platform and improve your user experience.</p>
                      </li>
                      <li>
                        <strong>Cookies and Similar Technologies:</strong>
                        <p>We may use cookies and similar technologies to collect information about your interactions with our services and enhance your user experience. These technologies may store data for varying periods depending on their purpose, such as remembering your login preferences or tracking your progress within the platform.</p>
                      </li>
                    </ol>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>3. HOW WE USE YOUR INFORMATION</h2>
                    <ol>
                      <li>
                        <strong>Personalized Learning Experience:</strong>
                        <p>We use the information we collect to provide, maintain, and improve our services, including offering personalized learning paths and resources tailored to your needs. Our AI algorithms analyze your educational history, preferences, and performance data to suggest relevant courses, learning materials, and study tips.</p>
                      </li>
                      <li>
                        <strong>Communication:</strong>
                        <p>We may use your information to communicate with you about our services, including updates, educational resources, and administrative messages. Notifications about new courses or features are sent periodically, while important administrative messages are delivered as necessary.</p>
                      </li>
                      <li>
                        <strong>File Upload and Storage</strong>
                        <p>We may use your information for research and analytics purposes to understand trends in educational data, evaluate learning outcomes, and enhance our AI algorithms. This helps us to continuously improve our platform and offer a better learning experience. Teachers can share files with students by setting specific permissions (e.g., view-only, edit), ensuring controlled access to educational content. Students can select files from their Google Drive to submit as assignments, making the process more accessible. Teachers can organize files within Google Drive into folders for different courses or assignments, aiding in the efficient management of educational resources.</p>
                      </li>
                      <li>
                        <strong>Research and Analytics</strong>
                        <p>We may use your information for research and analytics purposes to understand trends in educational data, evaluate learning outcomes, and enhance our AI algorithms. This helps us to continuously improve our platform and offer a better learning experience.</p>
                      </li>
                    </ol>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>4. HOW WE SHARE YOUR INFORMATION</h2>
                    <ol>
                      <li>
                        <strong>Third-Party Service Providers:</strong>
                        <p>We may share your information with third-party service providers who assist us in delivering our services, such as cloud hosting providers, analytics platforms, and educational content partners. These providers are contractually obligated to protect your information and use it only for the services they provide to us. SRDS.ai also utilizes the services of Google, including Google Drive, to enhance the delivery and security of our educational services. By using our platform, you acknowledge and agree that your data may be processed and stored on Google’s servers.
                          When you interact with certain features of our platform, such as saving documents or sharing educational materials, your information may be stored on Google Drive. This data storage is subject to Google's privacy practices, which include the following:
                        </p>
                      </li>
                      <li>
                        <strong>Educational Institutions and Partners:</strong>
                        <p>We may share your information with educational institutions or partners involved in delivering or enhancing the educational experience. These partners are required to protect your information and use it only for the agreed-upon purposes.</p>
                      </li>
                      <li>
                        <strong>Legal Requirements:</strong>
                        <p>We may disclose your information if required by law or legal process or if we believe that such disclosure is necessary to protect our rights, property, or safety, or the rights, property, or safety of others.</p>
                      </li>
                    </ol>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>
                      5. PURPOSE OF DATA COLLECTION
                    </h2>
                    <p>
                      At SRDS.ai, we collect data to provide a seamless, personalized, and enhanced educational experience for our users. Mentioned below is a detailed look at how we utilize the data collected:
                    </p>
                    <ol>
                      <li>
                        <strong>Personalized Learning Paths:</strong>
                        <p>We use data such as your educational background, course selections, and interaction history to tailor learning paths that suit your unique needs and goals. Our AI algorithms analyze this information to suggest courses, study materials, and learning strategies that optimize your educational journey.
                        </p>
                      </li>
                      <li>
                        <strong>Platform Enhancement:</strong>
                        <p>Data collected from user interactions helps us improve our Learning Management System by identifying areas for enhancement and ensuring that our platform remains user-friendly, efficient, and relevant to your educational needs.</p>
                      </li>
                      <li>
                        <strong>Communication and Support:</strong>
                        <p>We use your contact information to provide timely updates about new features, course offerings, and important notifications. This ensures you stay informed and receive support when needed, enhancing your overall experience on our platform</p>
                      </li>
                      <li>
                        <strong>User Experience Optimization:</strong>
                        <p>We gather data on how users navigate and interact with the platform to enhance user experience. This includes optimizing site performance, simplifying navigation, and providing a more intuitive interface.</p>
                      </li>
                    </ol>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>
                      6. DATA SECURITY

                    </h2>
                    <p>
                      SRDS.ai prioritizes the protection of your personal information with rigorous security measures. We use encryption to secure data in transit and at rest, ensuring your information remains confidential. Access to data is restricted to authorized personnel who adhere to strict protocols. Regular security audits and assessments help us identify and address potential vulnerabilities. In the event of a security incident, we have a response plan to resolve issues promptly and notify affected users if necessary. We encourage you to use strong passwords and keep your login information private.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>7. EU GENERAL DATA PROTECTION REGULATION (GDPR) COMPLIANCE</h2>
                    <p>
                      SRDS.ai adheres to the EU General Data Protection Regulation (GDPR) to protect user privacy. We process personal data lawfully and transparently, ensuring users have rights to access, correct, and delete their data. Our security measures include encryption and access controls, and we ensure data transfers outside the EEA have appropriate safeguards. Our Data Protection Officer oversees compliance and addresses concerns.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>8. CHILDREN’S ONLINE PRIVACY PROTECTION ACT (COPPA) COMPLIANCE</h2>
                    <p>
                      We obtain Verifiable Parental Consent for children under the age of 13 Years intending to use our services and Verifiable Parental Consent from Educational Institutions / Teachers acting as the parents’ agents if the targeted audience intended to use our services are 13 Years and below.
                      Please read our Collection, Use and Disclosure Practices mentioned below.

                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>9. DATA SHARING</h2>
                    <p>
                      All content provided on SRDS.ai, including courses, lessons, assessments, and any other educational materials, is the intellectual property of the respective creators and SRDS.ai. Users are strictly prohibited from reproducing, distributing, modifying, or using this content for any purpose outside the platform without explicit written permission from the content creators or SRDS.ai. Any unauthorized use of the content may result in termination of access to our services and could lead to legal action.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>10. AI CHATBOT INTERACTIONS</h2>
                    <p>
                      SRDS.ai offers personalized AI chatbot interactions for both students and teachers to enhance communication and support the learning experience. These interactions are monitored to improve the effectiveness of our educational services and to tailor responses to individual needs. However, the information collected through these interactions is not used for any purposes other than enhancing the learning experience and platform functionality. We prioritize your privacy and ensure that any data obtained from chatbot interactions is handled in accordance with our strict privacy and security measures.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>11. DATA RETENTION</h2>
                    <p>
                      We retain user data for as long as the user’s account is active. If a user wishes to delete their account and associated data, they can request deletion by contacting us. Upon receiving a deletion request, we will promptly remove the user's data, subject to any legal obligations to retain certain information. We are committed to ensuring that your data is only kept for as long as necessary to provide our services effectively.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>12. CHANGES TO PRIVACY POLICY</h2>
                    <p>
                      SRDS.ai may update or modify this Privacy Policy at any time to reflect changes in our practices or to comply with legal requirements. The updated policy will be effective immediately upon posting on our website. We encourage you to periodically review this Privacy Policy to stay informed about how we protect your information.
                      Your continued use of our services after any changes to the Privacy Policy will constitute your acknowledgment and acceptance of those changes. If you do not agree with the updated policy, you should discontinue using our services.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>13. CONTACT INFORMATION</h2>
                    <p>
                      For any queries or a detailed walkthrough of our platform, please contact us at support@arcitech.ai. We are here to assist you with any questions or concerns you may have about our services or this Privacy Policy.                    </p>
                  </div>
                </div>
                <div className="half-circle-bg-image">
                  <img src={half_circle_bg_image} />
                </div>
                <div className="half-circle-bg-image2">
                  <img src={half_circle_bg_image2} />
                </div>
                <div className="book-logo3">
                  <img src={book_logo3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section" id="footer-section">
        <div className="footer-wraper">
          <div className="footer-link-folder">
            <p>Saddle River Day School</p>
            {/* <div className="proschool-logo">
              <img src={subadmin_details ? subadmin_details?.school_image : Proschool_logo} />
            </div> */}
            {/* <div className="footer-Join-our-newsletter-folder">
              <p>Join our newsletter</p>
              <div className="footer-Join-our-newsletter-search-folder">
                <input type="email" placeholder="Your email"></input>
                <button>Subscribe</button>
              </div>
            </div> */}
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Quick Links</h2>
            <ul className="footer-Quick-Links">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about">About Us</NavLink>
              </li>
              {/* <li>
                <a href="revolution_sec_new" onClick={handleClick}>
                  Features
                </a>
              </li>
              <li>
                <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
              </li> */}
              <li>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Contact</h2>
            <div className="footer-contact">
              <div className="footer-contact-folder">

                <a><i class="fa-solid fa-phone"></i></a>
                <span><a href="tel:+1 201-327-4050">+1 201-327-4050</a></span>
              </div>
              <div className="footer-contact-folder">
                <a><i class="fa-solid fa-envelope"></i></a>
                <a href="mailto: noreply@saddleriverday.org">kmerges@saddleriverday.org</a>
              </div>
              <div className="footer-contact-folder">
                <a><i class="fa-solid fa-location-dot"></i></a>
                <a href="https://www.google.com/maps/place/Saddle+River+Day+School/@41.0307394,-74.0880125,17z/data=!3m1!4b1!4m6!3m5!1s0x89c2e436c9e7ef3b:0xf060c34a65b01898!8m2!3d41.0307394!4d-74.0854376!16zL20vMGRkYzZ5?entry=ttu" target="_blank">Saddle River Day School, 147 Chestnut Ridge Road, Saddle River, NJ 07458​​.</a>
              </div>
            </div>
          </div>
          <div className='footer-get-in-touch-folder'>
            <span>Get in touch</span>
            <div className='footer-social-link-folder'>
              <a href='https://www.facebook.com/saddleriverdayschool' target="_blank"><i className="fa-brands fa-facebook"></i></a>
              <a href='https://twitter.com/srDSrebels' target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
              <a href='https://www.instagram.com/saddleriverday/' target="_blank"><i className="fa-brands fa-instagram"></i></a>
              <a href='https://vimeo.com/srds' target="_blank"><i class="fa-brands fa-vimeo-v"></i></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
