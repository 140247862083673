import StudentsList from "./StudentsList";
import React, { useEffect, useState } from "react";
import { search, srot } from "../../assets/images/images";
import "./StudentsList.css";
import DownlodeFile from "../downlodeFile/DownlodeFile";
import {
  fetchAllSchoolData,
  resetAllTeacherBySchool,
} from "../../state/actions/getAllTeacherBySchoolAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../features/auth/AuthContext";
import { useParams } from "react-router-dom";
import PaginationView from "../../components/Pagination/PaginationView";
import SearchComponent from "../SearchComponent/SearchComponent";
import Helper from "../../utils/Helper";
import SortPopup from "../../pages/customSort/SortPopup";
import { addToHistory } from '../../state/reducers/historyReducer';
import { CircularProgress, Grid } from "@mui/material";

const Students = () => {
  // const { school_id } = useParams(); // Call it unconditionally at the beginning
  const { token } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOption, setsearchOption] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [grade, setgrade] = useState("");
  const [selectedMonth, setselectedMonth] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const itemsPerPage = 12;
  const inial_value = (currentPage - 1) * itemsPerPage;
  const {
    teachers,
    students,
    count,
    next,
    previous,
    totalPages,
    error,
    isLoading
  } = useSelector((state) => state.fetchSchoolData);
  const globalState = useSelector((state) => state.globalState);
  useEffect(() => {
    getAllteacherDetails();
    window.scrollTo(0, 0);
    // return () => {
    //   dispatch(resetAllTeacherBySchool());
    // };
  }, [currentPage, searchOption, sortOption, grade, selectedMonth]);

  useEffect(() => {
    return () => {
      dispatch(resetAllTeacherBySchool());
    };
  }, []); // Unconditionally called at the beginning

  useEffect(() => {
    dispatch(addToHistory('/dashboard/students'))
  }, [])

  const getAllteacherDetails = () => {
    const type = "Student"; // Define the type variable
    const school_id = globalState.school_id;
    dispatch(fetchAllSchoolData(currentPage, school_id, token, type, globalState.role, searchOption, "", sortOption, grade, selectedMonth));
  };

  const handleSearch = (e) => {
    setsearchOption(e.target.value)
  }

  const handleSortOptionChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  return (
    <>
      <h2 className="dashboard-name-title">Students</h2>
      <div className="header_inner_wrapper">
        <div className="Dashboard-Review-Card-header-seacrh-folder">
          <SearchComponent
            search={search}
            searchPlaceholder="Search"
            searchValue="search"
            onchange={handleSearch}
            name="searchOption"
            value={searchOption}
          />
        </div>
        <div className="header-filter-container">
          <div className="filter_inner_folder">
            <p>Grade:</p>
            <select id="grade" name="grade"
              value={grade || ""}
              onChange={(e) => setgrade(e.target.value)}
            >
              <option value="">All</option>
              {Object.keys(globalState.grade_details.grade).map((key) => (
                <option
                  key={`gradedetails${key}`}
                  value={key}
                >
                  {globalState.grade_details.grade[key]}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="filter_inner_folder">
            <p>Month:</p>
            <select id="month" name="month"
              value={selectedMonth}
              onChange={(e) => setselectedMonth(e.target.value)}>
              <option value="">All</option>
              {Helper.months.map((month, i) => (
                <option
                  key={i}
                  value={month.month}
                >
                  {month.name}
                </option>
              ))}
            </select>
          </div> */}
          <div className="student-sortby-popup-wrapper">
            <div className="lessonviewprogress-filder-tab student-shortby-tab"
              onClick={() => {
                setIsVisible(!isVisible)
                // if (!isVisible) {
                //   setIsVisible(true);
                //   setIsVisiblefillter(false);
                // } else {
                //   setIsVisible(false);
                // }
              }}
            >
              <span>
                {/* <img src={srot}/> */}
                <i class="fa-solid fa-arrow-down-wide-short"></i>
              </span>
              <p>Sort</p>
              {/* <div className="student-shortby-popup">
                <SortPopup
                  isVisible={isVisible}
                  handleApplyClick={() => { }}
                  sortOption={sortOption}
                  onSortOptionChange={handleSortOptionChange}
                />
              </div> */}
            </div>
            <div className="student-shortby-popup">
              {isVisible && <SortPopup
                isVisible={isVisible}
                handleApplyClick={() => setIsVisible(!isVisible)}
                sortOption={sortOption}
                onSortOptionChange={handleSortOptionChange}
              />}
            </div>
          </div>
        </div>
        {/* <DownlodeFile
            Word_File="Excel sheet (.xlsx)"
            pdf_File="" // to hide btn send blank string"
            Or= "" // to show or
          /> */}
      </div>
      {totalPages > 1 && (
        <div className="pagination_top_wrapper">
          <PaginationView
            totalPages={totalPages}
            next={next}
            previous={previous}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
      <div className="Student_Review_Table_Data_section">
        <table className="table-container" id="Student_Review_Table_Data">
          <thead>
            <tr>
              {/* <th className="checkbox-cell header-cell">
                <input type="checkbox" />
              </th> */}
              <th className="header-cell">Sr No</th>
              <th className="name-cell header-cell">Name</th>
              <th className="header-cell">Student ID</th>
              <th className="header-cell">Grade</th>
              {/* <th className="header-cell">School Name</th> */}
              <th className="header-cell">Email</th>
              <th className="header-cell">Details</th>
              <th className="header-cell">Enable/Disable</th>
            </tr>
          </thead>

            { isLoading ?

            <div style={{ display: "table-caption" }}>
              <Grid
                height={"50vh"}
                xs={12}
                container
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
              >
                <CircularProgress size={80} />
              </Grid>
            </div>
            : students && students?.length > 0 ? students?.map((student, index) => (
                <>
                <tbody>
                  <StudentsList key={student.student_id} data={student} index={index} getAllteacherDetails={getAllteacherDetails} inial_value={inial_value}/>
                </tbody>
                </>
              )) :
              <div style={{ display: "table-caption" }}>
              <p style={{ textAlign: "center", marginTop: "10px", margin: "0px auto" }}>No Students Found</p>
              </div>
            }

        </table>
        {/* {isLoading && 
          <Grid
            height={"50vh"}
            xs={12}
            container
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress size={80} />
          </Grid>
        }
        {!isLoading && students && students?.length && 
          <p style={{ textAlign: "center" , margin: "0px auto"}}>No Students Found</p>
        } */}
      </div>
      {totalPages > 1 && (
        <PaginationView
          totalPages={totalPages}
          next={next}
          previous={previous}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};

export default Students;
