import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GetFileStatus {
    coursefileStatus: any,
    uploadCourseFileProgress: string | null,
    isLoading: boolean;
    error: string | null;
}

const initialState: GetFileStatus = {
    coursefileStatus: {},
    uploadCourseFileProgress: null,
    isLoading: false,
    error: null,
};

const getCourseFileStatusSlice = createSlice({
    name: "getFileStatus",
    initialState,
    reducers: {
        getcoursefilestatus: (state: GetFileStatus, action: PayloadAction<GetFileStatus>) => {
            state.coursefileStatus = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setCourseFileUploadProgress: (state: GetFileStatus, action: PayloadAction<string>) => {
            state.uploadCourseFileProgress = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: GetFileStatus, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: GetFileStatus, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetgetcoursefilestatus: () => {
            return initialState;
        },
    },
});

export const {
    getcoursefilestatus,
    setCourseFileUploadProgress,
    setloading,
    seterror,
    resetgetcoursefilestatus,
} = getCourseFileStatusSlice.actions;

export default getCourseFileStatusSlice.reducer;
