import React, { useEffect, useState } from "react";
import LessonAndAssingmentTab from "./LessonAndAssingmentTab";
import BackButton from "../BackButton/BackButton";
import { CircularProgress, Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useLocation,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api/api";
import { useAuth } from "../../features/auth/AuthContext";
import { updateGlobalState } from "../../state/global/slice";

const LessonTab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const itemsPerPage = 12;
  const [selLession, setSelLession] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const onExpand = (i) => {
    setSelLession(selLession === i ? -1 : i);
  };
  const [lessonsDtls, setLessonsDtls] = useState();
  const inial_value = (currentPage - 1) * itemsPerPage;
  const [deleteCourse, setdeleteCourse] = useState(false);
  const [loading, setLoading] = useState(false);
  const globalState = useSelector((state) => state.globalState);
  const dispatch = useDispatch();
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const teacher_id =
  location.state?.teacher_id ??
  globalState.teacher_details?.data?.teacher_id ??
  "";
  const [lessonlisting, setlessonlisting] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const getLessonDtails = async (page) => {
    setLoading(true);
    document.body.style.overflow = "auto";
    try {
      const response = await api.get(`/auth/get-lesson-by-teacher-course/`, {
        params: {
          page: page,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });
      if (response.status === 200) {
        setLoading(false);
        setlessonlisting(response.data.data);
        setTotalPages(
          response.data.count >= 12
            ? Math.ceil(response.data.count / itemsPerPage)
            : Math.floor(response.data.count / itemsPerPage)
        );

        setNextUrl(response.data.next); // Update next URL
        setPrevUrl(response.data.previous); // Update previous URL
      } else {
        setLoading(false);
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLessonDtails(currentPage);
  }, [currentPage]);
  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };
  function formatDate(isoDate) {
    const date = new Date(isoDate);

    // Get individual date components
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // 'Jan', 'Feb', 'Mar' etc.
    const year = date.getFullYear();

    // Get the suffix for the day
    let suffix = "th";
    if (day % 10 === 1 && day !== 11) {
      suffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      suffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      suffix = "rd";
    }

    return `${day}${suffix} ${month}, ${year}`;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="view-course-Details-back-btn-folder">
              <BackButton buttonName={"Back"} />
              {/* <i
                className="fa-solid fa-arrow-left"
              ></i>
              <h2>Back</h2> */}
            </div>
            <div className="lesson-lsit-section">
              <h2 className="student-Active-Lessons">Lesson</h2>
              <div className="lesson-lsit-wrapper">
                {loading ? 
                    <Grid
                    height={"50vh"}
                    xs={12}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                      <CircularProgress size={80} />
                  </Grid> :
                lessonlisting.length > 0 ? (
                  lessonlisting.map((lesson, index) => (
                    <LessonAndAssingmentTab
                      type="Lesson"
                      titleHeader={inial_value + index}
                      title={lesson?.name}
                      Coursename={lesson?.course?.name}
                      start_date={formatDate(lesson.start_date)}
                      end_date={lesson.end_date != null ? formatDate(lesson.end_date) : '-'}
                      Progress_btn="Lesson Progress"
                      isAssignmentTab={false} // Add this line
                      course= {lesson?.course}
                      lesson={lesson}
                      assignment={lesson.assignment}
                      teacher_id={teacher_id}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No lesson found.</td>
                  </tr>
                )}
              </div>
            </div>
            <Grid xs={12} container justifyContent={"center"}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    hideNextButton={
                      nextUrl === null || currentPage === totalPages
                    } // Disable if no next URL and on the last page
                    hidePrevButton={prevUrl === null || currentPage === 1} // Hide if no previous URL and on the first page
                  />
                </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonTab;
