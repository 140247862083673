import FilteredTextarea from "../../../../components/FilteredInput/FilteredTextArea";

function AnswerInput({ value, onChange }) {
  return (
    <>
      <div className="AssingmentMCQs-mock-test-answer-container">
        <div className="options AssignmentMCQs-mock-test-answer-container">
          <div className="option AssignmentMCQs-mock-test-answer-folder">
            <textarea
              value={value}
              onChange={onChange}
              name="detailedAnswer"
              rows="12"
              placeholder="Type your answer here"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AnswerInput;
