import {
  Navigate,
  useLocation
} from "react-router-dom";
import { useAuth } from "../features/auth/AuthContext";

function PrivateRoute({ element, ...props }) {
  const { token } = useAuth();
  const location = useLocation();

  return token ? (
    element
  ) : (
    <Navigate
      to="/login"
      state={{ from: props.location, pathname: location?.pathname }}
    />
  );
}

export default PrivateRoute;