import StudentCourseCard from "../course/StudentCourseCard";
import imgs from "../../../../../../assets/images/imgs";
import { ReactSVG } from "react-svg";
import React, { useState } from "react";
import FilteredInput from "../../../../../../components/FilteredInput/FilteredInput";
import { CircularProgress, Grid } from "@mui/material";

function TabContent({ courses = [], isLoading }) {

  const [searchTerm, setSearchTerm] = useState('');

  // Event handler for search input changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter courses based on the search term
  const filteredCourses = courses.filter((course) =>
    course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    course.teacher_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <div className="studentDashboar-search-filter StudentCourseCard-lessons-search-filter">
        <input type="text" placeholder="Search courses" value={searchTerm}  onChange={handleSearchChange}  />
        <div className="serach-input-icon">
        <ReactSVG src={imgs.svg.search} alt="" />
        </div>
      </div>
      <div className="tab-content-courses">
        {/* {courses?.length > 0 ? (
          courses.map((course, index) => {
            return <StudentCourseCard key={index} course={course} />;
          })
        ) : (
          <p className="no-courses">No Courses</p>
        )} */}
        {isLoading ?
          <Grid
            height={"50vh"}
            xs={12}
            container
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress size={80} />
          </Grid>
          :
          filteredCourses.length > 0 ? (
          filteredCourses.map((course, index) => (
            <StudentCourseCard key={index} course={course} />
          ))
        ) : (
          <>
            <p className="no-courses">No Courses</p>
          </>
        )}
      </div>
    </>
  );
}

export default TabContent;
