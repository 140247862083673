import { Dispatch } from 'redux';
import api from "../../api/api";
import {
    setloading,
    seterror,
    uploadfile,
    resetuploadFile
} from "../reducers/couseFileUploadReducer";

const courseFileUpload = (data: any, token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));

    try {
        const result = await api.post(`/courses/api/file-upload/`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(uploadfile(result?.data?.files));
            dispatch(setloading(false));
        }

    } catch (error: any) {
        dispatch(seterror(error?.response?.data?.message));
        dispatch(setloading(false));
    }    
}

export { resetuploadFile, courseFileUpload }
