import React, { useEffect, useState } from "react";
import MultipleAssignmentviewResult from "./MultipleAssignmentviewResult";
import "./MultipleAssignment.css";
import MultipleAssignmentviewPopup from "./MultipleAssignmentviewPopup";
import { getAssignmentsByLesson, resetAssignmentsList } from "../../state/actions/getAssignmentsByLessonAction";
import { useAuth } from "../../features/auth/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import PaginationView from "../PaginationView/PaginationView";
import { useDispatch, useSelector } from "react-redux";
import { createAssignmentStatus } from "../../state/actions/createAssignmentStatusAction";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

function MultipleAssignment({ lesson, course, rubrics, setassignmentCount }) {
    const navigate = useNavigate();
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { lesson_id } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [selectedAssignmentIndex, setSelectedAssignmentIndex] = useState(null);
    const [assignmentPopUp, setAssignmentPopUp] = useState(false);
    const [assignmentRubric, setAssignmentRubric] = useState(null);
    const { count, next, previous, totalPages, error, assignments, isLoading } = useSelector((state) => state.getassignmentsbyLesson);
    const globalState = useSelector((state) => state.globalState);

    const startAssignmentPopupHandler = (assignment, index) => {
        setSelectedAssignment(assignment);
        setSelectedAssignmentIndex(index);
        setAssignmentRubric(rubrics[index] ? rubrics[index] : null);
        setAssignmentPopUp(!assignmentPopUp);
    };

    const startAssignmentHandler = (status = 3) => {
        const course_id = course?.course_id;
        const lesson_id = lesson?.lesson_id;
        const id = selectedAssignment?.assignment_id;

        const payload = {
            student_id: globalState.student_details.data.student_id,
            assignment_id: id,
            status: 3,
            completed_name: selectedAssignment.name,
            assignment_type: 1,
        };

        dispatch(createAssignmentStatus(payload, token));

        try {
            const state = {
                course: JSON.parse(JSON.stringify(course)),
                lesson: JSON.parse(JSON.stringify(lesson)),
                assignment: selectedAssignment,
                rubric: assignmentRubric ? JSON.parse(JSON.stringify(assignmentRubric)) : null,
                assignmentDtls: assignments?.length > 0 ? JSON.parse(JSON.stringify(assignments)) : null,
                type: 1,
            };

            navigate(`/course/${course_id}/lesson/${lesson_id}/assignment/${id}/preview`, { state });
        } catch (error) {
            console.error("Serialization error:", error);
        }
    };

    useEffect(() => {
        dispatch(getAssignmentsByLesson(lesson_id, currentPage, token));
    }, [currentPage])

    useEffect(() => {
        setassignmentCount(assignments?.length)
        const assignArr = assignments?.filter((assign) => assign.ai_feedback_json === null || assign.assignment_json
            === null)
        if (assignArr.length > 0) {
            setTimeout(() => {
                dispatch(getAssignmentsByLesson(lesson_id, currentPage, token));
            }, 30000);
        }
    }, [assignments]);

    useEffect(() => {
        return () => {
            dispatch(resetAssignmentsList());
        };
    }, []);

    return (
        <>
            <div className="maltiple-assignment-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="maltiple-assignment-wrapper">
                                <div className="maltiple-assignment-card-container">

                                    {isLoading ? <LoadingComponent
                                        loading_text="Educating the Future, Loading Now."
                                    />
                                        : assignments?.length > 0 ?
                                            assignments?.map((assignment, index) => (
                                                <div className="maltiple-assignment-card-folder">
                                                    <MultipleAssignmentviewResult key={index} index={index} assignment={assignment} startAssignmentPopupHandler={startAssignmentPopupHandler} lesson={lesson} course={course} rubrics={rubrics} />
                                                </div>
                                            ))
                                            : (
                                                <p style={{ textAlign: "center", marginTop: "20px" }}>No assignments found.</p>
                                            )
                                    }
                                </div>
                                {assignmentPopUp && <MultipleAssignmentviewPopup type={"Assignment"} startAssignmentPopupHandler={startAssignmentPopupHandler} startAssignmentHandler={startAssignmentHandler} rubric={assignmentRubric} assignment={selectedAssignment} index={selectedAssignmentIndex} />}
                            </div>
                            {/* {assignmentPopUp && (
                                <MaltipleAssignmentviewPopup
                                    type={"Assignment"}
                                    startAssignmentPopupHandler={startAssignmentPopupHandler}
                                    startAssignmentHandler={startAssignmentHandler}
                                    rubric={assignmentRubric}
                                    assignment={selectedAssignment}
                                    index={selectedAssignmentIndex}
                                />
                            )} */}
                        </div>
                    </div>
                    {totalPages > 1 && (
                        <PaginationView
                            totalPages={totalPages}
                            next={next}
                            previous={previous}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default MultipleAssignment;