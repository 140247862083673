import React, { useState } from "react";
// import '../../index.css';
const SortPopup = ({ isVisible,handleApplyClick ,  sortOption, onSortOptionChange}) => {

  const handleSortChange = (event) => {
    onSortOptionChange(event.target.value);
    handleApplyClick();
  };

  return (
    <>
      {isVisible && (
        <div className="sort-popup-assignprocess">
          <h3>Sort By:</h3>
          <label htmlFor="A-Z">
            <input
              type="radio"
              id="A-Z"
              value="A-Z"
              checked={sortOption === "A-Z"}
              onChange={handleSortChange}
            />
            A-Z
          </label>

          <label htmlFor="Z-A">
            <input
              type="radio"
              id="Z-A"
              value="Z-A"
              checked={sortOption === "Z-A"}
              onChange={handleSortChange}
            />
            Z-A
          </label>
          <label htmlFor="Newest"       onChange={handleSortChange}>
            <input
              type="radio"
              id="Newest"
              value="Newest"
              checked={sortOption === "Newest"}
              // onChange={handleSortChange}
            />
            Newest first
          </label>
          <label htmlFor="Oldest">
            <input
              type="radio"
              id="Oldest"
              value="Oldest"
              checked={sortOption === "Oldest"}
              onChange={handleSortChange}
            />
            Oldest first
          </label>
          {/* Add other sort options */}
          {/* <button
            className="apply-button-assignprocess"
            onClick={handleApplyClick}
          >
            Apply
          </button> */}
        </div>
      )}
    </>
  );
};

export default SortPopup;
