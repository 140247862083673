import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

function MarketPlaceCourseCard({course_demo_image,title,rate,number,Course_name,course_title,price,button_name1,button_name2}) {

    const navigate = useNavigate();

    return (
        <>  
            <div className="studentDashboar-Suggested-Courses-folder" id="studentCourseCard-folder">
                <div className="studentDashboar-Suggested-Courses-image">
                    <img src={course_demo_image} alt="" />
                </div>
                <div className="studentDashboar-Suggested-Courses-profile-folder">
                    <div className="studentDashboar-Suggested-Courses-profile">
                        {/* <div className="studentDashboar-profile-image">
                            <img src={ai_avatar} alt="" />
                        </div> */}
                        <div className="studentDashboar-Suggested-Courses-profile-title">
                            <h2 className="tudentDashboar-profile-card-title">{title}</h2>
                            <p>Teacher</p>
                        </div>
                    </div>
                    <div className="studentDashboar-Suggested-Courses-profile-rate">
                        <a href="#"><i className="fa-solid fa-star"></i></a>
                        <p className="rate">{rate}</p>
                        <p className="number">{number}</p>
                    </div>
                </div>
                <p className="studentDashboar-profile-course-name">
                    {Course_name}
                </p>
                <h2 className="studentDashboar-profile-course-title">
                    {course_title}
                </h2>
                <span>{price}</span>
                <div className="studentDashboar-profile-btn-folder">
                    <button type="button" onClick={() => navigate(`/cart`)}>
                    {button_name1}
                    </button>
                    <button type="button" className="Preview-lesson-btn">
                    {button_name2}
                    </button>
                </div>
            </div>
        </>
    )
}

export default MarketPlaceCourseCard;