import React, { useEffect, useState } from "react";
import "./LessonAssignmentAnalyticsTab.css";
import LessonViewProgress from "../LessonViewProgress";
import AssignmentViewProgress from "../AssignmentViewProgress";
import { useLocation } from "react-router-dom";
import api, { liveBaseURL, wsURL } from "../../../src/api/api";
import { useAuth } from "../../features/auth/AuthContext";
import BackButton from "../BackButton/BackButton";
import Helper from "../../utils/Helper";

function LessonAssignmentAnalyticsTab() {
    const { token } = useAuth();
    const location = useLocation();

    // function LessonAssignmentAnalyticsTab() {
    const [activeTab, setActiveTab] = useState("assignments"); // Default active tab is 'lessons'
    const [showDropdown, setShowDropdown] = useState(false);
    const [assignmentList, setAssignmentList] = useState([]);
    const [selectedAssignment, setSelectedAssignment] = useState({
        name: null,
        id: null,
        end_date: null,
        start_date: null,
    });
    // const [selectedAssignmentName, setSelectedAssignmentName] = useState(null);

    useEffect(() => {
        if (
            location.state.assignment_id &&
            location.state.assignment_name &&
            location.state.assignment_start_date
        ) {
            setSelectedAssignment({
                name: location.state.assignment_name,
                id: location.state.assignment_id,
                end_date: location.state.assignment_end_date,
                start_date: location.state.assignment_start_date,
            });
        }
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === "assignments") {
            setShowDropdown(!showDropdown);
        } else {
            setShowDropdown(false);
        }
    };

    const getAssignmentList = async (lesson_id) => {
        await api
            // .get(`lessons/get-lesson-by-assignments/${lesson_id}/`, {
            .get(`assignment/get-lesson-by-assignments/${lesson_id}/`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                if (response.data.status === 200) {
                    setAssignmentList(response.data.data);
                    const assignmentdetails = JSON.parse(localStorage.getItem(
                        "assignmentanalyticsdetails"
                    ));
                    //   console.log('assignmentdetails',assignmentdetails.name);
                    if (assignmentdetails) {
                        setSelectedAssignment({
                            name: assignmentdetails.name,
                            id: assignmentdetails.id,
                            end_date: assignmentdetails.end_date,
                            start_date: assignmentdetails.start_date,
                        });
                    } else {
                        if (
                            location.state.assignment_id &&
                            location.state.assignment_name &&
                            location.state.assignment_start_date
                        ) {
                            setSelectedAssignment({
                                name: location.state.assignment_name,
                                id: location.state.assignment_id,
                                end_date: location.state.assignment_end_date,
                                start_date: location.state.assignment_start_date,
                            });
                        } else {
                            setSelectedAssignment({
                                name: response.data.data[0].name,
                                id: response.data.data[0].assignment_id,
                                end_date: response.data.data[0].end_date,
                                start_date: response.data.data[0].start_date,
                            });
                        }
                    }
                }
            })
            .catch((error) => { });
    };

    useEffect(() => {
        getAssignmentList(location.state.lesson.lesson_id);
    }, []);

    const handleDropdownClick = (e) => {
        e.stopPropagation(); // Prevents the dropdown from closing when clicking inside it
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="LessonViewProgress-header-section">
                        <div className="view-course-Details-back-btn-folder LessonViewProgress-back-btn-folder">
                            <BackButton buttonName={location?.state?.lesson?.name} />
                            {/* <BackButton buttonName={location?.state?.lesson?.name} /> */}
                            {/* <i
                onClick={() => navigate(-1)}
                className="fa-solid fa-arrow-left"
              ></i>
              <h2>
              {location?.state?.lesson?.name }
              </h2> */}
                        </div>
                        <div className="date-inner-wrapper">
                            <div className="view-course-details-container">
                                <div className="view-Students-course-details-folder LessonViewProgress-student-course-folder">
                                    <span>Created</span>
                                    <div className="view-Students-course-details-counts">
                                        <p>
                                            {
                                                Helper.formatDateTime(
                                                    location?.state?.lesson?.start_date
                                                ).date
                                            }
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="view-Students-course-details-folder LessonViewProgress-student-course-folder">
                                    <span>Due date</span>
                                    <div className="view-Students-course-details-counts">
                                        <p>{Helper.formatDateTime(location?.state?.lesson?.end_date).date}</p>
                                        <p>{Helper.formatDateTime(location?.state?.lesson?.end_date).date}</p>
                                        <p>{location?.state?.lesson?.end_date !== null && location?.state?.lesson?.end_date !== "" ? Helper.formatDateTime(location?.state?.lesson?.end_date).date : "- -"}</p>
                                    </div>
                                </div> */}
                            </div>
                            {/* <button type="button" onClick={() => onPreviewLesson(location?.state?.lesson)}>Preview Lesson</button> */}
                        </div>
                    </div>
                    <div className="lesson-assignment-analyticsTab-section">
                        <div className="lesson-assignment-analyticsTab-wrapper">
                            <div className="view-lessons-wrapper">
                                <div className="view-lessons-btn-container teacehr-tab-setion">
                                    {/* <button
                                        className={`tab-button ${activeTab === "lessons" ? "active" : ""}`}
                                        onClick={() => handleTabClick("lessons")}
                                    >
                                        Lesson Analytics
                                    </button> */}
                                    <div className="dropdown-container">
                                        {/* <button
                      className={`tab-button ${
                        activeTab === "assignments" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("assignments")}
                    >
                      Assignment Analytics
                      <span className="dropdown-icon">▼</span>
                    </button> */}
                                        <button
                                            className={`tab-button ${activeTab === "assignments" ? "active" : ""}`}
                                            onClick={() => handleTabClick("assignments")}
                                        >
                                            {selectedAssignment ? (
                                                `Assignment: ${selectedAssignment?.name?.length > 14 ? selectedAssignment?.name?.substring(0, 14) + '...' : selectedAssignment?.name}`
                                            ) : (
                                                'Assignment Analytics'
                                            )}
                                            <span className="dropdown-icon">▼</span>
                                        </button>
                                        {showDropdown && assignmentList.length > 0 && (
                                            <div
                                                className="lesson-assignment-analyticsTab-dropdown-menu"
                                                onClick={handleDropdownClick}
                                            >
                                                {assignmentList.map((assignment, index) => {
                                                    return (
                                                        <button
                                                            key={index}
                                                            className="dropdown-item"
                                                            onClick={() => {
                                                                setSelectedAssignment({
                                                                    name: assignment.name,
                                                                    id: assignment.assignment_id,
                                                                    end_date: assignment.end_date,
                                                                    start_date: assignment.start_date,
                                                                });
                                                                setShowDropdown(false);
                                                            }}
                                                        >
                                                            Assignment <span>{index + 1}</span> :{" "}
                                                            {assignment.name.length > 14
                                                                ? assignment.name.substring(0, 14) + "..."
                                                                : assignment.name}
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        {/* // <div className="view-Students-course-details-folder LessonViewProgress-student-course-folder">
                                                                        //     <span>Due date</span>
                                                                        //     <div className="view-Students-course-details-counts">
                                                                        //         <p>{location?.state?.lesson?.end_date !== null && location?.state?.lesson?.end_date !== "" ? Helper.formatDateTime(location?.state?.lesson?.end_date).date : "- -"}</p>
                                                                        //     </div>
                                                                        // </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                {/* {activeTab === "lessons" && (
                                    <LessonViewProgress />
                                )} */}
                                {activeTab === "assignments" && (
                                    <AssignmentViewProgress
                                        selectedAssignment={selectedAssignment}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </div >
    );
}

export default LessonAssignmentAnalyticsTab;
