import React from 'react';
import { course_demo_image, course_info_image} from "../../assets/images/images"; 
import { useNavigate } from 'react-router-dom';
import BackButton from '../BackButton/BackButton';
// import ProfileInfos from '../ProfileInfo/ProfileInfos';
import "./AddToCart.css";
import Lesson from './AddToCartLesson';

function AddToCart() {

    return (
        <>  
           <div className='add-to-cart-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <BackButton buttonName="Course" />
                            <div className='add-to-cart-wrapper'>
                                <div className='add-to-cart-left-section'>
                                    <div className='add-to-cart-card-wrapper'>
                                        <h2>Fundamental of Neuroscience</h2>
                                        <span>Description :</span>
                                        <p className='des'>Science, spanning fields like biology and physics, systematically explores the natural world through observation and experimentation, 
                                           revealing its fundamental laws. Beyond unraveling how things work, it fosters innovation and a profound understanding of our universe's 
                                           intricacies, shaping our world and future.
                                        </p>
                                        <div className='add-to-cart-rating-wrapper'>
                                            <div className='add-to-cart-rating'>
                                                <span>Ratings</span>
                                                <span>4.1</span>
                                                <span><i class="fa-solid fa-star"></i></span>
                                            </div>
                                            <p className='ratting-numbers'>(322 Ratings)</p>
                                            <p><span>108</span> Students</p>
                                            <button>View All Ratings</button>
                                        </div>
                                        <div className='add-to-cart-profile-section'>
                                            {/* <ProfileInfos
                                                course_info_image= {course_info_image}
                                                Teacher_name= "Rehan blackman"
                                                Teacher_title= "Teacher"
                                            /> */}
                                            {/* <ProfileInfo/>
                                            <ProfileInfo
                                                course_info_image= {course_info_image}
                                                Teacher_name= "Rehan blackman"
                                                Teacher_title= "Teacher"
                                            /> */}
                                            <div className="view-course-details-container">
                                                <div className="view-Students-course-details-folder LessonViewProgress-student-course-folder">
                                                    <span>Created</span>
                                                    <div className="view-Students-course-details-counts">
                                                        <p>
                                                            Sep 10, 2023
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="view-Students-course-details-folder LessonViewProgress-student-course-folder">
                                                    <span>Due date</span>
                                                    <div className="view-Students-course-details-counts">
                                                        <p>
                                                            Sep 20, 2023
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className='add-to-cart-lesson-title'>Lessons</h2>
                                    <Lesson/>
                                </div>
                                <div className='add-to-cart-right-section'>
                                    <div className='add-to-cart-card'>
                                        <div className='add-to-cart-card-iamge'>
                                            <img src={course_demo_image}/>
                                        </div>
                                        <div className='add-to-cart-card-des-wrapper'>
                                            <h2>$20</h2>
                                            <div className='add-to-cart-card-btn-folder'>
                                                <button>Buy now</button>
                                                <button>Add to cart</button>
                                            </div>
                                            <ul className='course-includes'>
                                                <h2>This course includes :</h2>
                                                <li>4 lessons</li>
                                                <li>80 min of reading content</li>
                                                <li>25 assignments</li>
                                                <li>A.I. assistance</li>
                                                <li>Chat with teacher</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </>
    )
}

export default AddToCart;