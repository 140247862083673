import React from "react";

import { NavLink, useNavigate } from "react-router-dom";
import imgs from "../../../../../../assets/images/imgs";
import { Chip } from "@mui/material";
const StudentSuggesionCourseData = ({ course }) => {
  const navigate = useNavigate();
  function getLabel(status) {
    switch (status) {
      case "Pending":
        return "Request Sent";
      case "Active":
        return "Go To Course";
      case "Complete":
        return "Go To Course";

      default:
        return "View Course";
    }
  }

  function handleNavigation() {
    const targetPath =
      course?.status === "Pending"
        ? `/course/${course?.course_id}/view`
        : `/course/${course?.course_id}`;
    navigate(targetPath, {
      state: { course: course, isFromInvite: course?.status === "Pending" },
    });
  }

  return (
    <div className="studentDashboar-Suggested-Courses-folder">
      <div
        className="studentDashboar-Suggested-Courses-image"
        // onClick={() => {
        //   if (course?.status === "Active" || "Completed") {
        //     navigate(`/course/${course?.course_id}`, {
        //       state: { course: course },
        //     });
        //   } else {
        //     navigate(`/course/${course?.course_id}/view`, {
        //       state: { course: course, isFromInvite: false },
        //     });
        //   }
        // }}
        onClick={handleNavigation}
      >
        <img src={course?.image_url || imgs.jpg.course_demo_image} alt="" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <NavLink to={`/teacher/${course?.teacher}`}>
          <div className="studentDashboar-Suggested-Courses-profile-folder">
            <div className="studentDashboar-Suggested-Courses-profile">
              <div className="studentDashboar-profile-image">
                <img
                  src={course?.ai_avatar ?? imgs.png.tearcher_profile_image}
                  alt=""
                />
              </div>
              <div className="studentDashboar-Suggested-Courses-profile-title">
                <h2 className="tudentDashboar-profile-card-title">
                  {course?.teacher_name}
                </h2>
                <p>Teacher</p>
              </div>
            </div>
          </div>
        </NavLink>
        {course?.source && course?.source === "Invited" && (
          <Chip label={"Enrolled"} />
        )}
        {course?.source && course?.source === "Enrolled" && (
          <Chip label={course?.source} />
        )}
      </div>

      <h2
        className="studentDashboar-profile-course-title"
        // onClick={() => {
        //   if (course?.status === "Active" || "Completed") {
        //     navigate(`/course/${course?.course_id}`, {
        //       state: { course: course },
        //     });
        //   } else {
        //     navigate(`/course/${course?.course_id}/view`, {
        //       state: { course: course, isFromInvite: false },
        //     });
        //   }
        // }}
        onClick={handleNavigation}
      >
        {course?.name}
      </h2>
      <div className="studentDashboar-profile-btn-folder">
        <button
          type="button"
          // onClick={() => {
          //   if (course?.status === "Active" || "Completed") {
          //     navigate(`/course/${course?.course_id}`, {
          //       state: { course: course },
          //     });
          //   } else {
          //     navigate(`/course/${course?.course_id}/view`, {
          //       state: { course: course, isFromInvite: false },
          //     });
          //   }
          // }}
          onClick={handleNavigation}
          disabled={course?.status === "Pending" ? true : false}
        >
          {getLabel(course?.status)}
        </button>
      </div>
    </div>
  );
};

export default StudentSuggesionCourseData;
