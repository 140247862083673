import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setremovestudents,
    setloading,
    seterror,
    resetremovestudents,
} from "../reducers/removeStudentsFromCourseReducer";

const removeStudentsFromCourse = (data: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.post(`/courses/teacher/remove-students-from-course/`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setremovestudents("Student removed from course."));
            }
            else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    } catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
};

export { removeStudentsFromCourse, resetremovestudents };

