import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Assignments {
    assignments: any;
    next: string | null;
    previous: string | null;
    count: number | null;
    totalPages: number | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: Assignments = {
    assignments: [],
    next: null,
    previous: null,
    count: 0,
    totalPages: 0,
    isLoading: false,
    error: null,
};

const assignmentSlice = createSlice({
    name: "assignments",
    initialState,
    reducers: {
        setAssignmentsList: (state: Assignments, action: PayloadAction<Assignments[]>) => {
            state.assignments = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setPagination: (state: Assignments, action: PayloadAction<Assignments>) => {
            state.next = action.payload.next;
            state.previous = action.payload.previous;
            state.count = action.payload.count;
            state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: Assignments, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: Assignments, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetAssignmentsList: () => {
            return initialState;
        },
    },
});

export const {
    setAssignmentsList,
    setloading,
    seterror,
    setPagination,
    resetAssignmentsList,
} = assignmentSlice.actions;

export default assignmentSlice.reducer;