import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SyncSchoologyState {
  message: string | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: SyncSchoologyState = {
  message: null,
  isLoading: false,
  error: null,
};

const syncSchoologyslice = createSlice({
  name: 'syncSchoology',
  initialState,
  reducers: {
    setSyncSchoology: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setloading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    seterror: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetSyncSchoology: () => initialState,
  },
});

export const { setSyncSchoology, setloading, seterror, resetSyncSchoology } = syncSchoologyslice.actions;

export default syncSchoologyslice.reducer;
