import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setAddSchool, setSchoolId, setloading, seterror, resetaddSchool
} from "../reducers/addSchoolReducer";

const addSchool = (data: any,token:any) => async (dispatch: Dispatch) => {
   dispatch(setloading(true));
   try {
     const result = await api
       .post(
        `/super-admin/create-school/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`
          },
        }
       )
       
       if(result){
        dispatch(setloading(false));
        if (result.data?.status === 200) {
            dispatch(setAddSchool(result?.data));
            dispatch(setSchoolId(result?.data?.data))
          }
          else {
            dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
          }
       }
   }
   catch (error: any) {
    dispatch(seterror(error.response.data.message));
   }
}

export { addSchool, resetaddSchool} 

