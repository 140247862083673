import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PublishtoSchoologyState {
  message: string | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: PublishtoSchoologyState = {
  message: null,
  isLoading: false,
  error: null,
};

const publishtoSchoologyslice = createSlice({
  name: 'publishtoSchoology',
  initialState,
  reducers: {
    setPublishtoSchoology: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setloading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    seterror: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetPublishtoSchoology: () => initialState,
  },
});

export const { setPublishtoSchoology, setloading, seterror, resetPublishtoSchoology } = publishtoSchoologyslice.actions;

export default publishtoSchoologyslice.reducer;
