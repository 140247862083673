import React, { useRef } from "react";
import "cropperjs/dist/cropper.css"; // Import the CSS for cropper.js
import error_image from "../../../../../assets/images/error-image.png";
import { useNavigate} from "react-router-dom";
import "./Errorpage.css";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="error-page-section">
        <div className="error-page-wrapper">
            <div className="error-page-image">
                <img src={error_image} alt="error-page-image" />
            </div>
            <div className="error-page-text">
                <h1>Error</h1>
                <p>Oops! It seems you don't have the required grade level to access this course.</p>
                <button type="button" onClick={()=> {navigate("/dashboard/home");}}>Back to Homepage</button>
            </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
