import React, { Component } from 'react';
import "cropperjs/dist/cropper.css";
import error_image from "./assets/images/error-image.png";

class ErrorBoundary extends Component {
   constructor(props) {
      super(props);
      this.state = { hasError: false };
   }
   
   static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
   }

   componentDidCatch(error, errorInfo) {
      console.error("Error caught:", error, errorInfo);
      this.sendErrorEmail(this.props.token, this.props.userEmail, error, errorInfo);
   }

   sendErrorEmail(token, userEmail, error, errorInfo) {
           const errorDetails = {
         message: JSON.stringify({
            message: error.toString(),
            userEmail: userEmail || "Not signed in",
            stack: errorInfo.componentStack,
            location: window.location.href 
         }),
      };

      // console.log(' errorInfo.componentStack', errorInfo.componentStack);
      // console.log('error.toString()',  error.toString());

      const tokenName = {
         "proschool_validation": {"token":"724e50c8ba02204df566ed6046f5c69d9bb4fc2d","url":"https://web.mycro.news"},
         "proschool_ai" : {"token":"d6d784cd07a4bb926f23b7d772cd352ca21ce22b","url":"https://web.proschool.ai"},
         "srds_validation":{"token":"6afdad2356905bd2b1bae4338afb83b36e37","url":"https://srds-test-web.mycro.news"},
         "srds_ai": {"token":"52661a187a7311171e417a4e28cf3ba7395e686d","url":"https://web.srds.ai"},
     };

      const hostname = window.location.hostname;
      const subdomain_name = hostname.split(".")[0];

      let newToken = '';
      let baseUrl = '';
      if(subdomain_name == 'proschool'){
         newToken = tokenName.proschool_ai.token;
         baseUrl = tokenName.proschool_ai.url;
      } else if( subdomain_name == 'mycro' ||  subdomain_name == 'localhost'){
         newToken = tokenName.proschool_validation.token;
         baseUrl = tokenName.proschool_validation.url;
      } else if( subdomain_name == 'srds'){
         newToken = tokenName.srds_ai.token;
         baseUrl = tokenName.srds_ai.url;
      } else {
         newToken = tokenName.srds_validation.token;
         baseUrl = tokenName.srds_validation.url;
      }
      const cleanedToken = token ? token : newToken;

      fetch(`${baseUrl}/activity/email-logs/`, {
         method: 'POST',
         headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${cleanedToken}`,
        },
         body: JSON.stringify(errorDetails),
      }).catch(console.error);

     
   }

   render() {
      if (this.state.hasError) {
         return (
            <div className="error-page-section">
               <div className="error-page-wrapper">
                  <div className="error-page-image">
                     <img src={error_image} alt="error-page-image" />
                  </div>
                  <div className="error-page-text">
                     <h1>Error</h1>
                     <p>Something went wrong.</p>
                     <button type="button" onClick={() => window.location.href = '/dashboard/home'}>Back to Homepage</button>
                  </div>
               </div>
            </div>
         );
      }
      return this.props.children;
   }
}

export default ErrorBoundary;
