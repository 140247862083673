import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GoogleDriveSync {
    message: any;
    isLoading: boolean;
    error: string | null;
}

const initialState: GoogleDriveSync = {
    message: null,
    isLoading: false,
    error: null,
};

const googleDriveSyncSlice = createSlice({
    name: "googleDriveSync",
    initialState,
    reducers: {
        setgoogleDriveSync: (state: GoogleDriveSync, action: PayloadAction<string>) => {
            state.message = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: GoogleDriveSync, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: GoogleDriveSync, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetgoogleDriveSync: () => {
            return initialState;
        },
    },
});

export const {
    setgoogleDriveSync,
    setloading,
    seterror,
    resetgoogleDriveSync,
} = googleDriveSyncSlice.actions;

export default googleDriveSyncSlice.reducer;
