import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DeleteAssignment {
    isLoading: boolean;
    error: string | null;
    message: string | null;
    success: boolean;
}

const initialState: DeleteAssignment = {
    message: null,
    isLoading: false,
    error: null,
    success: false,
};


const deleteAssignmentSlice = createSlice({
    name: 'deleteAssignment',
    initialState,
    reducers: {
        setdeleteAssignment: (state: DeleteAssignment, action: PayloadAction<DeleteAssignment>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
            state.success = true;
        },
        setloading: (state: DeleteAssignment, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
            state.success = false;
        },
        seterror: (state: DeleteAssignment, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
            state.success = false;
        },
        resetdeleteAssignment: () => {
            return initialState;
        },
    },
});

export const { setdeleteAssignment, setloading, seterror, resetdeleteAssignment } = deleteAssignmentSlice.actions;

export default deleteAssignmentSlice.reducer;
