import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useAuth } from "../../../features/auth/AuthContext";
import { useCourseContext } from '../../../context/CourseContext';
import Pagination from "@mui/material/Pagination";
import { CircularProgress, Grid } from "@mui/material";
import Helper from "../../../utils/Helper";
import {
  massege,
  search_icon,
  student_grade_flage,
  srot
} from "../../../assets/images/images";
import AOS from 'aos';
import 'aos/dist/aos.css';
import api from "../../../api/api";
import SortPopup from "../../../pages/customSort/SortPopup";
import FilterPopup from "../../../pages/customFilter/FilterPopup";
import BackButton from "../../BackButton/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { getAssignmentStats, resetAssignmentStats } from "../../../state/actions/getAssignmentStatsAction";
import PaginationView from "../../PaginationView/PaginationView";

const StudentAssignmentStats = () => {

//   const { filters, updateLessionFilter} = useCourseContext();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { student_id, course_id } = useParams();
  const { student_details } = useSelector((state) => state.globalState);
  const [search, setSearch] = useState("");
  const [TempglobalState, setTempglobalState] = useState("");
  //const [totalPages, setTotalPages] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const { token } = useAuth();

  const fillterstatus = [
    { id: "Active", name: "Active" },
    { id: "Completed", name: "Completed" },
    { id: "In-Progress", name: "In Progress" },
  ];
  const fillterScore = [ ];
  const itemsPerPage = 12;
  const [isVisible, setIsVisible] = useState(false);
  const [isVisiblefillter, setIsVisiblefillter] = useState(false);
  const [assignmentStatus, setAssignmentStatus] = useState("");
  const [score, setScore] = useState("");
  const [student_assignment_details, setStudent_assignment_details] = useState(
    []
  );
  const [sortOption, setSortOption] = useState("");

  const [showdownlodefile, setShowdownlodefile] = useState(false); // State to control visibility
  const modalRef = useRef();
  const modalContentRef = useRef(); // Reference to the modal content

  const { assignStats, next, previous, count, totalPages, isLoading } = useSelector((state) => state.assignmentStats);

   // Function to open the modal
   const openModal = () => {
    setShowdownlodefile(true);
  };

  const toggledownlodefile = () => {
    // setShowdownlodefile(!showdownlodefile); // Toggle the visibility state
    setShowdownlodefile(false);
  };

  // Event listener to close modal if clicked outside the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
        toggledownlodefile();
      }
    };

    // Attach the event listener
    if (showdownlodefile) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showdownlodefile]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearch(value);
  };
  const handleAssignmentStatusChange = (event) => {
    setAssignmentStatus(event.target.value);
  };
  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };

  const [currentPage, setCurrentPage] = useState(1);
  // const hedleKeyupChnage = async (page) => {
  //   try {
  //     // loading = true; // Set loading to true before the request
  //     const pageNumber = typeof page === 'number' ? page : 1;
  //     const response = await api.get(`lessons/teachersearchlessons/`, {
  //       params: {
  //         page: pageNumber,
  //         student__name: search,
  //         // status: "Pending",
  //         result: assignmentStatus,
  //         sorting: sortOption,
  //         lesson_id: location?.state?.lesson?.lesson_id,
  //       },
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Token ${token}`,
  //       },
  //     });

  //     if (response.status == 200) {
  //       setStudent_assignment_details(response.data.data);
  //       setTotalPages(
  //         response.data.count >= 5
  //           ? Math.ceil(response.data.count / itemsPerPage)
  //           : Math.floor(response.data.count / itemsPerPage)
  //       ); // Calculate total pages
  //       setNextUrl(response.data.next); // Update next URL
  //       setPrevUrl(response.data.previous); // Update previous URL
  //       setIsVisible(false);
  //       setIsVisiblefillter(false);
  //     }
  //   } catch (error) {
  //     console.error("Fetching error:", error);
  //   } finally {
  //     // loading = false;// Set loading to false after the request is completed or if an error occurs
  //   }
  // };

  // useEffect(() => {
  //   AOS.init({
  //     duration: 500,
  //   });
  //   hedleKeyupChnage(currentPage);
  // }, [currentPage, TempglobalState, search]);

  const selectGrade = [
    {
      filtergrade: "A+"
    },
    {
      filtergrade: "A"
    },
    {
      filtergrade: "B"
    },
    {
      filtergrade: "C"
    }
  ];

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };

  const handleSortOptionChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const handleApplyClick = () => {
    setIsVisiblefillter(false);
    setIsVisible(false);
    //hedleKeyupChnage(currentPage);
    // onApplySort(sortOption);
    // setIsVisible(false);
  };

  useEffect(() => {
    getAssignmentStatsReq();
  }, [assignmentStatus, search, sortOption])

  useEffect(() => {
    return () => {
      dispatch(resetAssignmentStats());
    };
  }, []);

  const getAssignmentStatsReq = () => {
    dispatch(getAssignmentStats(course_id, student_id, token, search, assignmentStatus, sortOption));
  }

  const handleViewAnalytics = (assignment, lesson, assignment_id) => {
    navigate(
      `/course/${course_id}/lesson/${lesson}/assignment/${assignment_id}/analytics/${student_id}`, { state: assignment }
    );
  }


  return (
    <>
      <div id="assignment-stats-section">
        <div className="view-course-Details-wrapper"> 
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="LessonViewProgress-header-section">
                  <div className="view-course-Details-back-btn-folder LessonViewProgress-back-btn-folder">
                    <BackButton buttonName={"Assignment Stats"} />
                  </div>
                  <div className="assignment-student-name">
                    Student Name: {student_details?.data?.name}
                  </div>
                  <div className="date-inner-wrapper">
                    {/* <div className="view-course-details-container">
                      <div className="view-Students-course-details-folder LessonViewProgress-student-course-folder">
                        <span>Created</span>
                        <div className="view-Students-course-details-counts">
                          <p>{Helper.formatDateTime(location?.state?.lesson?.start_date).date}</p>
                        </div>
                      </div>
                      <div className="view-Students-course-details-folder LessonViewProgress-student-course-folder">
                        <span>Due date</span>
                        <div className="view-Students-course-details-counts">
                          <p>{location?.state?.lesson?.end_date !== null && location?.state?.lesson?.end_date !== "" ? Helper.formatDateTime(location?.state?.lesson?.end_date).date : "- -"}</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="LessonViewProgress-tab-folder-container">
                  <div className="LessonViewProgress-serach-filter">
                    <ReactSVG className="search-icon" src={search_icon} />
                    <input
                      type="text"
                      name="student_name"
                      value={search}
                      onChange={handleInputChange}
                      //onKeyUp={hedleKeyupChnage}
                      placeholder="Search"
                    />
                  </div>
                  <div className="LessonViewProgress-tab-filter-wrapper">
                    {/* <div
                      className="LessonViewProgress-tab-filter-downlode-folder"
                      onClick={toggledownlodefile}
                    >
                      <span>
                        <i className="fa-solid fa-download"></i>
                      </span>
                    </div> */}
                    <div className="LessonViewProgress-filder-tab-wrapper">
                      <div
                        className="lessonviewprogress-filder-tab"
                        onClick={() =>{
                          if (!isVisiblefillter) {
                            setIsVisiblefillter(true);
                            setIsVisible(false);
                          } else {
                            setIsVisiblefillter(false);
                          }
                        }
                        }
                      >
                        <span>
                          <i className="fa-solid fa-sliders"></i>
                        </span>
                        <p>Filter</p>
                      </div>
                      <FilterPopup
                        assignmentStatus={assignmentStatus}
                        score={score}
                        isVisiblefillter={isVisiblefillter}
                        handleApplyClick={handleApplyClick}
                        handleAssignmentStatusChange={handleAssignmentStatusChange}
                        fillterstatus={fillterstatus}
                        fillterScore={fillterScore}
                        handleScoreChange={handleScoreChange}
                       />
                    </div>
                    <div className="LessonViewProgress-filder-tab-wrapper">
                      <div
                        className="lessonviewprogress-filder-tab"
                        onClick={() =>{
                          if (!isVisible) {
                            setIsVisible(true);
                            setIsVisiblefillter(false);
                          } else {
                            setIsVisible(false);
                          }
                        }}
                      >
                        <span>
                          {/* <img src={srot}/> */}
                          <i class="fa-solid fa-arrow-down-wide-short"></i>
                        </span>
                        <p>Sort</p>
                      </div>
                      <SortPopup
                        isVisible={isVisible}
                        handleApplyClick={handleApplyClick}
                        sortOption={sortOption}
                        onSortOptionChange={handleSortOptionChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="LessonViewProgress-table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Assignments</th>
                        <th>Started At</th>
                        <th>Completed At</th>
                        <th>Assignment Status</th>
                        {/* <th>Assignment Progress</th> */}
                        {/* <th>Score</th> */}
                        {/* <th>Student Chat</th> */}
                        <th></th>
                      </tr>
                    </thead>
                    {isLoading ?
                      <div style={{ display: "table-caption" }}>
                        <Grid
                          height={"50vh"}
                          xs={12}
                          container
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <CircularProgress size={80} />
                        </Grid>
                      </div>
                      :

                      assignStats && assignStats.length > 0 ?

                    <tbody>
                      {

                                      assignStats?.map((assignment, index) => (
                                        <tr key={assignment.id}>
                                        <td className="assignment_Stats_name_wrapper">
                                            <div className="assignment_Stats_name">
                                                <span>{index + 1 + ": "}</span>
                                                <span className="assignment_name">{assignment.assignment_name}</span>
                                            </div>
                                        </td>
                                        <td>
                                          <div className="startedAt-time">
                                              <span>{Helper.formatDateTime(assignment.started_date).date}</span>
                                              <span>{Helper.formatDateTime(assignment.started_date).time}</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="completedat-time">
                                              <span>{assignment.completed_date ? (Helper.formatDateTime(assignment.completed_date).date) : '-'}</span>
                                              <span>{assignment.completed_date ? (Helper.formatDateTime(assignment.completed_date).time) : ''}</span>
                                              {/* <img src={student_grade_flage} alt="" /> */}
                                          </div>
                                        </td>
                                        <td>
                                            <div className={`LessonViewProgress-student-status ${Helper.getClassNameByStatus(assignment.status)}`}>
                                              {assignment.status}
                                          </div>
                                        </td>
                                          {/* <td>
                                          <div className="progress">
                                            <div
                                              className="progress-bar"
                                                style={{ width: `${assignment.progress}%` }}
                                            ></div>
                                          </div>
                                        </td>
                                        <td>
                                            <h2 className="student-score">
                                              {assignment.score}
                                            </h2>
                                        </td>
                                        <td>
                                          <div className="LessonViewProgress-student-chat">
                                              <img className="student-chat-profile" src={assignment.student_profile_pic} alt="" />
                                              <img src={massege} className="massege-icon" alt="" />
                                          </div>
                                        </td> */}
                                        <td>
                                            {assignment.status === "Completed" ? 
                                            <div className="view_analytics_btn">
                                              <a 
                                                onClick={() => handleViewAnalytics(assignment, assignment?.lesson_id, assignment?.assignment_id)}
                                              >View analytics</a>
                                            </div> : "-"}
                                        </td>
                                      </tr>
                                    ))

                          }
                        </tbody>
                        :
                        <div style={{ display: "table-caption" }}>
                          <p style={{ textAlign: "center", margin: "20px auto" }}>No Assignment Analytics Found</p>
                        </div>

                    }
                  </table>
                  <br />
                </div>
              </div>
            </div>
            {totalPages > 1 && (
              <PaginationView
                totalPages={totalPages}
                next={next}
                previous={previous}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
          {showdownlodefile && (
            <div ref={modalRef} className="Download-File-popup-wrappper">
              <div ref={modalContentRef} className="Download-File-popup-container" data-aos="zoom-in">
                <div className="download-popup">
                  <h2>Download File as</h2>
                  <button className="close-btn" onClick={toggledownlodefile}>
                    ×
                  </button>
                  <div className="input-section">
                    <label>
                      File Name *{" "}
                      <input type="text" placeholder="Enter File Name" />
                    </label>
                  </div>
                  <div className="button-section">
                    <button className="pdf-btn">PDF (.pdf)</button>
                    <span>Or</span>
                    <button className="spreadsheet-btn">
                      Spreadsheet (.xlsx)
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StudentAssignmentStats;
