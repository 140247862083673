import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GoogleToken {
    google_token: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: GoogleToken = {
    google_token: null,
    isLoading: false,
    error: null,
};

const getgoogleTokenSlice = createSlice({
    name: "getgoogleToken",
    initialState,
    reducers: {
        submitgoogleToken: (state: GoogleToken, action: PayloadAction<GoogleToken>) => {
            state.google_token = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: GoogleToken, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: GoogleToken, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetsubmitgoogleToken: () => {
            return initialState;
        },
    },
});

export const {
    submitgoogleToken,
    setloading,
    seterror,
    resetsubmitgoogleToken,
} = getgoogleTokenSlice.actions;

export default getgoogleTokenSlice.reducer;
