import { Tooltip } from "react-tooltip";
import "./SpeechtoText.css";
import react, { useEffect, useRef, useState } from 'react';

const useSpeechToText = (options) => {
    const [isListening, setIsListening] = useState(false);
    const [transcript, setTranscript] = useState("");
    const recgonitionRef = useRef(null);

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            return;
        }

        recgonitionRef.current = new SpeechRecognition();
        const recogonition = recgonitionRef.current;
        recogonition.interimResults = options.interimResults || true;
        recogonition.lang = options.lang || "en-US";
        recogonition.continuous = options.continuous || true;

        if ('webitSpeechGrammerList' in window) {
            const grammar = `#JSGF V1.0; grammer punctuation;
      public <action> = prompt | generate | create | lesson | give | solution;
      public <punc> = . | , | ? | ! | ; | : ;`;
            const speechRecognitionList = new window.webitSpeechGrammerList();
            speechRecognitionList.addFromString(grammar, 1);
            recogonition.grammars = speechRecognitionList;
        }

        recogonition.onresult = (event) => {
            let text = "";
            for (let i = 0; i < event.results.length; i++) {
                text += event.results[i][0].transcript;
            }
            if (text) {
                setTranscript(text);
            }
            // console.log(text);

        }

        recogonition.onerror = (event) => {
            console.error("Speech recongnition error: ", event.error);
        }

        recogonition.onend = () => {
            setIsListening(false);
            setTranscript("");
        }

        return () => {
            recogonition.stop();
        }
    }, []);

    const startListening = () => {
        if (recgonitionRef.current && !isListening) {
            // console.log('Attempting to start speech recognition...'); // Log attempt to start
            recgonitionRef.current.start();
            recgonitionRef.current.onstart = () => {
                // console.log('Speech recognition started successfully');
            };
            recgonitionRef.current.onerror = (error) => {
                // console.error('Speech recognition error:', error);
            };
            setTimeout(() => {
                setIsListening(true);
            }, 300);

        }
    };


    const stopListening = () => {
        if (recgonitionRef.current && isListening) {
            recgonitionRef.current.stop();
            setIsListening(false);
        }
    }
    //  const toggleListening = () => {
    //   if(isListening){
    //     stopListening()
    //   } else {
    //     startListening()
    //   }
    //  }
    return {
        isListening,
        transcript,
        startListening,
        stopListening,
        setIsListening
    };
    // return (
    //   <>
    //   <button type="button" id='mic-btn' className={`mic ${isListening ? 'listening' : ''}`}  onClick={toggleListening}>
    //     <i className={`fa-solid fa-microphone ${isListening ? 'hidden' : ''}`}></i>
    //     <div className={`boxContainer ${isListening ? '' : 'hidden'}`}>
    //       <div className="box box1"></div>
    //       <div className="box box2"></div>
    //       <div className="box box3"></div>
    //     </div>
    //   </button>
    //   <Tooltip
    //     id="speack-mic"
    //     place="bottom"
    //     content="Press Alt to unmute Mic"
    //     type="light"
    //     effect="float"
    //   />
    // </>
    // );
};
export default useSpeechToText